import Navbar from "./Navbar";
import List from "./List";
import Pagination from "./Pagination";

function ListContainer() {

  //COmponent =================================
  return (
    <div
      className="lg:min-w-[30rem] w-full lg:w-2/5 h-full flex flex-col bg-white
       dark:bg-black-900  overflow-hidden"
    >
      <Navbar/>

      {/**=========================== Tickets List ===================================== */}
      {/**Table Header */}
      <table className="w-full h-[calc(100%-7rem)] flex flex-col gap-3 overflow-hidden">
        {/**Tickets List*/}
        <tbody
          className="w-full h-full flex flex-col overflow-hidden overflow-y-scroll no-scrollbar
           no-scrollbar::-webkit-scrollbar"
        >
          <List />
        </tbody>
      </table>

      {/**=========================== Pagination ===================================== */}
      <Pagination/>
    </div>
  );
}

export default ListContainer;
