import { FC, useEffect, useState, useRef, useMemo } from "react";
import { BiCopyAlt } from "react-icons/bi";
import CannedResponses from "../Macros/CannedResponses";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import Editor from "../../../Components/Text Editor/Editor";
import useClickOutside from "../../../Custom-Hooks/useOnClickOutsideRef";
import { sendReplyFunc } from "./Helper";
import useAuthToken from "../../../Custom-Hooks/useAuthToken";

type Props = {
  scrollIntoView: any;
};

const Reply: FC<Props> = ({ scrollIntoView }) => {
  const token = useAuthToken();
  //const [newResponseModal, setCannedModal] = useState<boolean>(false);
  const chatData = useSelector((state: RootState) => state.Tickets.chatData);
  const activeTicket = useSelector(
    (state: RootState) => state.ChatData.activeTicket
  );
  const user = useSelector(
    (state: RootState) => state.UserInfo?.member_details
  )[0];
  const email_accounts = useSelector(
    (state: RootState) => state.Tickets.email_accounts
  );
  const company_details = useSelector(
    (state: RootState) => state.SettingsData.company_details
  );
  const [openCannedRes, setRes] = useState<boolean>(false);
  const [uploadingStatus, setUploadStatus] = useState<boolean>(false);
  const editorTextBoxRef = useRef<HTMLDivElement>(null);
  const statusSelectionRef = useRef<HTMLSelectElement>(null);
  const cannedModRef = useClickOutside(() => {
    setRes(false);
  });
  const [sending, setSending] = useState<boolean>(false);
  const [value, onChange] = useState<string | any>(
    window.localStorage.getItem("threadDraft") || ""
  );
  //Reply State and value ==================================
  const [replyObj, setReply] = useState({
    status: "",
    message: "",
    sender_email: user?.email,
    sender_name: user?.name,
    sender_uid: user?.id,
    date: new Date().getTime(),
    id: "",
    "read by": [],
  });
  const openTicket = useMemo(() => {
    return chatData?.find((data: any) => data?.id === activeTicket?.id);
  }, [activeTicket, chatData]);

  // Update Reply State On Value Change
  useEffect(() => {
    setReply((previous) => ({
      ...previous,
      message: value,
    }));
    // window.localStorage.setItem("threadDraft", value);
  }, [value]);

  // Filter Thread Messages
  useEffect(() => {
    if (openTicket?.id) {
      setReply((prev) => ({
        ...prev,
        sender_email: user?.email,
        sender_name: user?.name,
        sender_uid: user?.id,
        id: openTicket.id,
      }));
    } else {
      setReply({
        status: "",
        message: "",
        sender_email: user?.email,
        sender_name: user?.name,
        sender_uid: user?.id,
        date: new Date().getTime(),
        id: openTicket?.id,
        "read by": [],
      });
    }
  }, [openTicket, user]);

  //Update Reply State On Value Change ==========
  useEffect(() => {
    setReply((previous: any) => ({
      ...previous,
      message: value,
    }));
    //window.localStorage.setItem("threadDraft", value);
  }, [setReply, value, openTicket]);

  //Clear || Reset Fields and Data On Send
  const clearDataOnSend = () => {
    setReply((prev: any) => ({
      ...prev,
      message: "",
      sender_email: user?.email,
      sender_name: user?.name,
      sender_uid: user?.id,
      date: "",
      id: "",
      "read by": [],
    }));
    onChange("");
    const editor = document.getElementsByClassName("replyTextEditorOne")[0];
    if (editor) {
      editor.innerHTML = "";
    }
    if (statusSelectionRef && statusSelectionRef.current) {
      statusSelectionRef.current.selectedIndex = 0;
    }
    scrollIntoView();
    setSending(false);
  };

  return (
    <div
      className={`w-full h-fit min-h-fit p-4 relative ${
        !activeTicket?.id && "pointer-events-none"
      }`}
    >
      <form
        onSubmit={(e) => {
          e?.preventDefault();

          if (!sending && replyObj?.message?.length >= 5) {
            sendReplyFunc(
              e,
              setSending,
              openTicket,
              email_accounts,
              user,
              company_details,
              {
                ...replyObj,
                status: replyObj?.status
                  ? replyObj?.status
                  : openTicket?.status,
                date: new Date().getTime(),
              },
              clearDataOnSend,
              uploadingStatus,
              token as string
            );
          }
          clearDataOnSend();
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === "enter") {
            e.preventDefault();
          }
        }}
        className="w-full h-fit min-h-fit flex flex-col rounded-sm border border-black-400 dark:border-black-700
         bg-black-50 dark:bg-black-800  focus-within:!border-blue-600/50 transition-all"
      >
        <Editor
          editorTextBoxRef={editorTextBoxRef}
          slashComm={(bool: boolean) => (bool ? setRes(true) : setRes(false))}
          value={value}
          onChange={onChange}
          showChannel={true}
          setUploadStatus={setUploadStatus}
          uploadingStatus={uploadingStatus}
          editorClassName={"replyTextEditorOne"}
          otherStyles={`w-full h-fit min-h-[7rem] max-h-[17rem] bg-inherit text-xs text-black-600
           dark:text-black-300 rounded-t font-medium`}
          toolBarStyles={`w-full h-10 min-h-[2.5rem] border-b border-black-200 dark:border-black-700
           text-black-500 dark:text-black-200 rounded-t`}
        />

        {/**Reply Options =============== */}
        <div
          className="h-12 min-h-[3rem] w-full rounded-b bg-inherit overflow-hidden flex items-center
           justify-between p-2"
        >
          <div
            className="h-full w-fit border border-black-300 dark:border-black-700 rounded-sm
             overflow-hidden flex items-center justify-between bg-white dark:bg-black-900"
          >
            {/**Canned Response ========================================= */}
            <div
              ref={cannedModRef}
              className="w-8 h-8 group rounded-l-sm bg-inherit flex justify-center items-center
                text-base  text-black-700 dark:text-black-400"
            >
              <button
                title="Canned Responses"
                type="button"
                onClick={() => {
                  setRes((prev: boolean) => (prev ? false : true));
                }}
                className="h-full w-full flex items-center justify-center outline-none focus:outline-none"
              >
                <BiCopyAlt className="text-lg hover:opacity-80" />
              </button>
              <CannedResponses
                editorTextBoxRef={editorTextBoxRef}
                openCannedRes={openCannedRes}
                setRes={setRes}
                editorClassName={"replyTextEditorOne"}
                setReply={setReply}
                position={0.7}
                tooltipPosition={`bottom-40 left-2`}
                onChange={onChange}
              />
            </div>

            {/**Change Status =================*/}
            <select
              ref={statusSelectionRef}
              onChange={(e) => {
                setReply((prev: any) => ({
                  ...prev,
                  status: e.target.value,
                }));
              }}
              required
              className={`w-28 h-full pt-1 rounded-r-sm bg-inherit border-0 items-center outline-none
              focus:outline-none focus:ring-0 focus:border-0  hover:opacity-80 text-black-800 dark:text-black-400
              text-[0.7rem] font-semibold uppercase text-left`}
            >
              <option className="p-2" value={openTicket?.status ?? "Status"}>
                {openTicket?.status ?? "Status"}
              </option>
              <option className="p-2" value="open">
                open
              </option>
              <option className="p-2" value="pending">
                pending
              </option>
              <option className="p-2" value="solved">
                solved
              </option>
              <option className="p-2" value="closed">
                closed
              </option>
              <option className="p-2" value="reopened">
                reopened
              </option>
              <option disabled className="p-2" value="fcr">
                fcr
              </option>
            </select>
          </div>

          {/**Reply Button =========== */}
          <button
            disabled={sending}
            type="submit"
            className="h-8 w-24 shrink-0 rounded-sm bg-black-900 dark:bg-blue-600 text-xss text-white flex items-center
             justify-center gap-3 uppercase font-semibold hover:opacity-80 transition-all disabled:cursor-not-allowed"
          >
            {!sending ? (
              <span>update</span>
            ) : (
              <div
                className={`h-4 w-4 min-w-[1rem] rounded-full border-4 border-l-black-300 border-white animate-spin`}
              ></div>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Reply;
