/* eslint-disable no-prototype-builtins */
export const toUpper = (str: string) => {
  const firstChar = str?.charAt(0).toUpperCase();
  const otherPart = str?.split("");
  otherPart?.shift();
  return firstChar + otherPart?.join("")?.toLowerCase();
};

//Email Validation function ============
export const isEmail = (email: any) => {
  return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
};

export const saveLocal = (myString: any) => {
  function toBinary(string: any) {
    const codeUnits = Uint16Array.from(
      { length: string.length },
      (element, index) => string.charCodeAt(index)
    );
    const charCodes = new Uint8Array(codeUnits.buffer);

    let result = "";
    charCodes.forEach((char) => {
      result += String.fromCharCode(char);
    });
    return result;
  }

  const converted = toBinary(myString);
  return btoa(converted);
};

export const retriveLocal = (encoded: any) => {
  function fromBinary(binary: any) {
    const bytes = Uint8Array.from({ length: binary.length }, (element, index) =>
      binary.charCodeAt(index)
    );
    const charCodes = new Uint16Array(bytes.buffer);

    let result = "";
    charCodes.forEach((char) => {
      result += String.fromCharCode(char);
    });
    return result;
  }

  const decoded = atob(encoded);
  return fromBinary(decoded);
};

export const formatNumber = (number: number, format?: string) => {
  if (format === "duration") {
    let duration = number;
    const hours = Math.floor(duration / 3600000);
    duration = duration % 3600000;
    const minutes = Math.floor(duration / 60000);

    return `${hours}H ${minutes}M`;
  }

  if (number >= 10 && number < 1000 && format !== "duration")
    return number.toString();
  if (number < 10 && format !== "duration") return "0" + number.toString();

  const units = ["K", "M", "B", "T"];
  let unitIndex = -1;

  while (number >= 1000 && format !== "duration") {
    number /= 1000;
    unitIndex++;
  }

  return `${Number(number)?.toFixed(1)}${units[unitIndex]}`;
};

export function isPhoneNumber(phoneNumber: any) {
  // Strip out all non-digit characters
  const digitsOnly = phoneNumber.replace(/\D/g, "");
  // Allow numbers with 10 or more digits up to a maximum of 15 digits
  const isValidLength = digitsOnly.length >= 10 && digitsOnly.length <= 15;
  // Use a regex to check if the string contains only digits
  const containsOnlyDigits = /^\d+$/.test(digitsOnly);
  return isValidLength && containsOnlyDigits;
}

//Number Spacing ====
export const numberWithSpaces = (x: any) => {
  const parts = x?.toString()?.split(".");
  parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts?.join(".");
};

//Get An Array Of Miliseconds
export const getDateRangeArray = (startDateMs: number, endDateMs: number) => {
  const dates = [];
  let currentDateMs = startDateMs;
  while (currentDateMs <= endDateMs) {
    dates.push(currentDateMs);
    currentDateMs += 86400000; // add one day in milliseconds
  }
  return dates;
};

export function getMonthTimestamps(month: number, year: number): number[] {
  //MOnths are zero indexed
  const startDate = new Date(year, month, 1).getTime();
  const endDate = new Date(year, month + 1, 0).getTime() + 86399999;

  const timestamps: number[] = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    timestamps.push(currentDate);
    currentDate += 86400000; // add one day in milliseconds
  }

  return timestamps;
}

// define function to calculate time difference
export const getTimeDifference = (date: number) => {
  const now = new Date().getTime();
  const diff = now - new Date(date).getTime();
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor((diff / 1000 / 60) % 60);
  const seconds = Math.floor((diff / 1000) % 60);
  return `${(hours < 0 ? 0 : hours).toString().padStart(2, "0")}:${(minutes < 0
    ? 0
    : minutes
  )
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

//Get Duration
export function formatMilliseconds(ms: number, subtractMs?: number): string {
  const totalSeconds = Math.floor((subtractMs ? ms - subtractMs : ms) / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formatNumber = (num: number) =>
    (isNaN(num) ? 0 : num)?.toString()?.padStart(2, "0");

  return `${formatNumber(hours)}H :${formatNumber(minutes)}M :${formatNumber(
    seconds
  )}S`;
}

//Average Time In MM:SS Format
export function calculateAverage(milliseconds: number, number: number) {
  // Calculate the average number of milliseconds per "number"
  const averageMillisPerNumber = milliseconds / number;

  // Convert the average milliseconds per "number" to hours, minutes, and seconds
  const hours = Math.floor(averageMillisPerNumber / 3600000);
  const minutes = Math.floor((averageMillisPerNumber % 3600000) / 60000);
  const seconds = Math.floor((averageMillisPerNumber % 60000) / 1000);

  // Format the output as "00H:00M:00S"
  const formattedOutput = `${(isNaN(hours) ? 0 : hours)
    .toString()
    .padStart(2, "0")}:${(isNaN(minutes) ? 0 : minutes)
    .toString()
    .padStart(2, "0")}:${(isNaN(seconds) ? 0 : seconds)
    .toString()
    .padStart(2, "0")}`;

  // Return the formatted output
  return formattedOutput;
}

//Extract Images Only
export const extractImages = (Str: any): string => {
  const images = Str?.match(/<img[^>]*>/gims);
  const text = Str?.replace(/<img[^>]*>/gims, "");
  const newStr = `${text} ${images ? "<br/>" + images?.join(" ") : ""}`;

  return newStr;
};

//Decrypt
export const decrypt = (salt: any, encoded: any) => {
  const textToChars = (text: any) =>
    text.split("").map((c: any) => c.charCodeAt(0));
  const applySaltToChar = (code: any) =>
    textToChars(salt).reduce((a: any, b: any) => a ^ b, code);
  return encoded?.length >= 2
    ? encoded
        .match(/.{1,2}/g)
        .map((hex: any) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode: any) => String.fromCharCode(charCode))
        .join("")
    : null;
};

////Encrypt
export const crypt = (salt: any, text: any) => {
  const textToChars = (text: any) =>
    text.split("").map((c: any) => c.charCodeAt(0));
  const byteHex = (n: any) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code: any) =>
    textToChars(salt).reduce((a: any, b: any) => a ^ b, code);
  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
};

export const getCurrentDateInput = () => {
  const dateObj = new Date();

  // get the month in this format of 04, the same for months
  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  const day = ("0" + dateObj.getDate()).slice(-2);
  const year = dateObj.getFullYear();

  const shortDate = `${year}-${month}-${day}`;

  return shortDate;
};

export function formatDuration(milliseconds: number): string {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const remainingHours = hours % 24;
  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  const formattedDays = days.toString().padStart(2, "0");
  const formattedHours = remainingHours.toString().padStart(2, "0");
  const formattedMinutes = remainingMinutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function parseDuration(durationString: string): number {
  const [days, hours, minutes, seconds] = durationString.split(":").map(Number);
  const totalSeconds =
    days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
  const totalMilliseconds = totalSeconds * 1000;
  return totalMilliseconds;
}

export function generateTimeArray() {
  const times = [];

  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 5) {
      const hourString = hours < 10 ? "0" + hours : hours.toString();
      const minuteString = minutes < 10 ? "0" + minutes : minutes.toString();
      const timeString = `${hourString}:${minuteString}`;
      times.push(timeString);
    }
  }

  return times;
}

export function compareValues(value1: any, value2: any, property: any) {
  // If the values are objects, compare based on the specified property
  if (typeof value1 === "object" && typeof value2 === "object") {
    if (!value1.hasOwnProperty(property) || !value2.hasOwnProperty(property)) {
      // If either object doesn't have the specified property, they can't be compared
      return false;
    }
    return value1[property] === value2[property];
  }

  // If the values are arrays, compare based on the elements at the same index
  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) {
      // If the arrays have different lengths, they can't be compared
      return false;
    }
    for (let i = 0; i < value1.length; i++) {
      if (!compareValues(value1[i], value2[i], property)) {
        // If any element is different, the arrays are different
        return false;
      }
    }
    return true;
  }

  // If the values are not objects or arrays, compare them directly
  return value1 === value2;
}

export function compareArraysAndObjects(
  value1: any,
  value2: any,
  property?: any
) {
  // If the values are not both arrays or both objects, they can't be compared
  if (typeof value1 !== typeof value2) {
    return false;
  }

  // If the values are objects, compare based on the specified property
  if (typeof value1 === "object") {
    if (Array.isArray(value1)) {
      return compareValues(value1, value2, property);
    } else {
      if (Object.keys(value1).length !== Object.keys(value2).length) {
        // If the objects have different numbers of properties, they can't be compared
        return false;
      }
      for (const key in value1) {
        if (!compareValues(value1[key], value2[key], property)) {
          // If any property is different, the objects are different
          return false;
        }
      }
      return true;
    }
  }

  // If the values are not objects, compare them directly
  return value1 === value2;
}

export function getPasswordStrength(
  password: string,
  name: string,
  email: string
): number {
  const minLength = 8;
  const includesName = password.toLowerCase().includes(name.toLowerCase());
  const includesEmail = password.toLowerCase().includes(email.toLowerCase());
  const uppercaseRegex = /[A-Z]/g;
  const numberRegex = /[0-9]/g;
  const specialCharRegex = /[!@#$.%^&*()_+\-=[\]{};':"\\|,.<>/?]/g;

  let strength = 0;
  if (password.length >= 4) {
    if (password.length >= 4) {
      strength += 20;
    }
    if (password.length >= minLength) {
      strength += 20;
    }
    if (!includesName || !includesEmail) {
      strength += 10;
    }
    if (password.match(uppercaseRegex)) {
      strength += 10;
    }
    if (password.match(numberRegex)) {
      strength += 20;
    }
    if (password.match(specialCharRegex)) {
      strength += 20;
    }
  }
  return strength;
}

export function limitInputField(input: string): string {
  // Remove any special characters except for numbers, letters, and spaces
  const sanitizedInput = input.replace(/[^a-zA-Z0-9\s]/g, "");

  // Truncate the input to a maximum of 15 characters
  const limitedInput = sanitizedInput.slice(0, 15);

  return limitedInput;
}

export const flatString = (str: any): string => {
  return str?.toString()?.toLowerCase()?.replace(/\s/gim, "");
};

//Clear Site Data
export function clearAllData() {
  // Clear all caches
  caches.keys().then(function (cacheNames) {
    cacheNames.forEach(function (cacheName) {
      caches.delete(cacheName);
    });
  });

  // Clear local storage
  localStorage.clear();
  // Clear all site data
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    registrations.forEach(function (registration) {
      registration.unregister();
    });
  });
  document.cookie = "";
}

//Extract Ticket ID =======================
export const extractID = (str: string): any => {
  const regex = /#[A-Z0-9-]{15}/;
  const match = str.match(regex);
  const id = match ? match[0] : null;
  return id;
};

//Check Difference Between 2 objects =================
export function objectsMatch(obj1: any, obj2: any, propertiesToMatch: any) {
  for (let i = 0; i < propertiesToMatch.length; i++) {
    const property = propertiesToMatch[i];
    if (obj1[property] !== obj2[property]) {
      return false;
    }
  }
  return true;
}

//Remove URls ====================
export function checkUrls(htmlString: string): string[] {
  // Create a regular expression to match Firebase Storage URLs
  const firebaseStorageRegex =
    /(https?:\/\/(?!firebasestorage)\S+)|(https?:\/\/firebasestorage\.\S+)(?=\b|[\s<>])/gim;

  // Find all Firebase Storage URLs in the HTML string
  const matches = htmlString.match(firebaseStorageRegex);

  // Return an array of URLs if found, or an empty array if not
  return matches ? matches : [];
}

export function formatDate(date: any) {
  const new_date = new Date(date).toString()?.split("GMT")[0];

  return new_date;
}

/*
<input type="text" name="myField" pattern="[A-Za-z0-9 ]{0,15}" maxlength="15">
*/
