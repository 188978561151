import React, { FC, useState, useRef, useMemo, useEffect } from "react";
import { useScrollIntoView } from "@mantine/hooks";
import { useSelector } from "react-redux";
//import { getStorage, ref, deleteObject } from "firebase/storage";
import { markAsRead } from "../../../APIs & Data/TicketsnUserData";
import { RootState } from "../../../Redux/store";
import ZoomedMed from "../../../Components/Zoom Media/ZoomedMedia";
import Reply from "../Reply/Reply";
import Placeholder from "./Placeholder";
import Nav from "./Nav";
import MessageComponent from "./MessageComponent";

interface Props {}

const ExpandedChat: FC<Props> = () => {
  const [zoomMed, setZoomed] = useState({
    open: false,
    src: "",
  });
  const [zoomType, setType] = useState(null);
  const activeTicket = useSelector(
    (state: RootState) => state.ChatData.activeTicket
  );
  const threadMessages = useMemo(
    () => activeTicket?.thread || [],
    [activeTicket]
  );
  const user = useSelector(
    (state: RootState) => state.UserInfo?.member_details
  )[0];
  const chatData = useSelector((state: RootState) => state.Tickets.chatData);
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<
    HTMLDivElement | any
  >({offset:60,cancelable:true});

  //Check Open Ticket =====================================
  const openTicket = useMemo(() => {
    return chatData?.find((data: any) => data?.id === activeTicket?.id);
  }, [activeTicket, chatData]);

  //CRUD Notes Function
  // const crudNotes = async (
  //   e: React.SyntheticEvent,
  //   crudOP: string,
  //   id?: string
  // ) => {
  //   e && e.preventDefault();
  //   //Check Array Size the Update
  //   const notesBytesSize = new Blob([JSON.stringify(openTicket?.notes)])?.size;
  //   if (Number(notesBytesSize) <= 1000000) {
  //     if (crudOP === "add" && value?.length >= 5 && openTicket) {
  //       await addNote(openTicket.id, {
  //         author_email: user?.email,
  //         author_name: user?.name,
  //         date: new Date()?.getTime(),
  //         id: user?.email + new Date()?.getTime(),
  //         note: value,
  //       })?.then(() => {
  //         onChange("");
  //         const editor =
  //           document.getElementsByClassName("replyTextEditorOne")[0];
  //         setReply((prev: any) => ({ ...prev, message: "" }));
  //         if (editor) {
  //           editor.innerHTML = "";
  //         }
  //       });
  //       if (statusSelectionRef && statusSelectionRef.current) {
  //         statusSelectionRef.current.selectedIndex = 0;
  //       }
  //       dispatch(
  //         updateAlert([
  //           ...alerts,
  //           {
  //             message: "Note added successfully",
  //             color: "bg-green-200",
  //             id: new Date()?.getTime(),
  //           },
  //         ])
  //       );
  //     } else if (crudOP === "delete" && openTicket) {
  //       await deleteNote(openTicket?.id, id)
  //         .then(() => {
  //           const messagesArray = [
  //             openTicket?.notes.find((data: any) => data?.id === id)?.note,
  //           ];

  //           let urlsArray: string[] = [];
  //           messagesArray.forEach((value: string) => {
  //             const urls = checkUrls(value);
  //             if (urls) {
  //               urlsArray = [...urlsArray, ...urls];
  //             }
  //           });
  //           // Initialize Firebase Storage
  //           const storage = getStorage();
  //           urlsArray?.forEach((fileUrl: any) => {
  //             // Get a reference to the file
  //             const fileRef = ref(storage, fileUrl);
  //             // Delete the file
  //             deleteObject(fileRef)
  //               .then(() => {
  //                 // File deleted successfully
  //                 console.log("File deleted successfully!");
  //               })
  //               .catch((error) => {
  //                 // Uh-oh, an error occurred!
  //                 console.error("Error deleting file:", error);
  //               });
  //           });
  //           dispatch(
  //             updateAlert({
  //               message: "Message Deleted",
  //               color: "bg-green-200",
  //             })
  //           );
  //         })
  //         .catch(() => {
  //           dispatch(
  //             updateAlert({
  //               message: "Failed to delete please retry",
  //               color: "bg-red-200",
  //             })
  //           );
  //         });
  //     }
  //   } else if (Number(notesBytesSize) > 1000000) {
  //     dispatch(
  //       updateAlert([
  //         ...alerts,
  //         {
  //           message:
  //             "Max number of updates allowed reached, consider opening new ticket",
  //           color: "bg-red-200",
  //           id: new Date()?.getTime(),
  //         },
  //       ])
  //     );
  //   }
  // };

  // //Zoom Media ========================
  const zoomElement = (e: any) => {
    setType(e.target?.tagName);
    if (e.target?.tagName === "IMG") {
      setZoomed((prev) => ({ ...prev, open: true, src: e.target?.src }));
      document.body.style.overflow = "hidden";
    }
  };

  //Mark As Read
  useEffect(() => {
    if (openTicket?.id) {
      const IDs =
        openTicket?.thread?.length >= 1
          ? openTicket?.thread?.filter(
              (data: any) =>
                !data?.read_by?.some((email: any) => email === user?.email) &&
                data?.sender_email !== user?.email
            )
          : [];
      if (
        IDs &&
        (openTicket?.["requester email"] === user?.email ||
          openTicket?.["assignee email"] === user?.email ||
          openTicket?.collaborators?.includes(user?.email) ||
          openTicket?.["recipient email"]?.includes(user?.email))
      ) {
        markAsRead(IDs, openTicket, user?.email);
      }
    }
  }, [openTicket, user?.email]);

  //Scroll to Bottom
  useEffect(() => {
    if (scrollableRef) {
      scrollIntoView({ alignment: "end" });
    }
  }, [threadMessages, scrollIntoView, scrollableRef]);

  //Component ======================================
  return (
    <>
      <div
        //onClick={(e) => zoomElement(e)}
        className={`${
          activeTicket?.id
            ? "left-0 right-0"
            : "-left-[200%] right-[200%] lg:left-auto md:right-0"
        } top-0 bottom-0 fixed lg:relative transition-all duration-200 h-full w-full lg:w-3/5 lg:max-w-[calc(100%-30rem)]
         overflow-hidden z-10 bg-white dark:bg-black-900 !select-text flex flex-col justify-between
         selection:bg-black-900 dark:selection:bg-black-600 border-t-0 border border-black-200
          dark:border-black-700`}
      >
        <div className="w-full h-full overflow-hidden bg-inherit">
          {!activeTicket?.id ? (
            <Placeholder />
          ) : (
            <div className="w-full h-full overflow-hidden flex flex-col justify-between px-4">
              <Nav openTicket={openTicket} />
              <div
                ref={scrollableRef || parent}
                onClick={(e) => zoomElement(e)}
                className="w-full h-[calc(100%-3.5rem)] overflow-hidden overflow-y-scroll no-scrollbar 
                no-scrollbar::-webkit-scrollbar py-4 px-1"
              >
                {
                  //Description =====================
                  openTicket?.id && openTicket && (
                    <MessageComponent
                      user_name={openTicket?.["requester name"]}
                      date={openTicket?.date}
                      message={openTicket?.description}
                      status={"description"}
                    />
                  )
                }
                {
                  // Thread Message =====================
                  openTicket?.thread?.length >= 1 &&
                    openTicket?.thread?.map((message: any, index: number) => (
                      <MessageComponent
                        key={index}
                        user_name={message?.sender_name}
                        date={message?.date}
                        message={message?.message}
                        status={""}
                        openTicket={openTicket}
                        id={message?.id}
                        complete_message={message}
                      />
                    ))
                }
                {
                  //Final Solution If there is one =====================
                  (openTicket?.thread?.length >= 1 || openTicket?.resolution) &&
                    openTicket &&
                    (openTicket?.status === "solved" ||
                      openTicket?.status === "closed") && (
                      <MessageComponent
                        user_name={`resolution [${openTicket?.resolved_by}]`}
                        date={openTicket?.["close date"]}
                        message={openTicket?.resolution}
                        status={"as_resolution"}
                      />
                    )
                }

                {/**scrolltarget */}
                <div ref={targetRef} className="scale-0"></div>
              </div>
            </div>
          )}
        </div>
        {activeTicket?.id && <Reply scrollIntoView={scrollIntoView} />}
      </div>

      {/**Zoomed Imag &Modal */}
      <ZoomedMed zoomMed={zoomMed} setZoomed={setZoomed} type={zoomType} />
      {/**Zoomed Imag Modal */}
    </>
  );
};

export default React.memo(ExpandedChat);
