import { FC, useState } from "react";
import { TbCalendarEvent } from "react-icons/tb";
import useOnClickOutside from "../../Custom-Hooks/useOnClickOutsideRef";
import LeftHalf from "./LeftHalf";
import RightHallf from "./RightHallf";

type Props = {
  dates: any;
  additionalStyles: string;
  changeDate: any;
  parentWidth: string;
  datePosition?: string;
};

const DatePicker: FC<Props> = ({
  dates,
  additionalStyles,
  changeDate,
  parentWidth,
  datePosition,
}) => {
  const [openDatePicker, setDateOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>(dates?.start);
  const [end, setEnd] = useState<any>(dates?.end);
  const monthMap = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [range, setDatesRange] = useState<any>({
    firstHalf: {
      month: new Date(startDate)?.getMonth(),
      year: new Date(startDate)?.getFullYear(),
    },
    secondHalf: {
      month:
        new Date(startDate)?.getMonth() < 11
          ? new Date(startDate)?.getMonth() + 1
          : 0,
      year:
        new Date(startDate)?.getMonth() < 11
          ? new Date(startDate)?.getFullYear()
          : new Date(startDate)?.getFullYear() + 1,
    },
  });
  const getStartingDayOfMonth = (month: number, year: number) => {
    // Month is 0-indexed, so we need to subtract 1 from the input month value
    const date = new Date(year, month - 1, 1);
    const startingDay = date.toLocaleString("default", { weekday: "long" });
    return startingDay;
  };
  const daysOfWeek: any[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  //Check if start Date is selected  ==============
  const [startSelected, setStartSelect] = useState<boolean>(false);

  //Overlap Start Dates ==============
  const overlapStartMonth = () => {
    const day = daysOfWeek?.indexOf(
      daysOfWeek.find(
        (day: any) =>
          day ===
          getStartingDayOfMonth(
            range?.firstHalf?.month + 1,
            range?.firstHalf?.year
          )
      )
    );
    return Array(day)
      ?.fill(0)
      ?.map((_, index) => index);
  };
  const overlapEndMonth = () => {
    const day = daysOfWeek?.indexOf(
      daysOfWeek.find(
        (day: any) =>
          day ===
          getStartingDayOfMonth(
            range?.secondHalf?.month + 1,
            range?.secondHalf?.year
          )
      )
    );
    return Array(day)
      ?.fill(0)
      ?.map((_, index) => index);
  };

  //Days In A Month
  const getDays = (year: number, month: number) => {
    const days = new Date(year, month + 1, -0).getDate();
    const arr = [];
    for (let i = 1; i <= days; i++) {
      arr.push({
        day: i,
        dayMilli: new Date(year, month, i).getTime(),
        full: `${i}-${month}-${year}`,
      });
    }
    return arr;
  };

  //Close Picker OnClick Outside ======
  const pickerRef = useOnClickOutside(() => {
    setDateOpen(false);
  });

  //Component
  return (
    <div ref={pickerRef} className={`relative ${parentWidth}`}>
      <button
        onClick={() => {
          setDateOpen(true);
        }}
        className={`${additionalStyles} relative ${
          openDatePicker && "border-black-600"
        } transition-all overflow-hidden flex justify-between items-center`}
      >
        <div
          className={`h-full w-7 flex items-center justify-center text-base`}
        >
          <TbCalendarEvent />
        </div>
        <div
          className="w-[calc(100%-1.75rem)] h-full flex 
      items-center justify-between space-x-1 px-2"
        >
          <span className="w-[40%] whitespace-nowrap overflow-hidden text-ellipsis">
            {new Date(dates?.start)
              ?.toDateString()
              ?.split(" ")
              ?.slice(1, 6)
              ?.join(" ")}
          </span>
          -
          <span className="w-[40%] whitespace-nowrap overflow-hidden text-ellipsis">
            {new Date(dates?.end)
              ?.toDateString()
              ?.split(" ")
              ?.slice(1, 6)
              ?.join(" ")}
          </span>
        </div>
      </button>

      {/**Content ================== */}
      <div
        className={`absolute ${
          datePosition ? datePosition : "top-12 -left-0 md:left-0"
        } z-[99999] h-fit min-w-[15rem] w-full md:w-[34.5rem] rounded shadow-2xl bg-white
         dark:bg-black-700 border 
        ${
          openDatePicker
            ? "border-black-600/30 flex"
            : "border-black-300 hidden"
        } flex-col dark:border-black-600 overflow-hidden text-black-800 dark:text-black-300`}
      >
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2 p-2">
          {/**Firs tHalf ================================================ */}
          <LeftHalf
            setDatesRange={setDatesRange}
            range={range}
            monthMap={monthMap}
            overlapStartMonth={overlapStartMonth}
            getDays={getDays}
            startSelected={startSelected}
            setEnd={setEnd}
            setStartSelect={setStartSelect}
            startDate={startDate}
            setStartDate={setStartDate}
            end={end}
          />
          {/**First Half ================================================ */}

          {/**Second Half ================================================ */}
          <RightHallf
            setDatesRange={setDatesRange}
            range={range}
            monthMap={monthMap}
            overlapEndMonth={overlapEndMonth}
            getDays={getDays}
            startSelected={startSelected}
            setEnd={setEnd}
            setStartSelect={setStartSelect}
            startDate={startDate}
            setStartDate={setStartDate}
            end={end}
          />
          {/**Second Half ================================================ */}
        </div>

        {/**Bottom Nav ========================= */}
        <div className="min-h-[2.5rem] py-1 w-full bg-black-50 dark:bg-black-600 border-t border-black-200 dark:border-black-600 flex justify-end md:justify-between items-center px-1">
          <div className="hidden md:flex items-center justify-between space-x-2 p-1">
            <div className="text-xs dark:text-black-400 font-semibold text-black-700 font-sans w-32 h-8 overflow-hidden whitespace-nowrap overflow-ellipsis p-2 flex items-center justify-center">
              <span className="w-full overflow-hidden whitespace-nowrap overflow-ellipsis">
                {new Date(startDate).toDateString()}
              </span>
            </div>
            <div className="text-xs dark:text-black-400 text-black-700 font-semibold font-sans w-32 h-8 overflow-hidden whitespace-nowrap overflow-ellipsis p-2 flex items-center justify-center">
              <span className="w-full overflow-hidden whitespace-nowrap overflow-ellipsis">
                {new Date(end ? end : "").toDateString()}
              </span>
            </div>
          </div>
          <div className="flex items-center space-x-2 p-1">
            <button
              type="button"
              onClick={() => {
                setStartDate(dates.start);
                setEnd(dates?.end);
                setDatesRange({
                  firstHalf: {
                    month: new Date(startDate)?.getMonth(),
                    year: new Date(startDate)?.getFullYear(),
                  },
                  secondHalf: {
                    month:
                      new Date(startDate)?.getMonth() < 11
                        ? new Date(startDate)?.getMonth() + 1
                        : 0,
                    year:
                      new Date(startDate)?.getMonth() < 11
                        ? new Date(startDate)?.getFullYear()
                        : new Date(startDate)?.getFullYear() + 1,
                  },
                });
                setDateOpen(false);
              }}
              className="h-8 w-[6.5rem] bg-inherit dark:text-black-300 text-black-900 text-xs font-sans
               font-semibold uppercase hover:opacity-80 transition-all rounded-sm tracking-wider"
            >
              cancel
            </button>
            <button
              type="button"
              title="Please Select A Proper End Date"
              onClick={() => {
                changeDate({
                  start: startDate,
                  end: end,
                });
                setDateOpen(false);
              }}
              disabled={end ? false : true}
              className="h-8 w-[6.5rem] bg-black-800 dark:bg-black-900 text-black-50 text-xs font-sans font-semibold 
              uppercase hover:opacity-80 transition-all rounded-sm disabled:opacity-70 disabled:cursor-not-allowed tracking-wider
              disabled:text-black-300"
            >
              <span className={`font-semibold`}>apply</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
