import { FC, useEffect, useState, useRef } from "react";
import { BiDetail } from "react-icons/bi";
import {
  newCannedRes,
  newPublicCannedRes,
} from "../../../APIs & Data/TicketsnUserData";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../Redux/store";
import { updateAlert } from "../../../Redux/Slices/NotificationsSlice";
import Editor from "../../../Components/Text Editor/Editor";
import { AnimatePresence, motion } from "framer-motion";
import { openNewCannedResponse } from "../../../Redux/Slices/ChatSlice";

const NewCanned: FC = () => {
  const newCannedResponseModal = useSelector(
    (state: RootState) => state.ChatData.newCannedResponseModal
  );
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: RootState) => state.UserInfo.member_details);
  const scopeSelectionRef = useRef<HTMLSelectElement>(null);
  const [value, onChange] = useState<string>("<p></p>");
  const editorTextBoxRef = useRef<HTMLDivElement>(null);
  const [showloading, setLoading] = useState<boolean>(false);
  const [input, setInput] = useState({
    name: "",
    message: "",
    scope: "private",
  });

  //Update Mesange On Change
  useEffect(() => {
    setInput((previous: any) => ({ ...previous, message: value }));
  }, [value]);

  const clearForm = () => {
    dispatch(
      updateAlert({
        message: "Template added succesfully",
        color: "bg-green-200",
      })
    );
    setInput({
      name: "",
      message: "",
      scope: "private",
    });
    setLoading(false);
    dispatch(openNewCannedResponse(true));
    //Reset Scope Selection ==================
    onChange("<p></p>");
    if (scopeSelectionRef && scopeSelectionRef.current) {
      scopeSelectionRef.current.selectedIndex = 0;
    }
    if (editorTextBoxRef && editorTextBoxRef?.current) {
      editorTextBoxRef.current.innerHTML = "";
    }
    document.body.style.overflow = "";
    dispatch(openNewCannedResponse(false));
  };

  //Save New Canned Response =========
  const openNew = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    if (input?.name.length >= 1 && input?.message?.length >= 9) {
      if (input.scope === "private") {
        newCannedRes(user[0]?.id, input?.name, input?.message)
          .then(() => {
            clearForm();
          })
          .catch(() => {
            updateAlert({
              message: "Failed to add, please retry",
              color: "bg-red-200",
            });
            dispatch(openNewCannedResponse(false));
          });
        setLoading(false);
      } else if (input.scope === "public") {
        newPublicCannedRes(input?.name, input?.message)
          .then(() => {
            clearForm();
          })
          .catch(() => {
            updateAlert({
              message: "Failed to add, please retry",
              color: "bg-red-200",
            });
            setLoading(false);
            dispatch(openNewCannedResponse(false));
          });
      }
    } else {
      updateAlert({
        message: "Please fill all the details",
        color: "bg-red-200",
      });
      setLoading(false);
    }
  };

  //Component
  return (
    <AnimatePresence>
      {newCannedResponseModal && (
        <motion.div
          className={`fixed left-0 right-0 top-0 bottom-0 z-[9999] bg-black-800/30 backdrop-blur-sm`}
        >
          <form
            onSubmit={openNew}
            id="newCannedResponse"
            className={`m-auto mt-24 drop-shadow-2xl bg-slate-50 dark:bg-slate-800 rounded-xl 
        h-[60vh] min-h-[30rem] min-w-[35rem] w-[50vw] flex flex-col gap-4 p-4 transition-all 
                 duration-300 z-[999] relative overflow-hidden`}
          >
            <div className="flex justify-between items-center gap-4 h-11 overflow-hidden">
              <div className="h-full w-full min-w-[15rem] rounded-xl dark:bg-slate-700 bg-slate-100 relative">
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  autoComplete="off"
                  placeholder="Template name ..."
                  onChange={(e) => {
                    setInput({ ...input, name: e.target.value });
                  }}
                  value={input.name}
                  className="bg-transparent w-full h-full rounded dark:border-slate-700 border-slate-300 
              outline-none focus:outline-none focus:ring-0 text-xs px-4 pl-11 placeholder:text-slate-500
              dark:placeholder:text-slate-400 text-slate-800 dark:text-slate-300 dark:bg-slate-700 bg-white"
                />
                <BiDetail className="absolute text-slate-500 dark:text-slate-300 text-lg top-3 left-4" />
              </div>
              <select
                ref={scopeSelectionRef}
                onChange={(e) => {
                  setInput((prev) => ({ ...prev, scope: e.target.value }));
                }}
                className="h-full bg-white dark:bg-slate-700 rounded border dark:border-slate-700 border-slate-300 
              uppercase text-xs font-semibold dark:text-slate-300 text-slate-700"
                name="scope"
                id="scope"
              >
                <option value="private">Private Scope</option>
                <option value="public">Public Scope</option>
              </select>
            </div>
            <div className="h-[calc(100%-7.25rem)] w-full">
              <Editor
                editorTextBoxRef={editorTextBoxRef}
                value={value}
                onChange={onChange}
                showChannel={true}
                setUploadStatus={""}
                editorClassName={"newCannedResp"}
                otherStyles="w-full !h-full h-fit relative rounded-lg dark:bg-slate-700  
             bg-white pb-10 border border-slate-300/70 dark:border-slate-700 focus-within:border 
             focus-within:border-blue-600 dark:focus-within:border-blue-700 text-xs text-slate-700 dark:text-slate-200"
                toolBarStyles="w-full bg-inherit border-b border-slate-300 dark:border-slate-700 z-[9] sticky top-0
           text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-400"
              />
            </div>

            {/**Botton Btns */}
            <div
              className="h-14 grid grid-cols-2 absolute bottom-0 left-0 right-0 
          border-t border-slate-200 dark:border-slate-600"
            >
              <button
                type="button"
                onClick={() => {
              dispatch(openNewCannedResponse(false));
                  document.body.style.overflow = "";
                }}
                className="h-full col-span-1 outline-none focus:outline-none bg-white dark:bg-slate-700
             text-slate-700 dark:text-slate-100 text-sm font-semibold uppercase whitespace-nowrap
             hover:opacity-80 transition-all"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="h-full col-span-1 outline-none focus:outline-none hover:opacity-80 transition-all
             bg-blue-600 text-slate-100 text-sm font-semibold uppercase whitespace-nowrap  flex
             justify-center items-center"
              >
                {!showloading ? (
                  <span>Create new</span>
                ) : (
                  <div className="h-5 w-5 rounded-full border-4 border-l-blue-400 animate-spin"></div>
                )}
              </button>
            </div>
          </form>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default NewCanned;
