import { FC } from "react";
import { MdAttachment } from "react-icons/md";

type Props = {
  toolBarStyles: string;
  uploadFile: any;
  showChannel: boolean;
  editorClassName: any;
  uploadingStatus?: boolean;
};

const ToolBar: FC<Props> = ({
  toolBarStyles,
  uploadFile,
  editorClassName,
  uploadingStatus,
}) => {
  //Component  ===================
  return (
    <div
      className={`rteToolBar h-9 ${toolBarStyles} flex items-center justify-between p-2 
      px-1 text-lg text-black-700 dark:text-black-400 relative`}
    >
      {uploadingStatus && (
        <div className="absolute left-0 -bottom-0.5 h-0.5 w-1/5 bg-blue-500 animate-end_to_end"></div>
      )}
      <div className="flex items-center w-fit h-full">
        <label
          htmlFor={editorClassName}
          className="hover:opacity-80 transition-all outline-none h-6 w-8 border-0 flex justify-center items-center cursor-pointer"
        >
          <input
            multiple
            type="file"
            name={editorClassName}
            id={editorClassName}
            accept="image/*,.pdf,.doc,.docx,.xlsx, .xls, .csv,video/*,audio/*"
            className="hidden"
            onChange={(e) => {
              const target: any = e.target;
              Array.prototype.forEach.call(target.files, (content: any) => {
                if (content) {
                  uploadFile(content);
                }
              });
            }}
          />
          <MdAttachment className="text-xl" />
        </label>
      </div>
    </div>
  );
};

export default ToolBar;
