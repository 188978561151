import { useState } from "react";
import {
  RiHistoryFill,
  RiLayoutRight2Line,
  RiStickyNoteLine,
  RiGroup2Line,
} from "react-icons/ri";
import { useDispatch } from "react-redux";
import { selectTicket } from "../../../Redux/Slices/ChatSlice";
import { BsInfo } from "react-icons/bs";
import Collaborators from "./Collaborators";
import Details from "./Details/Details";

type Props = {
  openTicket: any;
};

const Nav = ({ openTicket }: Props) => {
  const dispatch = useDispatch();
  const [collabModal, setCollabModal] = useState<boolean>(false);
  const [showDetails, setDetails] = useState<boolean>(false);

  //Component =====================
  return (
    <div
      className="w-full h-14 min-h-[3.5rem] bg-inherit flex items-center justify-between
       gap-4 overflow-hidden"
    >
      <button
        onClick={() => {
          dispatch(selectTicket(null));
        }}
        title="Close chat"
        className="h-8 w-8 rounded bg-black-50 dark:bg-black-800 border border-black-300
         dark:border-black-600 flex items-center justify-center text-black-600
          dark:text-black-300 text-lg hover:!text-blue-600 transition-all"
      >
        <RiLayoutRight2Line />
      </button>

      {/*Other Controls */}
      <div
        className="h-8 w-[8rem] overflow-hidden grid grid-cols-4 rounded bg-black-50 dark:bg-black-800
       border border-black-300 dark:border-black-600 text-black-600 dark:text-black-300 text-lg"
      >
        <button
          title="Add note"
          className="col-span-1 flex items-center justify-center hover:!text-blue-600 transition-all"
        >
          <RiStickyNoteLine />
        </button>
        <button
          title="History"
          className="col-span-1 flex items-center justify-center hover:!text-blue-600 transition-all"
        >
          <RiHistoryFill />
        </button>
        <button
          onClick={() => {
            setCollabModal(true);
          }}
          title="Manage Collaborators"
          className="col-span-1 flex items-center justify-center hover:!text-blue-600 transition-all"
        >
          <RiGroup2Line />
        </button>
        <button
          onClick={() => {
            setDetails(true);
          }}
          title="View Ticket Details"
          className="col-span-1 text-2xl flex items-center justify-center hover:!text-blue-600 transition-all"
        >
          <BsInfo />
        </button>
      </div>

      {/** Collaborators ======================= */}
      <Collaborators
        openTicket={openTicket}
        collabModal={collabModal}
        setCollabModal={setCollabModal}
      />

      {/**Details  ====================== */}
      <Details
        showDetails={showDetails}
        setDetails={setDetails}
        openTicket={openTicket}
      />
      {/**Details  ====================== */}
    </div>
  );
};

export default Nav;
