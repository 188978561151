import React, { FC, useState, useEffect, useRef } from "react";
import { toUpper } from "../../../Reusable Functions/Reusable_Func";
import { useSelector, useDispatch } from "react-redux";
import { addTicket } from "../../../APIs & Data/TicketsnUserData";
import { BiTrash, BiCollection } from "react-icons/bi";
import { AppDispatch, RootState } from "../../../Redux/store";
import CannedResponses from "./../Macros/CannedResponses";
import Editor from "./../../../Components/Text Editor/Editor";
import ZoomedMed from "./../../../Components/Zoom Media/ZoomedMedia";
import ComboBox from "../../../Components/Custom Inputs/ComboBox";
import MultiSelect from "../../../Components/Custom Inputs/MultiSelect";
import TextMultiple from "../../../Components/Custom Inputs/TextMultiple";
import { default_email_template } from "./Default_Email_Template";
import { setThreadId } from "../../../Redux/Slices/Tickets_n_Contacts_Slice";
import { org } from "../../../APIs & Data/TicketsnUserData";
//Firestore ===================

import {
  getFirestore,
  collection,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import useClickOutside from "../../../Custom-Hooks/useOnClickOutsideRef";
import { useOutletContext } from "react-router-dom";
import Duplicates from "./Duplicates";
import { notifications } from "@mantine/notifications";
import { AnimatePresence, motion } from "framer-motion";
import { selectTicket } from "../../../Redux/Slices/ChatSlice";
import useAuthToken from "../../../Custom-Hooks/useAuthToken";
import { auth, getToken } from "../../../APIs & Data/Firebase";

const NewTicket: FC = () => {
  const token = useAuthToken();
  const defaultFields: any = useSelector(
    (state: RootState) => state.Tickets.ticketFields
  );
  const ticketFields = useRef(defaultFields);
  const [duplicates, setDuplicates] = useState<any[]>([]);

  const customFields = useSelector(
    (state: RootState) => state.Tickets.custom_fields
  );
  //New Ticket Values | Draft
  const draft = localStorage.getItem("newTicketDraftValues");
  const [inputValue, setValues] = useState<any>(
    draft
      ? JSON.parse(draft)
      : [ticketFields, ...customFields]?.reduce(
          (obj, val) => ({ ...obj, [val.name]: "" }),
          {}
        )
  );
  const [newTicketModal, setModal]: any = useOutletContext();
  const editorTextBoxRef = useRef<HTMLDivElement>(null);
  const contacts = useSelector((state: RootState) => state.Tickets.contacts);
  const allMembers = useSelector(
    (state: RootState) => state.UserInfo.allMembers
  );
  const team = useSelector((state: RootState) => state.Tickets.email_accounts);
  const company_details = useSelector(
    (state: RootState) => state.SettingsData.company_details
  );
  const categories = useSelector(
    (state: RootState) => state.Tickets.categories
  );
  const user = useSelector(
    (state: RootState) => state.UserInfo.member_details
  )[0];
  const dispatch: AppDispatch = useDispatch();
  const [zoomMed, setZoomed] = useState({
    open: false,
    src: "",
  });
  const [zoomType, setType] = useState(null);
  const [openCannedRes, setRes] = useState<boolean>(false);
  const [isSubmiting, setSubmit] = useState<boolean | any>(false);
  const [clear, setClear] = useState<boolean>(false);
  const [uploadingStatus, setUploadStatus] = useState<boolean>(false);
  const cannedModRef = useClickOutside(() => {
    setRes(false);
  });
  //Message Input Values =========================
  const [value, onChange] = useState<string | any>(inputValue.description);

  //Update Message On Editor Value change
  // useEffect(() => {
  //   setValues((previous: any) => ({ ...previous, description: value }));
  // }, [setValues, value]);

  //Zoom Media ========================
  const zoomElement = (e: any) => {
    if (e.target?.tagName === "IMG") {
      setZoomed((prev) => ({ ...prev, open: true, src: e.target?.src }));
      document.body.style.overflow = "hidden";
    }
    setType(e.target?.tagName);
  };

  //Submit A New Ticket
  const handleNewTicket = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmit(true);

    //Generate Unique ID
    const uniqueID = (() => {
      const name = inputValue["recipient name"]?.replace(/[^a-zA-Z]|\s/gi, "");
      const combined = `#${
        name?.charAt(name.length - 2)?.toUpperCase() +
        new Date().getFullYear().toString().slice(2, 4) +
        new Date().toISOString().slice(5, 7) +
        new Date().toISOString().slice(8, 10) +
        "-" +
        new Date().getMilliseconds()?.toString()?.charAt(0) +
        new Date().toISOString().slice(11, 13) +
        new Date().toISOString().slice(14, 16) +
        new Date().toISOString().slice(17, 19)
      }`;
      return combined?.replace(/\s/g, "");
    })();

    //Check if FIles Are still being Uploaded
    if (uploadingStatus) {
      notifications.show({
        title: "Still uploading",
        message: "File(s) are being upploaded please wait",
        color: "yellow",
      });
      setSubmit(false);
    } else if (!uploadingStatus) {
      //Sending Account =============================
      const sendingAccount = team.find(
        (account) =>
          account.name.toLowerCase() === inputValue?.team.toLowerCase()
      );
      //Open Ticket
      const assigneeObj = allMembers?.filter(
        (data: any) =>
          data?.name?.toLowerCase()?.replace(/\s/gi, "") ===
          inputValue["assignee name"]?.toLowerCase()?.replace(/\s/gi, "")
      )[0];
      const newTicket = async (inputValue: any) => {
        //AddTicket To DB and Check For Duplicates
        addTicket(inputValue).then(async (data: any) => {
          dispatch(selectTicket(data));
          if (inputValue?.["Customer's Phone"]) {
            const ticketsref = query(
              collection(getFirestore(), `companies/${org}/tickets`),
              where("Customer's Phone", "==", inputValue?.["Customer's Phone"])
            );

            await getDocs(ticketsref).then((snapshot) => {
              if (snapshot.docs?.length >= 2) {
                setDuplicates(
                  snapshot.docs.map((doc: any) => ({
                    ...doc.data(),
                    id: doc.id,
                  }))
                );
              }
            });
          }
        });

        const new_token = await getToken();

        const emailData = {
          from: `${sendingAccount?.email}`,
          company: `${sendingAccount?.name}`,
          password: sendingAccount?.password,
          host: sendingAccount?.host,
          port: sendingAccount?.port,
          to: inputValue["recipient email"]?.replace(/\\/gim, ","),
          cc: inputValue["carbon copy"],
          subject: `${toUpper(inputValue?.category)} || Ticket-ID: ${
            inputValue?.ticket_id || uniqueID
          }`,
          ticket_id: inputValue?.ticket_id,
          email_body: default_email_template(
            inputValue,
            company_details,
            sendingAccount?.name || "Support"
          ),
          //is_custom: sendingAccount?.is_custom,
          //domain: sendingAccount?.domain,
          token: new_token || token,
          uid: auth?.currentUser?.uid,
        };

        //Send Email Using Nodemailer ===================
        await fetch(`https://mail.basedesk.co.za/send`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(emailData),
        })
          .then((res) => res.json())
          .then((data) => {
            const resData = data;
            if (resData.status === "success") {
              notifications.show({
                title: "Email sent successfully",
                message: "Email notification sent successfully",
                color: "green",
              });
            } else if (resData.status === "fail") {
              setSubmit(false);
              notifications.show({
                title: "Failed to send email",
                message: "We ran into an error please retry",
                color: "red",
              });
            }
          })
          .catch(() => {
            notifications.show({
              title: "Failed to send email",
              message: "We ran into an error please retry",
              color: "red",
            });
            setSubmit(false);
          });
        document.body.style.overflow = "";
        const editor = document.getElementsByClassName("newTicketEditor")[0];
        window.localStorage.setItem("newTicketDraftValues", "");
        setValues(
          ticketFields.current?.reduce(
            (obj: any, val: any) => ({ ...obj, [val.name]: "" }),
            {}
          )
        );
        if (editor) {
          editor.innerHTML = "";
        }
        if (editor) {
          editor.innerHTML = "";
        }
        setClear(true);
        setSubmit(false);
        setModal(false);
        onChange("");
        dispatch(setThreadId(uniqueID));
        dispatch(setThreadId(uniqueID));
        window.localStorage.setItem("threadId", JSON.stringify(uniqueID));
      };
      if (value?.length >= 2) {
        newTicket({
          ...inputValue,
          description: value,
          due_date: new Date(
            new Date().getTime() +
              Number(
                categories?.filter(
                  (cat: any) =>
                    cat?.name?.toLowerCase()?.replace(/\s/gi, "") ===
                    inputValue?.category?.toLowerCase()?.replace(/\s/gi, "")
                )[0]?.turnaround_time
              )
          ).getTime(),
          date: new Date().getTime(),
          origin: "Web Form",
          resolved_by:
            inputValue?.status === "solved" || inputValue?.status === "closed"
              ? user?.name
              : "",
          resolution:
            inputValue?.status === "solved" || inputValue?.status === "closed"
              ? value
              : "",
          "close date":
            inputValue?.status === "solved" || inputValue?.status === "closed"
              ? new Date().getTime()
              : "",
          "resolution time":
            inputValue?.status === "solved" || inputValue?.status === "closed"
              ? 0
              : "",
          ticket_id: uniqueID,
          "assignee email": assigneeObj?.email,
          "assignee id": assigneeObj?.id,
        });
      } else {
        setSubmit(false);
        notifications.show({
          title: "Description cannot be empty!",
          message: "Description cannot be empty!",
          color: "yellow",
        });
      }
    }
  };

  //Add Dropdown List to ticketFields
  useEffect(() => {
    ticketFields.current = ticketFields.current?.map((data: any) => {
      return {
        ...data,
        dropdownList:
          data.name === "assignee name"
            ? user?.access === "admin"
              ? allMembers?.length >= 1 &&
                [...allMembers]?.map((member: any) => ({
                  value: member?.email,
                  name: member?.name,
                  hidden_property: member,
                }))
              : [{ ...user, hidden_property: { photoUrl: user?.photoUrl } }]
            : data.name === "collaborators"
              ? allMembers?.length >= 1 &&
                [...allMembers]
                  ?.filter(
                    (member) =>
                      member?.access === "admin" || member?.access === "agent"
                  )
                  ?.map((member: any) => ({
                    value: member?.email,
                    name: member?.name,
                    hidden_property: member,
                  }))
              : data.name === "recipient name"
                ? contacts?.length >= 1 &&
                  [...contacts]?.map((contact: any) => ({
                    value: contact?.branch_company
                      ? contact?.branch_company
                      : contact?.name,
                    name: contact?.branch_company
                      ? contact?.branch_company
                      : contact?.name,
                    hidden_property: contact,
                  }))
                : data.name === "category"
                  ? categories?.length >= 1 &&
                    [...categories]?.map((category: any) => ({
                      value: category?.name,
                      name: category?.name,
                      hidden_property: category,
                    }))
                  : data.name === "status"
                    ? [
                        { name: "open", value: "open" },
                        { name: "solved", value: "solved" },
                        { name: "pending", value: "pending" },
                        { name: "closed", value: "closed" },
                      ]
                    : data.name === "priority"
                      ? [
                          { name: "low", value: "low" },
                          { name: "medium", value: "medium" },
                          { name: "high", value: "high" },
                          { name: "urgent", value: "urgent" },
                        ]
                      : data.name === "team"
                        ? team?.length >= 1 &&
                          [...team]?.map((team: any) => ({
                            value: team?.name,
                            name: team?.name,
                          }))
                        : data?.dropdownList,
      };
    });
  }, [allMembers, user, categories, contacts, team]);

  //Component =====================================
  return (
    <AnimatePresence>
      {newTicketModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={`fixed top-0 bottom-0 flex justify-center md:py-20 overflow-y-auto no-scrollbar z-[9999] 
          overflow-hidden  bg-black-900/20 backdrop-blur-sm left-0 right-0 transition-all duration-300`}
        >
          <form
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "enter") {
                e.preventDefault();
              }
            }}
            onSubmit={(e) => handleNewTicket(e)}
            id="new_ticket_form"
            className={`relative transition-all w-full md:w-[38rem] md:max-h-[40rem] h-full md:h-fit dark:bg-black-800
             bg-white overflow-hidden flex flex-col justify-between gap-1 md:rounded-lg border-2 border-black-200 
             dark:border-black-700 drop-shadow-xl shadow-xl m-auto`}
          >
            <div className="h-full w-full overflow-hidden flex flex-col justify-between gap-4">
              {/**Top Section  ==================================== */}
              {team?.length >= 1 &&
              categories?.length >= 1 &&
              contacts?.length >= 1 &&
              allMembers?.length >= 1 ? (
                <div
                  className="w-full h-fit overflow-hidden overflow-y-scroll no-scrollbar
                 no-scrollbar::-webkit-scrollbar grid grid-cols-2 gap-4 p-4 pb-2"
                >
                  {[...ticketFields.current, ...customFields]
                    ?.filter(
                      (field: any) =>
                        field?.visibility === "show" &&
                        field?.name !== "description"
                    )
                    ?.map((field: any, index: number) => {
                      return (
                        <React.Fragment key={field?.name + index}>
                          {field?.type === "text" ||
                          field?.type === "tel" ||
                          field?.type === "number" ||
                          field?.type === "date" ||
                          field?.type === "email" ||
                          field?.type === "time" ? (
                            <fieldset
                              key={field?.name + index}
                              className="col-span-1 h-12 shrink-0 border border-black-300 dark:border-black-700 rounded px-2 overflow-hidden"
                            >
                              <legend className="px-2 bg-white dark:bg-black-800 rounded text-black-500 dark:text-black-500 dark:font-medium font-semibold uppercase text-[0.65rem]">
                                {field?.visible_name}{" "}
                                {field?.required && (
                                  <span className="text-red-600">*</span>
                                )}
                              </legend>
                              <label
                                htmlFor={field?.name}
                                className="h-full w-full flex items-center justify-between space-x-1 dark:text-black-300 text-black-700 text-xs font-medium relative"
                              >
                                <input
                                  className="new_ticket_input_field h-full w-full overflow-hidden whitespace-nowrap text-ellipsis p-2 pt-1 bg-transparent dark:text-black-300 text-black-800 border-0 outline-none focus:outline-none focus:ring-0 focus:border-0 text-xs font-normal dark:placeholder:text-black-600 placeholder:text-black-400"
                                  id={field.name}
                                  name={field.name}
                                  type={field.type}
                                  autoComplete="off"
                                  placeholder={field?.placeholder}
                                  value={inputValue[field.name]}
                                  required={field?.required}
                                  onChange={(e) => {
                                    setValues((prev: any) => ({
                                      ...prev,
                                      [field.name]: e.target.value,
                                    }));
                                  }}
                                />
                              </label>
                            </fieldset>
                          ) : field?.type === "dropdown" && !field?.multiple ? (
                            <fieldset
                              key={field?.name + index}
                              className="col-span-1 h-12 shrink-0 border border-black-300 dark:border-black-700 rounded"
                            >
                              <legend className="ml-2 px-2 bg-white dark:bg-black-800 rounded text-black-500 dark:text-black-400 dark:font-medium font-semibold uppercase text-[0.65rem]">
                                {field?.visible_name}{" "}
                                {field?.required && (
                                  <span className="text-red-600">*</span>
                                )}
                              </legend>
                              <ComboBox
                                clear={clear}
                                setClear={setClear}
                                multiple={field?.multiple}
                                hasDefault={field.hasDefault}
                                passedList={field?.dropdownList}
                                element={field}
                                setValues={setValues}
                                inputValue={inputValue}
                                inputStyles={
                                  "new_ticket_input_field h-full w-full max-w-[50%] overflow-hidden whitespace-nowrap text-ellipsis p-2 px-4 pt-1 bg-transparent dark:text-black-300 text-black-700 border-0 outline-none focus:outline-none focus:ring-0 focus:border-0 text-xs font-normal capitalize dark:placeholder:text-black-600 placeholder:text-black-400 select-none"
                                }
                              />
                            </fieldset>
                          ) : field?.type === "dropdown" && field?.multiple ? (
                            <fieldset
                              key={field?.name + index}
                              className="col-span-1 h-12 shrink-0 border border-black-300 dark:border-black-700 rounded"
                            >
                              <legend className="ml-2 px-2 bg-white dark:bg-black-800 rounded text-black-500 dark:text-black-400 dark:font-medium font-semibold uppercase text-[0.65rem]">
                                {field?.visible_name}{" "}
                                {field?.required && (
                                  <span className="text-red-600">*</span>
                                )}
                              </legend>
                              <MultiSelect
                                clear={clear}
                                setClear={setClear}
                                hasDefault={field.hasDefault}
                                passedList={field?.dropdownList}
                                element={field}
                                setValues={setValues}
                                inputValue={inputValue}
                                inputStyles={
                                  "new_ticket_input_field h-full w-full max-w-[50%] overflow-hidden whitespace-nowrap text-ellipsis p-2 px-4 pt-1 bg-transparent dark:text-black-300 text-black-700 border-0 outline-none focus:outline-none focus:ring-0 focus:border-0 text-xs font-normal capitalize dark:placeholder:text-black-600 placeholder:text-black-400 select-none"
                                }
                              />
                            </fieldset>
                          ) : field?.type === "text_multiple" ? (
                            <fieldset className="col-span-1 h-12 shrink-0 border border-black-300 dark:border-black-700 rounded px-2">
                              <legend className="px-2 bg-white dark:bg-black-800 rounded text-black-500 dark:text-black-400 dark:font-medium font-semibold uppercase text-[0.65rem]">
                                {field?.visible_name}{" "}
                                {field?.required && (
                                  <span className="text-red-600">*</span>
                                )}
                              </legend>
                              <TextMultiple
                                clear={clear}
                                setClear={setClear}
                                element={field}
                                setValues={setValues}
                                inputValue={inputValue}
                                inputStyles={
                                  "new_ticket_input_field h-full w-full overflow-hidden whitespace-nowrap text-ellipsis p-2 px-2 pt-1 bg-transparent dark:text-black-300 text-black-700 border-0 outline-none focus:outline-none focus:ring-0 focus:border-0 text-xs font-normal lowercase placeholder:capitalize dark:placeholder:text-black-600 placeholder:text-black-400 select-none"
                                }
                              />
                            </fieldset>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      );
                    })}

                  {/**Description field  ============= */}
                  {[...ticketFields.current]
                    ?.filter((field: any) => field?.name === "description")
                    ?.map((field: any, index: number) => {
                      return (
                        <div
                          key={field.name + index}
                          onClick={(e) => zoomElement(e)}
                          className={`col-span-2 w-full h-fit overflow-hidden relative`}
                        >
                          <Editor
                            uploadingStatus={uploadingStatus}
                            editorTextBoxRef={editorTextBoxRef}
                            slashComm={(bool: boolean) =>
                              bool ? setRes(true) : setRes(false)
                            }
                            value={value}
                            onChange={onChange}
                            showChannel={true}
                            setUploadStatus={setUploadStatus}
                            editorClassName={"newTicketEditor"}
                            toolBarStyles="w-full dark:bg-black-700 bg-black-50 border-b border-black-300 dark:border-black-700"
                            otherStyles="relative rounded bg-inherit transition-all duration-150 shadow-lg text-xs 
                           text-black-700 dark:text-black-300 border border-black-300 dark:border-black-700 min-h-[10rem] w-full"
                          />
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="h-full w-full overflow-hidden flex items-center justify-center py-8">
                  <div
                    className="bg-transparent h-10 w-10 rounded-full border-4 border-black-200
                    dark:border-black-600 border-l-blue-700 animate-spin"
                  ></div>
                </div>
              )}
              {/**Bottom Controls ========================================= */}
              <div
                className="dark:bg-black-700 bg-black-50 h-[3.5rem] shrink-0 w-full p-2 px-4 flex justify-between
             items-center z-[99]  select-none border-t border-black-200 dark:border-black-600 shadow-md"
              >
                <div className="flex justify-center items-center space-x-2">
                  <button
                    onClick={() => {
                      window.localStorage.setItem(
                        "newTicketDraftValues",
                        JSON.stringify({ ...inputValue, description: value })
                      );
                      setModal(false);
                      setSubmit(false);
                      document.body.style.overflow = "";
                    }}
                    disabled={isSubmiting ? true : false}
                    type="button"
                    className="h-8 px-4 flex justify-center items-center space-x-3 outline-none focus:outline-none
                     hover:opacity-80 rounded-sm font-medium text-xxs text-black-600 dark:text-black-300 font-sans transition-all disabled:cursor-not-allowed
                      uppercase bg-white dark:bg-black-600 border border-black-300 dark:border-black-500"
                  >
                    close
                  </button>
                  {/**Reset Input ========================================= */}
                  <div className="relative group">
                    <button
                      type="reset"
                      onClick={() => {
                        setClear(true);
                        const editor =
                          document.getElementsByClassName("newTicketEditor")[0];
                        onChange("");
                        window.localStorage.setItem(
                          "newTicketDraftValues",
                          JSON.stringify(
                            ticketFields.current?.reduce(
                              (obj: any, val: any) => ({
                                ...obj,
                                [val.name]: "",
                              }),
                              {}
                            )
                          )
                        );
                        setValues(
                          ticketFields.current?.reduce(
                            (obj: any, val: any) => ({
                              ...obj,
                              [val.name]: "",
                            }),
                            {}
                          )
                        );
                        if (editor) {
                          editor.innerHTML = "";
                        }
                        if (editor) {
                          editor.innerHTML = "";
                        }
                      }}
                      className="h-8 w-8 flex justify-center items-center outline-none focus:outline-none
                     hover:opacity-80 rounded text-red-500 border border-black-300 dark:border-black-500 dark:border-salte-700"
                    >
                      <BiTrash />
                    </button>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  {/**Canned Responses ========================================= */}
                  <div ref={cannedModRef} className="relative group">
                    <button
                      type="button"
                      onClick={() => {
                        setRes((prev: boolean) => (prev ? false : true));
                      }}
                      className="w-8 h-8 group flex items-center justify-center text-black-600 dark:text-black-300
                    rounded border hover:border-blue-600 dark:hover:border-blue-600 cursor-pointer
                      transition-all duration-200 dark:border-black-500 border-black-300 outline-none focus:outline-none"
                    >
                      <BiCollection />
                    </button>
                    <CannedResponses
                      editorTextBoxRef={editorTextBoxRef}
                      openCannedRes={openCannedRes}
                      setRes={setRes}
                      setReply={setValues}
                      position={4}
                      tooltipPosition={`bottom-14 -right-4`}
                      editorClassName={"newTicketEditor"}
                      onChange={onChange}
                    />
                  </div>
                  {/**Send ========================================= */}
                  <div
                    className={`flex items-center transition-all select-none bg-black-900 dark:bg-blue-600 text-white rounded pt-0.5`}
                  >
                    <button
                      disabled={isSubmiting ? true : false}
                      type="submit"
                      className="h-8 px-6 flex justify-center items-center space-x-3 outline-none focus:outline-none 
                       hover:opacity-80 rounded-md font-medium text-xxs font-sans transition-all disabled:cursor-not-allowed uppercase"
                    >
                      <span>create ticket</span>
                      <div
                        className={`h-4 w-4 rounded-full border-2 border-blue-500 border-l-white animate-spin ${
                          isSubmiting ? "" : "hidden"
                        }`}
                      ></div>{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/**Zoomed Imag Modal */}
          <ZoomedMed zoomMed={zoomMed} setZoomed={setZoomed} type={zoomType} />
          {/**Zoomed Imag Modal */}
        </motion.div>
      )}

      {/**Duplicates */}
      <Duplicates duplicates={duplicates} setDuplicates={setDuplicates} />
      {/**Duplicates */}
    </AnimatePresence>
  );
};

export default NewTicket;
