import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const getLocal = (name: string) => {
  const data = window.localStorage.getItem(name);
  return data ? JSON.parse(data) : undefined;
};

interface InitialInterface {
  bookMarked: any[];
  markedArray: any[];
  filteredChatData: any[];
  activeTicket: any;
  newTicket: any;
  pagination: any;
  newCannedResponseModal:boolean;
}

const initialState: InitialInterface = {
  bookMarked: getLocal("bookMarked") || [],
  markedArray: getLocal("markedArray") || [],
  filteredChatData: getLocal("filteredChatData") || [],
  pagination: getLocal("pagination") || { start: 0, end: 40 },
  activeTicket: getLocal("activeTicket") || null,
  newTicket: null,
  newCannedResponseModal:false
};

const ChatSlice = createSlice({
  name: "ChatData",
  initialState,
  reducers: {
    bookMarkTicket: (state, action: PayloadAction<any[]>) => {
      state.bookMarked = action.payload;
      window.localStorage.setItem("bookMarked", JSON.stringify(action.payload));
    },
    selectTicket: (state, action: PayloadAction<any>) => {
      state.activeTicket = action.payload;
      window.localStorage.setItem(
        "activeTicket",
        JSON.stringify(action.payload)
      );
    },
    markTicket: (state, action: PayloadAction<any[]>) => {
      state.markedArray = action.payload;
      window.localStorage.setItem(
        "markedArray",
        JSON.stringify(action.payload)
      );
    },
    filterTickets: (state, action: PayloadAction<any[]>) => {
      state.filteredChatData = action.payload?.sort((a: any, b: any) =>
        a?.date < b?.date &&
        state.bookMarked?.some((data: any) => data?.ticket_id === a?.ticket_id)
          ? -1
          : 1
      );
      window.localStorage.setItem(
        "filteredChatData",
        JSON.stringify(
          action.payload?.sort((a: any, b: any) =>
            a?.date < b?.date &&
            state.bookMarked?.some(
              (data: any) => data?.ticket_id === a?.ticket_id
            )
              ? -1
              : 1
          )
        )
      );
    },
    updateNewTicket: (state, action: PayloadAction<any>) => {
      state.newTicket = action.payload;
      window.localStorage.setItem("newTicket", JSON.stringify(action.payload));
    },
    paginate: (
      state,
      action: PayloadAction<{ start: number; end: number }>
    ) => {
      state.pagination = action.payload;
      window.localStorage.setItem("pagination", JSON.stringify(action.payload));
    },
    openNewCannedResponse: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.newCannedResponseModal = action.payload;
      window.localStorage.setItem("newCannedResponseModal", JSON.stringify(action.payload));
    },
  },
});
export const {
  bookMarkTicket,
  markTicket,
  selectTicket,
  updateNewTicket,
  filterTickets,
  paginate,
  openNewCannedResponse
} = ChatSlice.actions;

export default ChatSlice.reducer;
