import { FC, useState } from "react";
import { TbEye, TbTrash, TbArrowMerge } from "react-icons/tb";
import Details from "../Thread/Details/Details";
import {
  addNotification,
  deleteTicket,
} from "../../../APIs & Data/TicketsnUserData";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../Redux/store";
import { updateAlert } from "../../../Redux/Slices/NotificationsSlice";

type Props = {
  duplicates: any;
  setDuplicates: any;
};

const Duplicates: FC<Props> = ({ duplicates, setDuplicates }) => {
  const user = useSelector(
    (state: RootState) => state.UserInfo.member_details
  )[0];
  const [showDetails, setDetails] = useState<boolean>(false);
  const [openTicket, setopenTicket] = useState<any[]>([]);
  const alerts = useSelector(
    (state: RootState) => state.NotificationsData.alerts
  );
  const dispatch: AppDispatch = useDispatch();

  //Component
  return (
    <div
      className={`fixed z-[99999] top-0 bottom-0 left-0 right-0 bg-black-900/70 ${
        duplicates.length >= 1 ? "flex" : "hidden"
      } justify-center p-6 pt-20`}
    >
      <div className="w-[35rem] h-[30rem] rounded bg-white dark:bg-black-700 relative drop-shadow-2xl p-4">
        <div className="h-12 w-12 rounded-full border-black-300 border-2 bg-white dark:bg-black-600 flex justify-center items-center text-xl text-black-700 dark:text-black-200 font-bold absolute -top-6 left-4 capitalize">
          {duplicates.length >= 1 && duplicates[0]["recipient name"]?.charAt(0)}
        </div>
        {/**Heading */}
        <div className="mt-4 text-sm font-semibold text-black-700 dark:text-black-300 dark:text-black-1000 uppercase flex items-center justify-between">
          <span>Duplicates Found</span>
          <button
            onClick={() => {
              setDuplicates([]);
            }}
            className="bg-blue-600 text-white text-xs tracking-widest capitalize px-4 h-8 rounded-sm hover:opacity-80 transition-all"
          >
            IGNORE
          </button>
        </div>
        {/**Heading */}
        <ul className="mt-4 w-full h-[calc(100%-4rem)] overflow-hidden overflow-y-scroll pr-2 space-y-2">
          {duplicates.length >= 1 &&
            duplicates
              ?.sort((a: any, b: any) => b.date - a.date)
              ?.map((ticket: any) => {
                return (
                  <li
                    key={ticket.id}
                    className={`w-full min-h-[5.5rem] h-[5.5rem] opacity-100 transition-all duration-200 rounded-sm border dark:border-black-700 border-black-200 relative snap_childTwo hover:bg-black-50 dark:hover:bg-black-700 px-2 bg-black-100 dark:bg-black-800`}
                  >
                    {/**Ticket Details ========================================== */}
                    <div className="flex space-x-2 relative h-full w-full overflow-hidden">
                      <div className="w-full pl-2 flex flex-col space-y-1 justify-center text-[0.7rem] capitalize font-medium tracking-normal text-black-700 dark:text-black-300 relative">
                        <span className="max-w-[10-rem] overflow-hidden whitespace-nowrap overflow-ellipsis">
                          [{ticket.category}] |{" "}
                          {ticket.company
                            ? ticket.company
                            : ticket["recipient name"]?.split(/,|\//)[0]}
                        </span>
                        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis flex items-center h-4 space-x-2 text-black-500 dark:text-black-400">
                          <span>
                            {new Date(ticket?.date)?.toString()?.split("(")[0]}{" "}
                          </span>
                        </div>
                        <div className="flex items-center space-x-2 pt-[0.1rem]">
                          <div className="w-32 h-5 pt-[.1rem] flex justify-center items-center rounded-sm bg-black-50 dark:bg-black-700 border dark:border-black-600 border-black-300">
                            <span className="w-full whitespace-nowrap overflow-ellipsis overflow-hidden text-center text-[0.65rem] text-black-600 dark:text-black-400 uppercase  font-medium">
                              {ticket.ticket_id?.replace(/\s/g, "")}
                            </span>
                          </div>
                          <div
                            className={`w-20 h-5 pt-[.15rem] flex justify-center items-center  border-l-2 ${
                              (new Date(ticket.due_date).getTime() <=
                                new Date().getTime() &&
                                ticket.status &&
                                ticket.status?.toLowerCase() === "open") ||
                              (ticket.status &&
                                ticket.status?.toLowerCase() === "reopened")
                                ? "text-red-500 border-red-500 bg-[#dc262611]"
                                : ticket.status?.toLowerCase() === "pending"
                                ? "text-orange-500 border-orange-500 bg-[#f9741612]"
                                : ticket.status &&
                                  ticket.status?.toLowerCase() === "solved"
                                ? "text-emerald-500 border-emerald-500 bg-[#10b98111]"
                                : ticket.status &&
                                  ticket.status?.toLowerCase() === "closed"
                                ? "text-teal-600 border-teal-600 bg-[#0d948911]"
                                : ticket.status?.toLowerCase() === "open" &&
                                  new Date(ticket.due_date).getTime() >
                                    new Date().getTime()
                                ? "text-blue-600 border-blue-500 bg-[#2564eb10]"
                                : ""
                            } `}
                          >
                            <span className="w-full whitespace-nowrap overflow-ellipsis overflow-hidden text-center text-[0.6rem] uppercase font-medium tracking-wider">
                              {new Date(ticket.due_date).getTime() <=
                                new Date().getTime() &&
                              ticket.status &&
                              ticket.status?.toLowerCase() === "open"
                                ? "Overdue"
                                : ticket?.status}
                            </span>
                          </div>
                        </div>

                        {/**Options */}
                        <div className="absolute right-1 h-10 p-1 w-fit flex items-center space-x-1 bg-inherit">
                          <button
                            onClick={() => {
                              setDetails(true);
                              setopenTicket([ticket]);
                            }}
                            className="h-9 w-9 rounded bg-white dark:bg-black-800 text-black-700 dark:text-black-200 text-base flex items-center justify-center border border-black-300 dark:border-black-600"
                          >
                            {" "}
                            <abbr title="View Ticket details">
                              <TbEye />
                            </abbr>
                          </button>
                          <button
                            disabled
                            className="h-9 w-9 rounded bg-white dark:bg-black-800 text-black-700 dark:text-black-200 text-base flex items-center justify-center border border-black-300 dark:border-black-600 disabled:cursor-not-allowed"
                          >
                            {" "}
                            <abbr title="Merge Ticket">
                              <TbArrowMerge />
                            </abbr>
                          </button>
                          <button
                            disabled={
                              ticket["assignee email"] === user?.email ||
                              user?.access === "admin"
                                ? false
                                : true
                            }
                            onClick={() => {
                              deleteTicket(ticket?.id);
                              addNotification(
                                ticket["assignee id"],
                                "New Dduplicate",
                                `${user?.name} received a duplicate regarding ticket id: ${ticket?.ticket_id}`
                              );
                              setDuplicates([]);
                              setDetails(false);
                              setopenTicket([]);
                              dispatch(
                                updateAlert([
                                  ...alerts,
                                  {
                                    message: "duplicate has been deleted",
                                    color: "bg-green-200",
                                    id: new Date().getTime(),
                                  },
                                ])
                              );
                            }}
                            className="h-9 w-9 rounded bg-white dark:bg-black-800 text-black-700 dark:text-black-200 text-base flex items-center justify-center border border-black-300 dark:border-black-600 disabled:cursor-not-allowed"
                          >
                            <abbr title="Delete Ticket">
                              <TbTrash />
                            </abbr>
                          </button>
                        </div>
                        {/**Options */}
                      </div>
                    </div>
                  </li>
                );
              })}
        </ul>
      </div>

      {/**More Details */}
      <Details
        showDetails={showDetails}
        setDetails={setDetails}
        openTicket={openTicket}
      />
      {/**More Details */}
    </div>
  );
};

export default Duplicates;
