import { FC } from "react";
import { TbLayoutList, TbCopy, TbCheck } from "react-icons/tb";
import { formatMilliseconds } from "../../../../Reusable Functions/Reusable_Func";

type Props = {
  openTicket: any;
};

const AdditionalDetails: FC<Props> = ({ openTicket }) => {
  const ticket = openTicket || {};

  //Component ========================
  return (
    <ul
      className="w-full h-fit p-4 rounded drop-shadow border border-black-200 dark:border-black-700
       bg-white dark:bg-black-800 flex flex-col divide-y divide-black-200 dark:divide-black-400/10 select-none"
    >
      {ticket &&
        Object.keys(ticket)
          ?.filter(
            (data: any) =>
              ![
                "date",
                "due_date",
                "waiting_time",
                "1st_time_response",
                "resolution_time",
                "close date",
                "id",
                "description",
                "logs",
                "assignee id",
                "resolution",
                "collaborators",
                "thread",
                "assigneeid",
                "read_by",
                "feedback",
                "recipient insta_id",
                "recipient messanger_id",
                "recipient slack_id",
                "recipient twitter_id",
                "collaborators",
                "carboncopy",
                "recipient whatsapp_number",
                "notes",
                "company",
                "category",
              ]?.some((item: any) => item === data)
          )
          ?.sort((a: any, b: any) => (a < b ? -1 : 1))
          ?.map((field: string) => {
            return (
              <li
                key={field+field+field}
                className="text-xs flex items-center justify-between gap-4 w-full h-10 min-h-[2.5rem]"
              >
                <div
                  className={`"hidden sm:flex items-center space-x-2 font-semibold capitalize w-2/5 text-xs
                  overflow-hidden whitespace-nowrap overflow-ellipsis text-black-700 dark:text-black-200`}
                >
                  <TbLayoutList className="text-lg" />
                  <span>{field?.replace(/_/gim, " ")}</span>
                </div>
                <div
                  className={`"hidden sm:flex w-3/5 h-full text-[0.65rem] overflow-hidden whitespace-nowrap overflow-ellipsis ${
                    field?.includes("email")
                      ? "lowercase text-blue-600"
                      : "capitalize"
                  } truncate text-black-600 dark:text-black-300 text-xs font-medium relative
                flex items-center justify-between`}
                >
                  <span className="w-[calc(100%-2rem)] whitespace-nowrap truncate overflow-hidden">
                    {openTicket && field?.toLowerCase()?.includes("date")
                      ? ticket?.[field]
                        ? new Date(ticket?.[field])?.toString()?.split("GMT")[0]
                        : ""
                      : field?.toLowerCase()?.includes("time")
                      ? `${formatMilliseconds(ticket?.[field])}`
                      : ticket?.[field]?.toString()}
                  </span>
                  <label htmlFor={field+field+field} className="relative">
                    <div title="Copy" className="chatDetailsCopyBtn">
                      <input
                        type="checkbox"
                        name={field+field+field}
                        id={field+field+field}
                        className="scale-0 absolute peer"
                        onChange={(e) => {
                          if (ticket?.[field]) {
                            navigator?.clipboard?.writeText(
                              ticket?.[field]?.toString()
                            );
                            setTimeout(() => {
                              e.target.checked = false;
                            }, 2000);
                          }
                        }}
                      />
                      <TbCopy className="peer-checked:hidden" />
                      <TbCheck className="hidden peer-checked:block text-green-600" />
                    </div>
                  </label>
                </div>
              </li>
            );
          })}
    </ul>
  );
};

export default AdditionalDetails;
