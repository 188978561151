import React from 'react'
import Loader from '../Components/Misc/Loader'
import { Navigate } from 'react-router-dom'

type Props = {}

export const AppRedirect = (props: Props) => {


  return (
    <div className='fixed inset-0'>
        <Loader/>
        <Navigate to='/'/>
    </div>
  )
}