import { FC, useRef } from "react";
import Filters from "./Filters";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import {
  changeChatDate,
  changeLoadingStatus,
  updateChatDataFilters,
} from "../../../Redux/Slices/Tickets_n_Contacts_Slice";
import DatePicker from "../../../Components/Date Picker/DatePicker";
import useClickOutside from "../../../Custom-Hooks/useOnClickOutsideRef";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  setfiltersModal: any;
  filtersModal: any;
}

const OffCanvasMenu: FC<Props> = ({ filtersModal, setfiltersModal }) => {
  const chatDates = useSelector((state: RootState) => state.Tickets.chatDates);
  const dispatch = useDispatch();
  const filtersSelectRef = useRef<HTMLSelectElement>(null);
  const filtersSelect2Ref = useRef<HTMLSelectElement>(null);
  const filtersSelect3Ref = useRef<HTMLSelectElement>(null);
  const overlayRef = useClickOutside(() => {
    setfiltersModal(false);
  });

  //Component ================
  return (
    <AnimatePresence>
      {filtersModal && (
        <motion.div
          initial={{ left: "-100%", right: "auto" }}
          exit={{ left: "-100%", right: "auto" }}
          animate={{ left: 0, right: "auto" }}
          transition={{ duration: 0.3 }}
          ref={overlayRef}
          className={`fixed top-0 bottom-0 flex justify-center  h-screen dark:bg-black-800 bg-black-50 backdrop-blur-sm
           shadow-2xl p-5 w-[22rem] z-[9999] left-[4.25rem] right-0 transition-all duration-100 border-r
            border-black-200 dark:border-black-600`}
        >
          <div
            className={`h-full flex flex-col items-center gap-2  pb-12 w-full transition-all`}
          >
            <div
              className="absolute bottom-0 grid grid-cols-2 bg-inherit w-full h-14 shrink-0 border-t
           border-black-300 dark:border-black-600"
            >
              {/**Reset Filters ================ */}
              <button
                onClick={() => {
                  dispatch(
                    updateChatDataFilters({
                      ticket_id: "",
                      assignee: [],
                      stores: [],
                      category: "",
                      status: "",
                      priority: "",
                    })
                  );
                  if (filtersSelectRef && filtersSelectRef.current) {
                    filtersSelectRef.current.selectedIndex = 0;
                  }
                  if (filtersSelect2Ref && filtersSelect2Ref.current) {
                    filtersSelect2Ref.current.selectedIndex = 0;
                  }
                  if (filtersSelect3Ref && filtersSelect3Ref.current) {
                    filtersSelect3Ref.current.selectedIndex = 0;
                  }
                }}
                className="h-full col-span-1 text-black-600 dark:text-black-300
             bg-black-200 dark:bg-black-700 hover:opacity-80 transition-all flex 
             justify-center items-center space-x-2 text-xs font-semibold uppercase select-none"
              >
                clear
              </button>
              <button
                onClick={() => setfiltersModal(false)}
                className="h-full col-span-1 text-black-600 dark:text-black-300
            bg-black-100 dark:bg-black-900 hover:opacity-80 transition-all flex 
            justify-center items-center space-x-2 text-xs font-semibold uppercase select-none"
              >
                close
              </button>
            </div>
            <div className="w-full h-[calc(100%-3.5rem)] space-y-2 pb-6">
              <DatePicker
                dates={chatDates}
                additionalStyles={`h-10 w-full col-span-1 bg-inherit rounded-sm border-2 border-black-300 dark:border-black-700
             focus:border-blue-600 dark:focus:border-blue-600 dark:placeholder:text-black-500 
             transition-all p-1 px-2 pr-10 text-xs font-medium tracking-wide font-sans text-black-700 dark:text-black-300`}
                changeDate={(e: any) => {
                  dispatch(changeChatDate(e));
                  dispatch(changeLoadingStatus(true));
                  setfiltersModal(false);
                }}
                parentWidth="col-span-1 h-10 dark:bg-black-900 bg-black-50 w-full min-w-[15rem] 
            lg:min-w-0 flex items-center rounded relative"
                datePosition={"right-left top-[110%]"}
              />
              <Filters
                filtersSelectRef={filtersSelectRef}
                filtersSelect2Ref={filtersSelect2Ref}
                filtersSelect3Ref={filtersSelect3Ref}
              />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OffCanvasMenu;
