import { useState, useEffect } from "react";
import error_bound from "./Assets/images/error_bound.png";
import { TbReload } from "react-icons/tb";
import { clearAllData } from "./Reusable Functions/Reusable_Func";
import { useSelector } from "react-redux";
import { RootState } from "./Redux/store";
import { addLog, org } from "./APIs & Data/TicketsnUserData";

function ErrorBoundary(props: any) {
  const user = useSelector(
    (state: RootState) => state.UserInfo.member_details
  )[0];

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setMessage] = useState("Something went wrong");

  useEffect(() => {
    const handleGlobalError = (event: any) => {
      // Handle the error here
      console.error("Global error caught:", event.error);
      setHasError(true);
      addLog({
        worskapce: org,
        date: new Date()?.getTime(),
        user_email: user?.email || "",
        log_message: event.error?.message || "Couldn't generate log message",
        details: JSON.stringify(event.error) || "Couldn't generate log message",
      });
    };
    window.addEventListener("error", handleGlobalError);
    return () => {
      window.removeEventListener("error", handleGlobalError);
    };
  }, [user?.email]);

  useEffect(() => {
    const handleGlobalError: any = (error: any, errorInfo: any) => {
      // Log the error to an error reporting service
      console.error("Global error caught:", error, errorInfo);
      setMessage(
        "Error: Data has been collected to help troubleshoot and prevent the error from happening again"
      );
      addLog({
        worskapce: org,
        date: new Date()?.getTime(),
        user_email: user?.email || "",
        log_message: error?.message || "Couldn't generate log message",
        details: JSON.stringify(errorInfo) || "",
      });
      // Update state to show the fallback UI
      setHasError(true);
    };

    window.addEventListener("error", handleGlobalError);
    return () => {
      window.removeEventListener("error", handleGlobalError);
    };
  }, [user?.email]);

  // Render the children as normal
  return hasError ? (
    <div className="w-screen h-screen flex items-center justify-center flex-col bg-black-300">
      <img src={error_bound} alt="erro iamg" className="h-20 w-20" />
      <h1 className="mt-4 font-bold text-black-600">Something went wrong</h1>
      <p className="mt-1 text-xs font-medium text-black-600 text-center max-w-[25rem] px-4">
        {errorMessage}{" "}
      </p>
      <button
        onClick={() => {
          if (user?.id) {
            window.location.reload();
          } else {
            clearAllData();
            window.location.assign("/login");
          }
        }}
        className="mt-4 h-9 w-36 bg-black-900 rounded-full text-white text-xs font-semibold capitalize
         flex items-center justify-center gap-3 hover:opacity-80 transition-all hover:ring-2 ring-offset-2
         ring-black-600"
      >
        <TbReload className="text-lg" /> <span>reload</span>
      </button>
    </div>
  ) : (
    props.children
  );
}

export default ErrorBoundary;
