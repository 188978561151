import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  //retriveLocal,
  saveLocal,
} from "../../Reusable Functions/Reusable_Func";
const initialLocation =
  window.localStorage.getItem("locationPath") || "Basedesk";

//Get Theme From Local Storage ==============
const initialTheme = localStorage.getItem("theme");
//&& window.matchMedia("(prefers-color-scheme: dark)").matches
//const initialUser = localStorage.getItem("bs-sessions-persist");

interface InitialStateType {
  username: string;
  allMembers: any[];
  member_details: any[] | any;
  routeLocation: string;
  cannedResponses: any[];
  publicCannedResponses: any[];
  toDo: any[];
  theme: string | null;
  company_name: string | any;
}

const initialState: InitialStateType = {
  username: "Unkown User",
  allMembers: [],
  member_details: [{}],
  routeLocation: initialLocation,
  cannedResponses: [],
  publicCannedResponses: [],
  toDo: [],
  //!initialTheme
  theme:
    // ? window.matchMedia("(prefers-color-scheme: dark)").matches
    //   ? "dark"
    //   : "light"
    initialTheme ? JSON.parse(initialTheme) : "light",
  company_name: localStorage.getItem("organization_name"),
};

export const UserSlice = createSlice({
  name: "UserInfo",
  initialState,
  reducers: {
    addAllMembers: (state, action: PayloadAction<any[]>) => {
      state.allMembers = action.payload;
    },
    updateUser: (state, action: PayloadAction<any[]>) => {
      state.member_details = action.payload;
      window.localStorage.setItem(
        "bs-sessions-persist",
        saveLocal(JSON.stringify(action.payload))
      );
    },
    changeLocation: (state, action: PayloadAction<string>) => {
      state.routeLocation = action.payload;
    },
    setToDo: (state, action: PayloadAction<any[]>) => {
      state.toDo = action.payload;
    },
    updateCannedRes: (state, action: PayloadAction<any[]>) => {
      state.cannedResponses = action.payload;
    },
    updatePublicCannedRes: (state, action: PayloadAction<any[]>) => {
      state.publicCannedResponses = action.payload;
    },
    changeTheme: (state, action: PayloadAction<string | null>) => {
      state.theme = action.payload;
    },
    setCompany: (state, action: PayloadAction<string | null>) => {
      state.company_name = action.payload;

      window.localStorage.setItem("organization_name", `${action.payload}`);
    },
  },
});

export const {
  updateUser,
  addAllMembers,
  changeLocation,
  setToDo,
  changeTheme,
  setCompany,
  updateCannedRes,
  updatePublicCannedRes,
} = UserSlice.actions;

export default UserSlice.reducer;
