// Get Current Position of Caret and Insert img ===============
export const insertAtCursor = (any: any) => {
  const selection = window.getSelection();
  let range = selection?.getRangeAt(0);
  range?.deleteContents();
  range?.insertNode(any);
  if (range) {
    range = range?.cloneRange();
    range?.setStartAfter(any);
    range?.collapse(true);
    selection?.removeAllRanges();
    selection?.addRange(range);
  }
};

//Convert Bytes To Readable File Size
export const convertBytes = (a: any) => {
  let b = 0,
    c = parseInt(a, 10) || 0;
  for (; 1024 <= c && ++b; ) c /= 1024;
  return (
    c.toFixed(10 > c && 0 < b ? 1 : 0) +
    " " +
    ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][b]
  );
};

//Listen For Slash And Fire Canned Res Shortcut
// export const slashCommands = (e: any, func: any) => {
//   let input = e.currentTarget?.innerText;
//   if (input.endsWith("/") && input.length === 1) {
//     // execute the function if the input is "/" and nothing else
//     func(true);
//   } else if (
//     !input.endsWith("/") ||
//     input.length > 1 ||
//     (input.length === 1 && input[0] !== "/")
//   ) {
//     // close the input if user types more than one character or types a character other than "/"
//     func(false);
//   }
// };

export const slashCommands = (e: any, func: any) => {
  const input = e.currentTarget?.innerText;
  if (
    (input.endsWith("/") && input.length === 1) ||
    (/\s\//.test(input) && input.endsWith("/"))
  ) {
    // execute the function if the input is "/" and nothing else
    func(true);
  } else {
    // close the input if user types more than one character,
    // types a character other than "/", or types a space after a word
    func(false);
  }
};

export const formatText = (format: string) => {
  const selection = window.getSelection();
  const range = selection?.getRangeAt(0);

  if (range && !range.collapsed) {
    const el = document.createElement(format);
    const fragment = range.cloneContents();
    const isApplied = fragment.querySelector(format);

    if (isApplied) {
      range.surroundContents(document.createElement("span"));
    } else {
      el.appendChild(fragment);
      range.deleteContents();
      range.insertNode(el);
    }
  }
};
