import { FC, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LogIn from "./Pages/Auth/LogIn";
import WorkspaceOption from "./Pages/Auth/WorkspaceOption";
import TicketsComponent from "./Pages/Tickets/TicketsComponent";
import ErrorBoundary from "./ErrorBoundary";
import PrivateRoutes from "./PrivateRoutes";
import Loader from "./Components/Misc/Loader";
import Legal from "./Pages/Legal/legal";
import License from "./Pages/Legal/license";
import ForgotPassword from "./Pages/forgot_password/forgot_password";
import ResetPassword from "./Pages/reset password/reset_password";
import { AppRedirect } from "./Pages/app redirect";

//Lazy Loading Components || Code Splitting ====================
const ErrorPage = lazy(() => import("./Pages/signup/ErrorPage"));
const ContactsComponent = lazy(
  () => import("./Pages/Contacts/ContactsComponent")
);
const ReportsComponent = lazy(() => import("./Pages/Reports/ReportComponent"));
const AppShell = lazy(() => import("./Pages/App Shell/AppShell"));
const Dashboard = lazy(() => import("./Pages/Dashboard/Dashboard"));
const Admin = lazy(() => import("./Pages/Settings/Admin"));
const TeamMembers = lazy(() => import("./Pages/Settings/Users/TeamMembers"));
const EndUsers = lazy(() => import("./Pages/Settings/Users/EndUsers"));
const Groups = lazy(() => import("./Pages/Settings/Groups/Groups"));
const Skills = lazy(() => import("./Pages/Settings/Skills/Skills"));
const Categories = lazy(() => import("./Pages/Settings/Categories/Categories"));
const CustomFields = lazy(
  () => import("./Pages/Settings/Custom Field/CustomFields")
);
const Emails = lazy(
  () => import("./Pages/Settings/Emails Management/EmailAccounts")
);
const Livechat = lazy(() => import("./Pages/Settings/Live Chat/LiveChat"));
const AppsNIntegrations = lazy(
  () => import("./Pages/Settings/Integrations/Integrations")
);
const WorkflowRouting = lazy(
  () => import("./Pages/Settings/Workflow & Routing/WorkflowRouting")
);
const Templates = lazy(() => import("./Pages/Settings/Templates/Templates"));
const Permissions = lazy(
  () => import("./Pages/Settings/Roles & Permissions/Permissions")
);
const SLAPolicies = lazy(
  () => import("./Pages/Settings/SLA Policies/Policies")
);
const Account = lazy(() => import("./Pages/Settings/User Account/Account"));
const General = lazy(() => import("./Pages/Settings/User Account/General"));
const Security = lazy(() => import("./Pages/Settings/User Account/Security"));
const AutomatedReports = lazy(
  () => import("./Pages/Settings/AutomatedReports")
);
const KnowledgeBase = lazy(
  () => import("./Pages/Knowledge Base/KnowledgeBase")
);

//Getting Started Or Setting Up An Account ========================
const NewAccountSignUp = lazy(
  () => import("./Pages/signup/New Account/NewAccount")
);

const App: FC = () => {
  //Component =================
  return (
    <ErrorBoundary>
      <div className="overflow-hidden">
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <Routes>
              {/**Landing ======================== */}
              <Route path="/legal" element={<Legal />} />
              <Route path="/license" element={<License />} />
              <Route path="/workspace-setup" element={<NewAccountSignUp />} />

              {/**Error Page ===========================*/}
              <Route path="*" element={<ErrorPage />} />

              {/**LogIn ======================== */}
              <Route path="/logIn" element={<LogIn />} />
              <Route path="/forgot_password" element={<ForgotPassword />} />
              <Route path="/reset_password" element={<ResetPassword />} />
              <Route path="/workspace" element={<WorkspaceOption />} />
              <Route path="/app" element={<AppRedirect />} />

              {/* <Route path="/support" element={<SupportHome />}>
              <Route path="new-ticket" element={<NewTicket />} />
              <Route path="knowledge_base" element={<KnowledgeBase />} />
            </Route> */}

              {/**App======================== */}
              <Route element={<PrivateRoutes />}>
                <Route path="/" element={<AppShell />}>
                  <Route path="" element={<Dashboard />} />
                  <Route path="tickets" element={<TicketsComponent />} />
                  <Route path="reports" element={<ReportsComponent />} />
                  <Route path="contacts" element={<ContactsComponent />} />
                  <Route path="settings" element={<Admin />}>
                    <Route path="" element={<TeamMembers />} />
                    <Route path="end_users" element={<EndUsers />} />
                    <Route path="groups" element={<Groups />} />
                    <Route path="skills" element={<Skills />} />
                    <Route path="categories" element={<Categories />} />
                    <Route path="custom_fields" element={<CustomFields />} />
                    <Route path="emails" element={<Emails />} />
                    <Route path="templates" element={<Templates />} />
                    <Route path="livechat" element={<Livechat />} />
                    <Route
                      path="apps_integration"
                      element={<AppsNIntegrations />}
                    />
                    <Route
                      path="workflow-routing"
                      element={<WorkflowRouting />}
                    />
                    <Route path="permissions" element={<Permissions />} />
                    <Route path="SLA_Policies" element={<SLAPolicies />} />
                  </Route>
                  <Route path="account" element={<Account />}>
                    <Route path="general" element={<General />} />
                    <Route path="security" element={<Security />} />
                  </Route>
                  <Route
                    path="automated-reports"
                    element={<AutomatedReports />}
                  />

                  {/**Support ======================== */}
                  <Route
                    path="knowledge-base"
                    element={<KnowledgeBase />}
                  ></Route>
                </Route>
              </Route>
              <Route path="*" element={ErrorPage} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    </ErrorBoundary>
  );
};

export default App;
