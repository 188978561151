import { FC, useState, useMemo } from "react";
import useClickOutside from "../../../Custom-Hooks/useOnClickOutsideRef";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../Redux/store";
import { BiBuilding } from "react-icons/bi";
import { updateChatDataFilters } from "../../../Redux/Slices/Tickets_n_Contacts_Slice";

interface Props {}
const CompanyFilter: FC<Props> = () => {
  const [search, setSearch] = useState<string | any>("");
  const [modal, setModal] = useState<boolean>(false);
  const modalRef = useClickOutside(() => {
    setModal(false);
  });
  const dispatch: AppDispatch = useDispatch();
  const chatData = useSelector((state: RootState) => state.Tickets.chatData);
  const chatDataFilters = useSelector(
    (state: RootState) => state.Tickets.chatDataFilters
  );
  const contactsList = useMemo(() => {
    const all = chatData?.map((data: any) =>
      data?.company?.toString()?.toLowerCase()?.trim()
    );
    return [...new Set(all)].filter(Boolean);
  }, [chatData]);
  const filteredData = useMemo(() => {
    return contactsList?.length >= 1
      ? [...contactsList]?.filter((data: any) =>
          data?.includes(search?.toString()?.toLowerCase()?.trim())
        )
      : [];
  }, [search, contactsList]);

  //Component ==================
  return (
    <div ref={modalRef} className="relative w-full col-span-1">
      <div
        className="w-full h-14 lg:h-10 flex items-center dark:bg-black-900 bg-black-50 overflow-hidden
       relative rounded border-2 dark:border-black-700 border-black-300 pl-8"
      >
        <BiBuilding className="dark:text-black-400 text-black-700 text-lg absolute h-14 lg:h-10 left-3" />
        <label htmlFor="company">
          <input
            type="search"
            name="company"
            id="company"
            autoComplete="off"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            onFocus={() => {
              setModal(true);
            }}
            className="w-full h-full outline-none focus:outline-none focus:ring-0
             border-0 text-xs dark:text-black-400 text-black-700 font-medium bg-inherit
              placeholder-black-900 dark:placeholder:text-black-400 rounded-sm"
            placeholder="Company ..."
          />
        </label>
      </div>

      {/**List ===================== */}
      <div
        className={`${
          modal ? "flex" : "hidden"
        } flex-col items-center p-2 absolute top-[110%] left-0 w-full h-[14rem] bg-white dark:bg-black-800 border border-black-300 dark:border-black-700 shadow-xl drop-shadow-xl z-[999] text-xs font-semibold dark:text-black-400 text-black-800 overflow-hidden rounded`}
      >
        <div className="mt-2 w-full h-full p-2 space-y-2 overflow-hidden overflow-y-scroll">
          <label
            htmlFor="select_all_compnies"
            className="w-full flex items-center space-x-2 capitalize"
          >
            {" "}
            <input
              type="checkbox"
              checked={chatDataFilters?.stores?.length <= 0}
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch(
                    updateChatDataFilters({
                      ...chatDataFilters,
                      stores: [],
                    })
                  );
                } else {
                  dispatch(
                    updateChatDataFilters({
                      ...chatDataFilters,
                      stores: [...filteredData]
                        ?.filter(
                          (data: any) => data?.length >= 1
                        )
                        ?.map((data: any) => data),
                    })
                  );
                }
              }}
              name="select_all_compnies"
              id="select_all_compnies"
            />
            <span className="whitespace-nowrap overflow-ellipsis overflow-hidden">
              Select All
            </span>
          </label>

          {filteredData?.slice(0, 30)?.map((name: any, index: number) => {
            return (
              <label
                key={index}
                htmlFor={index + name?.id}
                className={`w-full flex items-center space-x-2 capitalize`}
              >
                {" "}
                <input
                  type="checkbox"
                  checked={
                    chatDataFilters?.stores?.length >= 1
                      ? !chatDataFilters?.stores?.some(
                          (data: any) =>
                            data
                              ?.toString()
                              ?.toLowerCase()
                              ?.replace(/\/s/g, "") ===
                            name
                        )
                      : true
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      dispatch(
                        updateChatDataFilters({
                          ...chatDataFilters,
                          stores: [
                            ...chatDataFilters?.stores?.filter(
                              (data: any) => data !== name
                            ),
                          ],
                        })
                      );
                    } else {
                      dispatch(
                        updateChatDataFilters({
                          ...chatDataFilters,
                          stores: [
                            ...chatDataFilters?.stores?.filter(
                              (data: any) => data !== name
                            ),
                            name,
                          ],
                        })
                      );
                    }
                  }}
                  value={name}
                  name={index + name?.id}
                  id={index + name?.id}
                />
                <span className="whitespace-nowrap overflow-ellipsis overflow-hidden">
                  {name ? name : "N/A"}
                </span>
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CompanyFilter;

