import { FC, useEffect, useState } from "react";
import {
  TbListSearch,
  TbChevronDown,
  TbListDetails,
  TbAddressBook,
  TbActivity,
} from "react-icons/tb";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { useClickOutside } from "@mantine/hooks";

type Props = {
  element: any;
  inputStyles: any;
  passedList: any[];
  hasDefault: boolean;
  multiple: boolean | any;
  setValues: any;
  inputValue: any;
  clear: any;
  setClear: any;
};

const ComboBox: FC<Props> = ({
  element,
  inputStyles,
  passedList,
  hasDefault,
  setValues,
  inputValue,
  clear,
  setClear,
}) => {
  const [showDropDown, setShow] = useState<boolean>(false);
  const listModalRef = useClickOutside(() => setShow(false));
  const [selected, setSelected] = useState<any>(inputValue[element.name] ?? "");
  const [selectedObj, setSelectedObj] = useState<any>(inputValue);
  const [filteredList, setList] = useState<any[]>([]);
  const user = useSelector(
    (state: RootState) => state?.UserInfo?.member_details
  )[0];

  //Update Value On Clear
  useEffect(() => {
    if (clear) {
      if (hasDefault) {
        setSelected(
          passedList ? passedList[0]?.name : inputValue[element.name] ?? ""
        );
        setSelectedObj(
          passedList ? passedList[0]?.hidden_property : inputValue
        );
        setClear(false);
      } else {
        setSelected(inputValue[element.name] ?? "");
        setSelectedObj(inputValue);
        setClear(false);
      }
    }
  }, [clear, setClear, element.name, inputValue, passedList, hasDefault]);

  //Update Initial Value
  useEffect(() => {
    if (hasDefault && selected === "") {
      setSelected(
        passedList ? passedList[0]?.name : inputValue[element.name] ?? ""
      );
      setSelectedObj(passedList ? passedList[0]?.hidden_property : inputValue);
    }
  }, [selected, hasDefault, inputValue, passedList, element]);

  //Update Value of List
  useEffect(() => {
    setList(
      passedList
        ? passedList?.filter(
            (data: any) => data?.hidden_property?.access !== "client"
          )
        : []
    );
  }, [passedList]);

  //Update Values On Change
  useEffect(() => {
    if (inputValue[element.name] !== selected && user?.email) {
      setValues((prev: any) => ({ ...prev, [element.name]: selected }));
      if (element.name === "assignee name" && selectedObj) {
        setValues((prev: any) => ({
          ...prev,
          "assignee email": selectedObj?.email,
          "assignee id": selectedObj?.id,
          "assigner email": user?.email,
        }));
      } else if (element.name === "recipient name") {
        setValues((prev: any) => ({
          ...prev,
          "recipient email": selectedObj?.email,
          "recipient phone": selectedObj?.phone ?? "",
          "recipient insta_id": selectedObj?.insta_id ?? "",
          "recipient messanger_id": selectedObj?.messanger_id ?? "",
          "recipient slack_id": selectedObj?.slack_id ?? "",
          "recipient twitter_id": selectedObj?.twitter_handle ?? "",
          "recipient whatsapp_number": selectedObj?.whatapp_number ?? "",
          company: selectedObj?.["branch_company"] ?? "",
          thread_size: 1,
          "requester email": user?.email,
          "requester name": user?.name,
        }));
      } else if (element.name === "status") {
        setValues((prev: any) => ({
          ...prev,
          fcr: selected === "solved" ? true : false,
        }));
      }
    }
  }, [selected, element, setValues, inputValue, selectedObj, user]);

  //Component ======
  return (
    <div
      onMouseLeave={() => {
        if (
          [...passedList]?.filter(
            (data) =>
              data?.name
                ?.toLowerCase()
                ?.replace(/\s/gi, "")
                ?.includes(selected?.toLowerCase()?.replace(/\s/gi, "")) ||
              data?.value
                ?.toLowerCase()
                ?.replace(/\s/gi, "")
                ?.includes(selected?.toLowerCase()?.replace(/\s/gi, ""))
          ).length <= 0
        ) {
          setSelected(passedList ? passedList[0]?.name : "");
          setSelectedObj(passedList ? passedList[0] : {});
        } else if (!selected) {
          setSelected("");
          setSelectedObj({});
        } else {
          setSelected(
            [...passedList]?.filter(
              (data) =>
                data?.name
                  ?.toLowerCase()
                  ?.replace(/\s/gi, "")
                  ?.includes(selected?.toLowerCase()?.replace(/\s/gi, "")) ||
                data?.value
                  ?.toLowerCase()
                  ?.replace(/\s/gi, "")
                  ?.includes(selected?.toLowerCase()?.replace(/\s/gi, ""))
            )[0]?.name
          );
          setSelectedObj(
            [...passedList]?.filter(
              (data) =>
                data?.name
                  ?.toLowerCase()
                  ?.replace(/\s/gi, "")
                  ?.includes(selected?.toLowerCase()?.replace(/\s/gi, "")) ||
                data?.value
                  ?.toLowerCase()
                  ?.replace(/\s/gi, "")
                  ?.includes(selected?.toLowerCase()?.replace(/\s/gi, ""))
            )[0]?.hidden_property
          );
        }
      }}
      ref={listModalRef}
      className="relative"
    >
      <div
        onClick={() => {
          setShow(true);
        }}
        className="w-full h-full flex flex-nowrap justify-between items-center whitespace-nowrap overflow-hidden relative cursor-text"
      >
        {/**Input ============= */}
        <input
          onFocus={() => {
            setShow(true);
          }}
          onKeyDown={() => {
            setShow(true);
          }}
          type="text"
          autoComplete="off"
          title={"Please Add " + element?.name}
          required={element?.required}
          name={element?.name}
          id={element?.name}
          placeholder={element?.placeholder}
          className={`${
            showDropDown || !selected ? "" : "!hidden"
          } ${inputStyles}`}
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value);
            setList(
              [...passedList]
                ?.filter(
                  (data) =>
                    data?.name
                      ?.toLowerCase()
                      ?.replace(/\s/gi, "")
                      ?.includes(
                        e.target.value?.toLowerCase()?.replace(/\s/gi, "")
                      ) ||
                    data?.value
                      ?.toLowerCase()
                      ?.replace(/\s/gi, "")
                      ?.includes(
                        e.target.value?.toLowerCase()?.replace(/\s/gi, "")
                      )
                )
                ?.filter(
                  (data: any) => data?.hidden_property?.access !== "client"
                )
            );
          }}
        />
        {/**Chip ============= */}
        <div
          className={`ml-2 mt-0.5 w-fit h-fit p-0.5 rounded border border-black-300 dark:border-black-600 ${
            selected && !showDropDown ? "flex" : "!hidden"
          } justify-between items-center text-[.7rem] font-medium text-black-700 dark:text-black-300 capitalize bg-black-50 dark:bg-black-700 cursor-pointer`}
        >
          <span className="h-full px-2 border-r border-black-300 dark:border-black-600">
            {selected}
          </span>
          {/**Clear field ======= */}
          <button
            onClick={() => {
              setSelected("");
              setSelectedObj({});
            }}
            type="button"
            className="focus:outline-none outline-none h-full px-1 text-xs"
          >
            <abbr title="clear">&times;</abbr>
          </button>
        </div>

        <div className="absolute right-0 -top-0.5 bottom-0  h-full w-7 bg-inherit flex items-center justify-center">
          <TbChevronDown className="text-base text-black-400 dark:text-black-500" />
        </div>
      </div>

      {/**drop down */}
      <div
        className={`absolute top-[100%] h-fit w-full bg-transparent z-[999] pt-1.5 ${
          showDropDown ? "" : "!hidden"
        }`}
      >
        <div
          className={`min-h-[2.5rem]  w-full dark:bg-black-800 bg-white rounded-md p-2 px-3 space-y-1 border dark:border-black-700 border-black-400 shadow-2xl drop-shadow-2xl transition-all`}
        >
          {filteredList?.length <= 0 && (
            <div className="font-medium text-xs text-black-600 dark:text-black-500 h-full pt-0.5 flex items-center space-x-2 px-2">
              <TbListSearch className="text-lg" /> <span>No Results Found</span>
            </div>
          )}
          {filteredList?.length >= 1 && (
            <ul className="w-full h-full  max-h-[16rem] p-1 px-2 overflow-hidden overflow-y-scroll space-y-1
             no-scrollbar no-scrollbar::-webkit-scrollbar">
              {filteredList?.length >= 1 &&
                filteredList?.slice(0, 50)?.map((item, index) => {
                  return (
                    <li
                      key={item?.value + index}
                      onClick={() => {
                        setSelected(item.name);
                        setSelectedObj(item?.hidden_property);
                        setShow(false);
                      }}
                      className={`h-9 w-full font-medium capitalize text-xs text-black-600 dark:text-black-300 flex items-center 
                      space-x-2 px-2 hover:bg-black-200 dark:hover:bg-black-700 rounded cursor-pointer ${
                        selected?.length >= 1
                          ? selected
                              ?.toLowerCase()
                              ?.replace(/\s/gi, "")
                              ?.includes(
                                item?.value?.toLowerCase()?.replace(/\s/gi, "")
                              ) ||
                            selected
                              ?.toLowerCase()
                              ?.replace(/\s/gi, "")
                              ?.includes(
                                item?.name?.toLowerCase()?.replace(/\s/gi, "")
                              )
                            ? "bg-black-200 dark:bg-black-700"
                            : ""
                          : ""
                      }`}
                    >
                      {element.name === "assignee name" ? (
                        <div className="h-fit w-fit relative border-2 border-black-500 rounded-full">
                          <div
                            className={`w-2 h-2 rounded-full border border-white dark:border-black-700 absolute -right-0.5 -top-0.5 ${
                              item?.hidden_property?.status === "available"
                                ? "bg-green-600"
                                : item?.hidden_property?.status ===
                                  "unavailable"
                                ? "bg-red-600"
                                : "bg-yellow-500"
                            }`}
                          ></div>
                          <img
                            onError={(e) => {
                              e.currentTarget.src = "/logo/basedesk.png";
                            }}
                            src={
                              item?.hidden_property?.photoUrl ??
                              "/logo/basedesk.png"
                            }
                            alt="profile"
                            className="h-6 w-6 rounded-full object-covero object-center"
                          />
                        </div>
                      ) : element?.name === "recipient name" ? (
                        <TbAddressBook className="text-lg" />
                      ) : element?.name === "status" ? (
                        <TbActivity className="text-base" />
                      ) : (
                        <TbListDetails className="text-base" />
                      )}
                      <span className="whitespace-nowrap overflow-ellipsis overflow-hidden">
                        {item?.name}
                      </span>
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default ComboBox;
