import { FC, useMemo, useState } from "react";
import useClickOutside from "../../../Custom-Hooks/useOnClickOutsideRef";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../Redux/store";
import { BiUser } from "react-icons/bi";
import { updateChatDataFilters } from "../../../Redux/Slices/Tickets_n_Contacts_Slice";

interface Props {}

const AgentsFilter: FC<Props> = () => {
  const dispatch: AppDispatch = useDispatch();
  const allUsers = useSelector((state: RootState) => state.UserInfo.allMembers);
  const [modal, setModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string | any>("");
  const allAgents = useMemo(() => {
    return (
      allUsers?.filter(
        (data: any) =>
          data.access !== "client" &&
          Object?.values(data)?.some((item: any) =>
            item
              ?.toString()
              ?.toLowerCase()
              ?.replace(/\s/g, "")
              ?.includes(search?.toString()?.toLowerCase()?.replace(/\s/g, ""))
          )
      ) || []
    );
  }, [allUsers,search]);
  const modalRef = useClickOutside(() => {
    setModal(false);
  });
  const chatDataFilters = useSelector(
    (state: RootState) => state.Tickets.chatDataFilters
  );

  //Component ==================
  return (
    <div ref={modalRef} className="relative">
      <div
        className={`col-span-1 h-10 dark:bg-black-900 bg-black-50 w-full flex pl-7
         items-center overflow-hidden relative rounded border-2 dark:border-black-700 border-black-300`}
      >
        <BiUser className="dark:text-black-400 text-black-700 text-lg absolute h-full left-3" />
        <label htmlFor="assignee" className="">
          <input
            type="search"
            name="assignee"
            id="assignee"
            autoComplete="off"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            onFocus={() => {
              setModal(true);
            }}
            className="w-full h-full outline-none focus:outline-none focus:ring-0 border-0
             focus:border-0 text-xs dark:text-black-400 text-black-700 font-medium bg-inherit placeholder-black-900 dark:placeholder:text-black-400 rounded-sm"
            placeholder="Assignee ..."
          />
        </label>
      </div>

      {/**List ===================== */}
      <div
        className={`${
          modal ? "flex" : "hidden"
        } flex-col items-center p-2 absolute top-12 left-0] w-full min-w-[10rem] h-[15.5rem] bg-white dark:bg-black-800 border border-black-300 dark:border-black-700 shadow-xl drop-shadow-xl z-[999] text-xs font-semibold dark:text-black-400 text-black-800 overflow-hidden rounded`}
      >
        <div className="mt-2 w-full h-full p-2 space-y-2 overflow-hidden overflow-y-scroll">
          <label
            htmlFor="select_all"
            className="w-full flex items-center space-x-2 capitalize"
          >
            {" "}
            <input
              type="checkbox"
              checked={chatDataFilters?.assignee?.length <= 0}
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch(
                    updateChatDataFilters({ ...chatDataFilters, assignee: [] })
                  );
                } else {
                  dispatch(
                    updateChatDataFilters({
                      ...chatDataFilters,
                      assignee: [...allAgents?.map((data: any) => data?.email)],
                    })
                  );
                }
              }}
              name="select_all"
              id="select_all"
            />
            <span className="whitespace-nowrap overflow-ellipsis overflow-hidden">
              Select All
            </span>
          </label>

          {allAgents?.slice(0,30)?.map((assignee: any, index: any) => {
            return (
              <label
                key={index}
                htmlFor={index}
                className={`w-full flex items-center space-x-2 capitalize`}
              >
                {" "}
                <input
                  type="checkbox"
                  checked={
                    chatDataFilters?.assignee?.length >= 1
                      ? !chatDataFilters?.assignee?.some(
                          (data: any) =>
                            data
                              ?.toString()
                              ?.toLowerCase()
                              ?.replace(/\/s/g, "") ===
                            assignee?.email
                              ?.toString()
                              ?.toLowerCase()
                              ?.replace(/\/s/g, "")
                        )
                      : true
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      dispatch(
                        updateChatDataFilters({
                          ...chatDataFilters,
                          assignee: [
                            ...chatDataFilters?.assignee?.filter(
                              (data: any) => data !== assignee?.email
                            ),
                          ],
                        })
                      );
                    } else {
                      dispatch(
                        updateChatDataFilters({
                          ...chatDataFilters,
                          assignee: [
                            ...chatDataFilters?.assignee?.filter(
                              (data: any) => data !== assignee?.email
                            ),
                            assignee?.email,
                          ],
                        })
                      );
                    }
                  }}
                  name={index}
                  id={index}
                />
                <span className="whitespace-nowrap overflow-ellipsis overflow-hidden">
                  {assignee?.name}
                </span>
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AgentsFilter;
