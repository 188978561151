/* eslint-disable no-irregular-whitespace */
export const default_email_template = (
  inputValue: any,
  company_details: any,
  sendingAccountName: string
) => {
  return inputValue?.state !== "solved"
    ? `<!DOCTYPE HTML
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <!--[if gte mso 9]>
<xml>
  <o:OfficeDocumentSettings>
    <o:AllowPNG/>
    <o:PixelsPerInch>96</o:PixelsPerInch>
  </o:OfficeDocumentSettings>
</xml>
<![endif]-->
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="x-apple-disable-message-reformatting">
  <!--[if !mso]><!-->
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <!--<![endif]-->
  <title></title>

  <style type="text/css">
    @media only screen and (min-width: 520px) {
      .u-row {
        width: 500px !important;
      }

      .u-row .u-col {
        vertical-align: top;
      }

      .u-row .u-col-100 {
        width: 500px !important;
      }

    }

    img {
      width: 10rem !important;
    }

    p {
      font-size: 12px;
      line-height: 140% !important;
    }

    @media (max-width: 520px) {
      .u-row-container {
        max-width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }

      .u-row .u-col {
        min-width: 320px !important;
        max-width: 100% !important;
        display: block !important;
      }

      .u-row {
        width: calc(100% - 40px) !important;
      }

      .u-col {
        width: 100% !important;
      }

      .u-col>div {
        margin: 0 auto;
      }
    }

    body {
      margin: 0;
      padding: 0;
    }

    table,
    tr,
    td {
      vertical-align: top;
      border-collapse: collapse;
    }

    p {
      margin: 0;
    }

    .ie-container table,
    .mso-container table {
      table-layout: fixed;
    }

    * {
      line-height: inherit;
    }

    a[x-apple-data-detectors='true'] {
      color: inherit !important;
      text-decoration: none !important;
    }

    .dndhelp_file {
      color: #000 !important;
      text-decoration: none !important;
      font-size: 18px;
      display: inline-flex;
      width: 12rem;
      height: 2.5rem;
      align-items: center;
      border-radius: 0.75rem;
      border: solid 1px #cbd5e1;
      margin-top: 1rem;
      margin-bottom: 1rem;
      overflow: hidden;
      user-select: none;
      padding: 0.5rem
    }

    .dndhelp_file_tag {
      border-radius: 0.5rem;
      border-radius: 0.5rem;
      height: 100%;
      width: 2.5rem
        /* 40px */
      ;
      color: #fff;
      line-height: 1rem
        /* 16px */
      ;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .blue_tag {
      font-size: 1rem
        /* 12px */
      ;
      background-color: #2563eb;
    }

    .red_tag {
      font-size: 0.75rem
        /* 12px */
      ;
      background-color: #dc2626;
    }

    .green_tag {
      font-size: 1rem
        /* 12px */
      ;
      background-color: #22c55e;
    }

    .audio_tag {
      font-size: 1rem
        /* 12px */
      ;
      background-color: #0284c7;
    }

    .dndhelp_file_details {
      width: calc(100% - 3rem) !important;
      height: 100% !important;
      margin-left: .25rem !important;
      font-size: .65rem
        /* 12px */
         !important;
      display: flex;
      flex-direction: column;
      gap: 2px;
      line-height: normal;
    }

    .dndhelp_file_details span {
      width: calc(100% - 3rem) !important;
      height: 100% !important;
      margin-left: .5rem !important;
      font-size: .75rem
        /* 12px */
         !important;
    }

    table,
    td {
      color: #1e293b;
    }

    #u_body a {
      color: #0000ee;
      text-decoration: underline;
    }

    @media (max-width: 480px) {
      #u_content_text_1 .v-container-padding-padding {
        padding: 30px 30px 30px 20px !important;
      }

      #u_content_button_1 .v-container-padding-padding {
        padding: 10px 20px !important;
      }

      #u_content_button_1 .v-size-width {
        width: 100% !important;
      }

      #u_content_button_1 .v-text-align {
        text-align: left !important;
      }

      #u_content_button_1 .v-padding {
        padding: 15px 40px !important;
      }

      #u_content_text_3 .v-container-padding-padding {
        padding: 30px 30px 80px 20px !important;
      }
    }
  </style>



  <!--[if !mso]><!-->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap" rel="stylesheet" type="text/css">
  <!--<![endif]-->

</head>

<body class="clean-body u_body"
  style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #ecf0f1;color: #1e293b">
  <!--[if IE]><div class="ie-container"><![endif]-->
  <!--[if mso]><div class="mso-container"><![endif]-->
  <table id="u_body"
    style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #ecf0f1;width:100%"
    cellpadding="0" cellspacing="0">
    <tbody>
      <tr style="vertical-align: top">
        <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
          <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #ecf0f1;"><![endif]-->


          <div class="u-row-container" style="padding: 0px;background-color: transparent">
            <div class="u-row"
              style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
              <div
                style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->

                <!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                <div class="u-col u-col-100"
                  style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
                  <div
                    style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                      <!--<![endif]-->

                      <table style="font-family:'Open Sans',sans-serif;" role="presentation" cellpadding="0"
                        cellspacing="0" width="100%" border="0">
                        <tbody>
                          <tr>
                            <td class="v-container-padding-padding"
                              style="overflow-wrap:break-word;word-break:break-word;padding:40px 10px 10px;font-family:'Open Sans',sans-serif;"
                              align="left">

                              <h1 class="v-text-align"
                                style="margin: 0px; line-height: 140%; text-align: center; word-wrap: break-word; font-weight: normal; font-family: 'Open Sans',sans-serif; font-size: 18px;">
                                <div>
                                  <div style="text-transform: capitalize;"><strong>${sendingAccountName}</strong></div>
                                </div>
                              </h1>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td><![endif]-->
                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>



          <div class="u-row-container" style="padding: 0px;background-color: transparent">
            <div class="u-row"
              style="margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;border-radius:5px;">
              <div
                style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: #ffffff;"><![endif]-->

                <!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                <div class="u-col u-col-100"
                  style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
                  <div
                    style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                      <!--<![endif]-->

                      <table id="u_content_text_1" style="font-family:'Open Sans',sans-serif;" role="presentation"
                        cellpadding="0" cellspacing="0" width="100%" border="0">
                        <tbody>
                          <tr>
                            <td class="v-container-padding-padding"
                              style="overflow-wrap:break-word;word-break:break-word;padding:25px 40px 10px 50px;font-family:'Open Sans',sans-serif;"
                              align="left">

                              <div class="v-text-align"
                                style="color: #1e293b; line-height: 140%; text-align: left; word-wrap: break-word;">
                                <p style="line-height: 140%; font-size: 14px; text-align: left;"><span
                                    style="font-size: 12px; line-height: 16.8px; font-family: 'Open Sans', sans-serif; color: #1e293b;"><span
                                      style="line-height: 16.8px; font-size: 12px;"><strong> ${sendingAccountName
                                        ?.toString()
                                        ?.toUpperCase()}
                                        has opened a new ticket regarding ${
                                          inputValue?.category
                                        }. The details are as follows:</strong></span></span></p>
                                <p style="font-size: 14px; line-height: 140%;"> </p>
                                <p style="font-size: 14px; line-height: 140%;"><span
                                    style="color: #1e293b; font-size: 14px; line-height: 19.6px;"><strong>Ticket
                                      Details:</strong></span></p>
                                <p style="font-size: 14px; line-height: 140%;"><span
                                    style="color: #1e293b; font-size: 12px; line-height: 16.8px;text-transform: capitalize;">Brand:
                                    ${inputValue?.company}</span><br /><span
                                    style="color: #1e293b; font-size: 12px; line-height: 16.8px;">Ticket-ID:
                                    ${inputValue?.ticket_id}</span><br />
                                    <span
                                    style="color: #1e293b; font-size: 12px; line-height: 16.8px;text-transform: capitalize;">Due By:
                                    ${new Date(
                                      inputValue["due_date"]
                                    ).toLocaleString()}</span>
                                    <br />
                                    <span
                                    style="color: #1e293b; font-size: 12px; line-height: 16.8px;text-transform: capitalize;">Priority:
                                    ${inputValue?.priority}</span>
                                    <br />
                                    <span
                                    style="color: #1e293b; font-size: 12px; line-height: 16.8px;text-transform: capitalize;">Status:
                                    ${inputValue?.status}</span><br />
                                    <span
                                    style="color: #1e293b; font-size: 12px; line-height: 16.8px;">Requester:
                                    ${inputValue["requester name"]}</span></p>
                                    <!---=============================- Customers Details =============================-->
                                    <p style="font-size: 14px; line-height: 140%;"> </p>
                                    <p style="font-size: 14px; line-height: 140%;"><span
                                        style="color: #1e293b; font-size: 14px; line-height: 19.6px;"><strong>Customer's Details :</strong></span></p>
                                    <p style="font-size: 14px; line-height: 140%;"><span
                                        style="color: #1e293b; font-size: 12px; line-height: 16.8px;text-transform: capitalize;">Fullname:
                                        ${
                                          inputValue?.["Customer's Name"] ??
                                          "None"
                                        }</span><br /><span
                                        style="color: #1e293b; font-size: 12px; line-height: 16.8px;text-transform: capitalize;">Email:
                                        ${
                                          inputValue?.["Customer's Name"]
                                            ?.length <= 3
                                            ? "None"
                                            : inputValue?.["Customer's Name"] ??
                                              "None"
                                        }</span><br />
                                      <span style="color: #1e293b; font-size: 12px; line-height: 16.8px;text-transform: capitalize;">Phone:
                                        ${
                                          inputValue?.["Customer's Phone"] ??
                                          "None"
                                        }</span>
                                      <br />
                                    </p>
                                <p style="font-size: 14px; line-height: 140%;"><br /><span
                                    style="color: #1e293b; font-size: 14px; line-height: 19.6px;"><strong>Description:</strong></span>
                                </p>
                                ${inputValue?.description}
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table style="font-family:'Open Sans',sans-serif;" role="presentation" cellpadding="0"
                        cellspacing="0" width="100%" border="0">
                        <tbody>
                          <tr>
                            <td class="v-container-padding-padding"
                              style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:'Open Sans',sans-serif;"
                              align="left">

                              <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                                style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #BBBBBB;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                <tbody>
                                  <tr style="vertical-align: top">
                                    <td
                                      style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                      <span>&#160;</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table style="font-family:'Open Sans',sans-serif;" role="presentation" cellpadding="0"
                        cellspacing="0" width="100%" border="0">
                        <tbody>
                          <tr>
                            <td class="v-container-padding-padding"
                              style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:'Open Sans',sans-serif;"
                              align="left">

                              <div class="v-text-align"
                                style="line-height: 140%; text-align: left; word-wrap: break-word;">
                                <p style="font-size: 14px; line-height: 140%; text-align: center;"><em><span
                                      style="font-size: 12px; line-height: 16.8px;">In order to update or respond to
                                      this issue please click the button below</span></em></p>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table id="u_content_button_1" style="font-family:'Open Sans',sans-serif;" role="presentation"
                        cellpadding="0" cellspacing="0" width="100%" border="0">
                        <tbody>
                          <tr>
                            <td class="v-container-padding-padding"
                              style="overflow-wrap:break-word;word-break:break-word;padding:10px 40px;font-family:'Open Sans',sans-serif;"
                              align="left">

                              <div class="v-text-align" align="center">
                                <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-spacing: 0; border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;font-family:'Open Sans',sans-serif;"><tr><td class="v-text-align" style="font-family:'Open Sans',sans-serif;" align="center"><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://unlayer.com" style="height:33px; v-text-anchor:middle; width:128px;" arcsize="9%" stroke="f" fillcolor="#2563eb"><w:anchorlock/><center style="color:#ffffff;font-family:'Open Sans',sans-serif;"><![endif]-->
                                <a href="${`https://app.basedesk.co.za/logIn`}" target="_blank" class="v-size-width"
                                  style="box-sizing: border-box;display: inline-block;font-family:'Open Sans',sans-serif;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #ffffff; background-color: #2563eb; border-radius: 3px;-webkit-border-radius: 3px; -moz-border-radius: 3px; width:31%; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;border-top-width: 0px; border-top-style: solid; border-left-width: 0px; border-left-style: solid; border-right-width: 0px; border-right-style: solid; border-bottom-width: 0px; border-bottom-style: solid;">
                                  <span class="v-padding" style="display:block;padding:8px 10px;line-height:120%;">
                                    <p style="font-size: 14px; line-height: 120%;"><span
                                        style="font-size: 14px; line-height: 16.8px;"><span
                                          style="line-height: 16.8px; font-size: 14px;"><span
                                            style="line-height: 16.8px; font-size: 14px;">Update</span></span></span>
                                    </p>
                                  </span>
                                </a>
                                <!--[if mso]></center></v:roundrect></td></tr></table><![endif]-->
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table id="u_content_text_3" style="font-family:'Open Sans',sans-serif;" role="presentation"
                        cellpadding="0" cellspacing="0" width="100%" border="0">
                        <tbody>
                          <tr>
                            <td class="v-container-padding-padding"
                              style="overflow-wrap:break-word;word-break:break-word;padding:10px 30px 25px 40px;font-family:'Open Sans',sans-serif;"
                              align="left">

                              <div class="v-text-align"
                                style="color: #333333; line-height: 140%; text-align: left; word-wrap: break-word;">
                                <p style="font-size: 14px; line-height: 140%;"><span
                                    style="font-size: 12px; line-height: 16.8px;">Disclaimer:</span></p>
                                <p style="font-size: 14px; line-height: 140%;"><span
                                    style="font-size: 12px; line-height: 16.8px;">The information contained in this
                                    communication from the sender is confidential. It is intended solely for use by the
                                    recipient and others authorized to receive it. If you are not the recipient, you are
                                    hereby notified that any disclosure, copying, distribution or taking action in
                                    relation of the contents of this information is strictly prohibited and may be
                                    unlawful.</span></p>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td><![endif]-->
                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>


          <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
        </td>
      </tr>
    </tbody>
  </table>
  <!--[if mso]></div><![endif]-->
  <!--[if IE]></div><![endif]-->
</body>

</html>`
    : `<!DOCTYPE HTML
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <!--[if gte mso 9]>
<xml>
  <o:OfficeDocumentSettings>
    <o:AllowPNG/>
    <o:PixelsPerInch>96</o:PixelsPerInch>
  </o:OfficeDocumentSettings>
</xml>
<![endif]-->
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="x-apple-disable-message-reformatting">
  <!--[if !mso]><!-->
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <!--<![endif]-->
  <title></title>

  <style type="text/css">
    @media only screen and (min-width: 520px) {
      .u-row {
        width: 500px !important;
      }

      .u-row .u-col {
        vertical-align: top;
      }

      .u-row .u-col-100 {
        width: 500px !important;
      }

    }

    img {
      width: 10rem !important;
    }

    p {
      font-size: 12px;
      line-height: 140% !important;
    }

    @media (max-width: 520px) {
      .u-row-container {
        max-width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }

      .u-row .u-col {
        min-width: 320px !important;
        max-width: 100% !important;
        display: block !important;
      }

      .u-row {
        width: calc(100% - 40px) !important;
      }

      .u-col {
        width: 100% !important;
      }

      .u-col>div {
        margin: 0 auto;
      }
    }

    body {
      margin: 0;
      padding: 0;
    }

    table,
    tr,
    td {
      vertical-align: top;
      border-collapse: collapse;
    }

    p {
      margin: 0;
    }

    .ie-container table,
    .mso-container table {
      table-layout: fixed;
    }

    * {
      line-height: inherit;
    }

    a[x-apple-data-detectors='true'] {
      color: inherit !important;
      text-decoration: none !important;
    }

    .dndhelp_file {
      color: #000 !important;
      text-decoration: none !important;
      font-size: 18px;
      display: inline-flex;
      width: 12rem;
      height: 2.5rem;
      align-items: center;
      border-radius: 0.75rem;
      border: solid 1px #cbd5e1;
      margin-top: 1rem;
      margin-bottom: 1rem;
      overflow: hidden;
      user-select: none;
      padding: 0.5rem
    }

    .dndhelp_file_tag {
      border-radius: 0.5rem;
      border-radius: 0.5rem;
      height: 100%;
      width: 2.5rem
        /* 40px */
      ;
      color: #fff;
      line-height: 1rem
        /* 16px */
      ;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .blue_tag {
      font-size: 1rem
        /* 12px */
      ;
      background-color: #2563eb;
    }

    .red_tag {
      font-size: 0.75rem
        /* 12px */
      ;
      background-color: #dc2626;
    }

    .green_tag {
      font-size: 1rem
        /* 12px */
      ;
      background-color: #22c55e;
    }

    .audio_tag {
      font-size: 1rem
        /* 12px */
      ;
      background-color: #0284c7;
    }

    .dndhelp_file_details {
      width: calc(100% - 3rem) !important;
      height: 100% !important;
      margin-left: .25rem !important;
      font-size: .65rem
        /* 12px */
         !important;
      display: flex;
      flex-direction: column;
      gap: 2px;
      line-height: normal;
    }

    .dndhelp_file_details span {
      width: calc(100% - 3rem) !important;
      height: 100% !important;
      margin-left: .5rem !important;
      font-size: .75rem
        /* 12px */
         !important;
    }

    table,
    td {
      color: #1e293b;
    }

    #u_body a {
      color: #0000ee;
      text-decoration: underline;
    }

    @media (max-width: 480px) {
      #u_content_text_1 .v-container-padding-padding {
        padding: 30px 30px 30px 20px !important;
      }

      #u_content_button_1 .v-container-padding-padding {
        padding: 10px 20px !important;
      }

      #u_content_button_1 .v-size-width {
        width: 100% !important;
      }

      #u_content_button_1 .v-text-align {
        text-align: left !important;
      }

      #u_content_button_1 .v-padding {
        padding: 15px 40px !important;
      }

      #u_content_text_3 .v-container-padding-padding {
        padding: 30px 30px 80px 20px !important;
      }
    }
  </style>



  <!--[if !mso]><!-->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap" rel="stylesheet" type="text/css">
  <!--<![endif]-->

</head>

<body class="clean-body u_body"
  style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #ecf0f1;color: #1e293b">
  <!--[if IE]><div class="ie-container"><![endif]-->
  <!--[if mso]><div class="mso-container"><![endif]-->
  <table id="u_body"
    style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #ecf0f1;width:100%"
    cellpadding="0" cellspacing="0">
    <tbody>
      <tr style="vertical-align: top">
        <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
          <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #ecf0f1;"><![endif]-->


          <div class="u-row-container" style="padding: 0px;background-color: transparent">
            <div class="u-row"
              style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
              <div
                style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->

                <!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                <div class="u-col u-col-100"
                  style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
                  <div
                    style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                      <!--<![endif]-->

                      <table style="font-family:'Open Sans',sans-serif;" role="presentation" cellpadding="0"
                        cellspacing="0" width="100%" border="0">
                        <tbody>
                          <tr>
                            <td class="v-container-padding-padding"
                              style="overflow-wrap:break-word;word-break:break-word;padding:40px 10px 10px;font-family:'Open Sans',sans-serif;"
                              align="left">

                              <h1 class="v-text-align"
                                style="margin: 0px; line-height: 140%; text-align: center; word-wrap: break-word; font-weight: normal; font-family: 'Open Sans',sans-serif; font-size: 22px;">
                                <div>
                                  <div style="text-transform: capitalize;"><strong>${sendingAccountName}</strong></div>
                                </div>
                              </h1>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td><![endif]-->
                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>



          <div class="u-row-container" style="padding: 0px;background-color: transparent">
            <div class="u-row"
              style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;">
              <div
                style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: #ffffff;"><![endif]-->

                <!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                <div class="u-col u-col-100"
                  style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
                  <div
                    style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                      <!--<![endif]-->

                      <table id="u_content_text_1" style="font-family:'Open Sans',sans-serif;" role="presentation"
                        cellpadding="0" cellspacing="0" width="100%" border="0">
                        <tbody>
                          <tr>
                            <td class="v-container-padding-padding"
                              style="overflow-wrap:break-word;word-break:break-word;padding:25px 40px 10px 50px;font-family:'Open Sans',sans-serif;"
                              align="left">

                              <div class="v-text-align"
                                style="color: #1e293b; line-height: 140%; text-align: left; word-wrap: break-word;">
                                <p style="line-height: 140%; font-size: 14px; text-align: left;"><span
                                    style="font-size: 12px; line-height: 16.8px; font-family: 'Open Sans', sans-serif; color: #1e293b;"><span
                                      style="line-height: 16.8px; font-size: 12px;"><strong> ${sendingAccountName
                                        ?.toString()
                                        ?.toUpperCase()} has opened a new ticket with ID: ${
                                        inputValue?.ticket_id
                                      } which has been Resolved. If you feel unsatisfied by the solution please don't
                                      hesitate to cantact us thruogh the links provided below, don't foget to grab your ticket-id.</strong></span></span></p>
                                <p style="font-size: 14px; line-height: 140%;"> </p>
                                <p style="font-size: 14px; line-height: 140%;"><span
                                    style="color: #1e293b; font-size: 14px; line-height: 19.6px;"><strong>Ticket
                                      Details:</strong></span></p>
                                <p style="font-size: 14px; line-height: 140%;"><span
                                    style="color: #1e293b; font-size: 12px; line-height: 16.8px;text-transform: capitalize;">Brand:
                                    ${inputValue?.company?.name}</span><br /><span
                                    style="color: #1e293b; font-size: 12px; line-height: 16.8px;">Ticket-ID:
                                    ${inputValue?.ticket_id}</span><br />
                                    <span
                                    style="color: #1e293b; font-size: 12px; line-height: 16.8px;">Closed on:
                                    ${new Date(
                                      inputValue["date"]
                                    ).toLocaleString()}</span>
									<br />
                                    <span
                                    style="color: #1e293b; font-size: 12px; line-height: 16.8px;">Resolved By:
                                    ${inputValue["resolved_by"]}</span>
                                    <br />
                                    <span
                                    style="color: #1e293b; font-size: 12px; line-height: 16.8px;">Status:
                                    Resolved</span></p>
                                    <!---=============================- Customers Details =============================-->
                                    <p style="font-size: 14px; line-height: 140%;"> </p>
                                    <p style="font-size: 14px; line-height: 140%;"><span
                                        style="color: #1e293b; font-size: 14px; line-height: 19.6px;"><strong>Customer's Details :</strong></span></p>
                                    <p style="font-size: 14px; line-height: 140%;"><span
                                        style="color: #1e293b; font-size: 12px; line-height: 16.8px;">Fullname:
                                        ${
                                          inputValue?.["Customer's Name"] ??
                                          "None"
                                        }</span><br /><span
                                        style="color: #1e293b; font-size: 12px; line-height: 16.8px;">Email:
                                        ${
                                          inputValue?.["Customer's Email"]
                                            ?.length <= 3
                                            ? "None"
                                            : inputValue?.["Customer's Email"]
                                        }</span><br />
                                      <span style="color: #1e293b; font-size: 12px; line-height: 16.8px;">Phone:
                                        ${
                                          inputValue?.["Customer's Phone"] ??
                                          "None"
                                        }</span>
                                      <br />
                                    </p>
                                <p style="font-size: 14px; line-height: 140%;"><br /><span
                                    style="color: #1e293b; font-size: 14px; line-height: 19.6px;"><strong>Message:</strong></span>
                                </p>
                                ${inputValue?.description}
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table style="font-family:'Open Sans',sans-serif;" role="presentation" cellpadding="0"
                        cellspacing="0" width="100%" border="0">
                        <tbody>
                          <tr>
                            <td class="v-container-padding-padding"
                              style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:'Open Sans',sans-serif;"
                              align="left">

                              <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                                style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #BBBBBB;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                <tbody>
                                  <tr style="vertical-align: top">
                                    <td
                                      style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                      <span>&#160;</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table style="font-family:'Open Sans',sans-serif;" role="presentation" cellpadding="0"
                        cellspacing="0" width="100%" border="0">
                        <tbody>
                          <tr>
                            <td class="v-container-padding-padding"
                              style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:'Open Sans',sans-serif;"
                              align="left">

                              <div class="v-text-align"
                                style="line-height: 140%; text-align: left; word-wrap: break-word;">
                                <p style="font-size: 14px; line-height: 140%; text-align: center;"><em><span
                                      style="font-size: 12px; line-height: 16.8px;">In order to update or respond to
                                      this issue please click the button below</span></em></p>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table id="u_content_button_1" style="font-family:'Open Sans',sans-serif;" role="presentation"
                        cellpadding="0" cellspacing="0" width="100%" border="0">
                        <tbody>
                          <tr>
                            <td class="v-container-padding-padding"
                              style="overflow-wrap:break-word;word-break:break-word;padding:10px 40px;font-family:'Open Sans',sans-serif;"
                              align="left">

                              <div class="v-text-align" align="center">
                                <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-spacing: 0; border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;font-family:'Open Sans',sans-serif;"><tr><td class="v-text-align" style="font-family:'Open Sans',sans-serif;" align="center"><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://unlayer.com" style="height:33px; v-text-anchor:middle; width:128px;" arcsize="9%" stroke="f" fillcolor="#2563eb"><w:anchorlock/><center style="color:#ffffff;font-family:'Open Sans',sans-serif;"><![endif]-->
                                <a href="${`https://app.basedesk.co.za/logIn`}" target="_blank" class="v-size-width"
                                  style="box-sizing: border-box;display: inline-block;font-family:'Open Sans',sans-serif;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #ffffff; background-color: #2563eb; border-radius: 3px;-webkit-border-radius: 3px; -moz-border-radius: 3px; width:31%; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;border-top-width: 0px; border-top-style: solid; border-left-width: 0px; border-left-style: solid; border-right-width: 0px; border-right-style: solid; border-bottom-width: 0px; border-bottom-style: solid;">
                                  <span class="v-padding" style="display:block;padding:8px 10px;line-height:120%;">
                                    <p style="font-size: 14px; line-height: 120%;"><span
                                        style="font-size: 14px; line-height: 16.8px;"><span
                                          style="line-height: 16.8px; font-size: 14px;"><span
                                            style="line-height: 16.8px; font-size: 14px;">Update</span></span></span>
                                    </p>
                                  </span>
                                </a>
                                <!--[if mso]></center></v:roundrect></td></tr></table><![endif]-->
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table id="u_content_text_3" style="font-family:'Open Sans',sans-serif;" role="presentation"
                        cellpadding="0" cellspacing="0" width="100%" border="0">
                        <tbody>
                          <tr>
                            <td class="v-container-padding-padding"
                              style="overflow-wrap:break-word;word-break:break-word;padding:10px 30px 25px 40px;font-family:'Open Sans',sans-serif;"
                              align="left">

                              <div class="v-text-align"
                                style="color: #333333; line-height: 140%; text-align: left; word-wrap: break-word;">
                                <p style="font-size: 14px; line-height: 140%;"><span
                                    style="font-size: 12px; line-height: 16.8px;">Disclaimer:</span></p>
                                <p style="font-size: 14px; line-height: 140%;"><span
                                    style="font-size: 12px; line-height: 16.8px;">The information contained in this
                                    communication from the sender is confidential. It is intended solely for use by the
                                    recipient and others authorized to receive it. If you are not the recipient, you are
                                    hereby notified that any disclosure, copying, distribution or taking action in
                                    relation of the contents of this information is strictly prohibited and may be
                                    unlawful.</span></p>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td><![endif]-->
                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>


          <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
        </td>
      </tr>
    </tbody>
  </table>
  <!--[if mso]></div><![endif]-->
  <!--[if IE]></div><![endif]-->
</body>

</html>`;
};
