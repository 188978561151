import { FC, useState } from "react";
import { extractImages } from "../../../Reusable Functions/Reusable_Func";
import { HiBadgeCheck } from "react-icons/hi";
import { deleteThreadMessage } from "../../../APIs & Data/TicketsnUserData";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { useClickOutside } from "@mantine/hooks";
import { BiDotsVertical } from "react-icons/bi";
import { MdOutlineReply, MdPushPin } from "react-icons/md";
import { TbAlarmFilled, TbChecks, TbTrash } from "react-icons/tb";

type Props = {
  user_name: string;
  date: number;
  message: string;
  status: string;
  openTicket?: any;
  id?: string;
  complete_message?: any;
};

const MessageComponent: FC<Props> = ({
  user_name,
  date,
  message,
  status,
  openTicket,
  id,
  complete_message,
}) => {
  const user = useSelector(
    (state: RootState) => state.UserInfo.member_details
  )[0];
  const [optionsChecked, setOptions] = useState<string | null>(null);
  const message_id = id?.toString() + date?.toString() + "message";
  const threadRef = useClickOutside(() => {
    setOptions(null);
  });

  //Component ================
  return (
    <div
      className="w-full text-black-400 text-sm flex justify-between gap-4 pb-6 
        transition-all tracking-wide bg-tranparent"
    >
      {/**Message ====================== */}
      <div className="w-full bg-tranparent relative">
        {/**Contents ======================= */}
        <div
          className="font-semibold dark:font-medium dark:text-black-400 text-black-500 justify-between 
          md:items-center w-full flex flex-col md:flex-row relative pb-1 border-dashed border-b
           border-black-300 dark:border-black-600 mb-3"
        >
          <div className="flex items-center gap-3 dark:text-black-300 text-black-800 text-xs h-10 shrink-0">
            {/**Profile ======================== */}
            {status === "as_resolution" ? (
              <div
                className="shrink-0 w-6 h-6 text-green-600 fill-green-600 rounded-full flex justify-center 
                  items-center uppercase font-bold text-xl first-letter:object-cover object-center object-fit
                 dark:bg-black-700 bg-black-200 ring-1 ring-offset-2 ring-offset-white
                 dark:ring-offset-black-900 ring-black-300 dark:ring-black-500"
              >
                <HiBadgeCheck />
              </div>
            ) : (
              <div
                className={`h-6 w-6 pt-0.5 uppercase rounded-full ring-2 ring-zinc-300 dark:ring-zinc-600
                 bg-black-600 text-white text-xs font-semibold flex items-center justify-center`}
              >
                {user_name?.charAt(0)}
              </div>
            )}
            <span className="tracking-normal text-xs font-medium capitalize font-sans">
              {user_name}
            </span>
          </div>

          {/**Message Options =========================== */}
          <div className="flex gap-0 md:gap-2 h-full items-center justify-between">
            <span className="flex gap-2">
              <span className="text-[0.65rem] dark:text-black-400 text-black-600 font-medium font-sans">
                {`${new Date(date).toDateString()}`},{" "}
                {`${new Date(date).toLocaleString()?.split(",")[1]}`}
              </span>
            </span>
            {/**Message Options =========================== */}
            {!(status === "as_resolution" || status === "description") && (
              <label
                ref={threadRef}
                htmlFor={message_id}
                className="relative h-fit w-fit dark:text-black-400 text-black-700 flex
              items-center justify-center cursor-pointer rounded"
              >
                <input
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOptions(id as string);
                    } else {
                      setOptions(null);
                    }
                  }}
                  checked={optionsChecked === id}
                  type="checkbox"
                  name={message_id}
                  id={message_id}
                  className="absolute scale-0 peer"
                />
                <BiDotsVertical className="text-lg bg-zinc-50 rounded-full" />
                <div
                  className="h-fit w-[11rem] absolute right-[100%] top-2 bg-black-700/50 dark:bg-black-400/50 
               rounded-lg shadow-xl drop-shadow-xl backdrop-blur-md p-4 py-2 flex-col divide-y divide-black-100/50
              dark:divide-black-300/60 hidden peer-checked:flex transition-all z-[9999]"
                >
                  <button
                    className="bg-transparent w-full h-10 flex items-center gap-2 text-xs text-white 
                 capitalize font-semibold hover:opacity-70 transition-all"
                  >
                    <MdOutlineReply className="text-xl" />
                    <span>reply</span>
                  </button>
                  <button
                    className="bg-transparent w-full h-10 flex items-center gap-2 text-xs text-white 
                capitalize font-semibold hover:opacity-70 transition-all"
                  >
                    <TbChecks className="text-lg" />
                    <span>mark as unread</span>
                  </button>
                  <button
                    className="bg-transparent w-full h-10 flex items-center gap-2 text-xs text-white 
                capitalize font-semibold hover:opacity-70 transition-all"
                  >
                    <MdPushPin className="text-xl" />
                    <span>pin to chat</span>
                  </button>
                  <button
                    className="bg-transparent w-full h-10 flex items-center gap-2 text-xs text-white 
                capitalize font-semibold hover:opacity-70 transition-all"
                  >
                    <TbAlarmFilled className="text-xl" />
                    <span>Set as reminder</span>
                  </button>
                  <button
                    onClick={() => {
                      deleteThreadMessage(complete_message, openTicket?.id);
                    }}
                    className="bg-transparent w-full h-10 flex items-center gap-2 text-xs text-red-500 
                     capitalize font-semibold hover:opacity-70 transition-all group"
                  >
                    <TbTrash className="text-lg group-focus:hidden flex" />
                    <div
                      className="h-5 w-5 border-4 rounded-full animate-spin border-l-red-400
                  border-red-100 group-focus:flex hidden"
                    ></div>
                    <span className="group-focus:hidden flex">
                      Delete message
                    </span>
                    <span className="group-focus:flex hidden">
                      Please wait ...
                    </span>
                  </button>
                </div>
              </label>
            )}
            {/**Message Options =========================== */}
          </div>
        </div>
        <div className="dark:text-black-400 text-black-700 text-[13px]">
          {message && (
            <div
              dangerouslySetInnerHTML={{
                __html: extractImages(message),
              }}
              className="messageContainer dark:marker:text-black-400 marker:text-black-800 list-disc 
            space-y-0 leading-[1.1rem] overflow-hidden pb-2 px-2"
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageComponent;
