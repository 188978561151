const Legal = () => {
  return (
    <div className="fixed inset-0 overflow-hidden overflow-y-scroll p-4">
      <embed
        src="https://firebasestorage.googleapis.com/v0/b/dial-n-dine-help-desk.appspot.com/o/basedesk%2FBASEDESK%20TERMS%20AND%20CONDITIONS.pdf?alt=media&token=4db6f8ed-bc8e-4210-9b44-5df860d76e83"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default Legal;
