import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { paginate } from "../../../Redux/Slices/ChatSlice";

const Pagination = () => {
  const dispatch = useDispatch();
  const chatData = useSelector((state: RootState) => state.Tickets.chatData);
  const pagination = useSelector(
    (state: RootState) => state.ChatData.pagination
  );

  //Component ========================
  return (
    <div
      className="w-full h-14 bg-black-50 dark:bg-black-800 border-t border-black-200
       dark:border-black-700 flex items-center justify-between px-4 overflow-hidden shadow-lg drop-shadow-lg"
    >
      <span className="text-xxs uppercase font-bold text-black-600 dark:text-black-200">
        Showing {pagination?.start + 1} - {pagination?.end}{" "}
        <span className="text-zinc-400">of</span>{" "}
        {chatData?.length}
      </span>
      <div
        className="h-8 w-24 rounded border border-black-200 dark:border-black-700 
        grid grid-cols-2 overflow-hidden bg-white dark:bg-black-900 shadow-sm"
      >
        <button
          onClick={() => {
            dispatch(
              paginate({
                start: pagination?.start - 40,
                end: pagination?.end - 40 <= 49 ? 40 : pagination?.end - 40,
              })
            );
          }}
          disabled={pagination?.start === 0}
          className="h-full col-span-1 flex items-center justify-center text-xl
         text-black-500 dark:text-black-300 hover:!text-blue-600 transition-all
         disabled:cursor-not-allowed"
        >
          <BiLeftArrowAlt />
        </button>
        <button
          onClick={() => {
            dispatch(
              paginate({
                start: pagination?.start + 40,
                end: pagination?.end + 40,
              })
            );
          }}
          disabled={pagination?.end >= chatData?.length}
          className="h-full col-span-1 flex items-center justify-center text-xl
         text-black-500 dark:text-black-300 hover:!text-blue-600 transition-all
         disabled:cursor-not-allowed"
        >
          <BiRightArrowAlt />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
