import { Navigate, Outlet } from "react-router-dom";
import Loader from "./Components/Misc/Loader";
import { useAuth } from "./Custom-Hooks/useAuth";
import { useSelector } from "react-redux";
import { RootState } from "./Redux/store";
import UserData from "./APIs & Data/user_data";
import { usePostHog } from "posthog-js/react";
import React from "react";
import { org } from "./APIs & Data/TicketsnUserData";

const PrivateRoutes = () => {
  const posthog = usePostHog()
  const { loading, currentUser } = useAuth();
  const user = useSelector(
    (state: RootState) => state.UserInfo.member_details
  )[0];

  React.useEffect(() => {
    if (user) {
      posthog?.identify(user?.uid||user?.id, {
        email: user?.email,
      });
      posthog?.group("company", org||"unknown",{
        name: org||"unknown",
      });
    }
  }, [posthog, user]);


  //Component ==============
  return (
    <>
      {loading || (!loading && currentUser && !user?.id) ? (
        <Loader />
      ) : currentUser && user?.id ? (
        <Outlet />
      ) : (
        <Navigate to="/login" />
      )}
      <UserData />
    </>
  );
};
export default PrivateRoutes;
