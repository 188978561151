import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { RiUserReceivedLine } from "react-icons/ri";
import noResults from "../../../Assets/images/search.png";
import no_profile from "../../../Assets/images/no_profile.png";
import { useClickOutside } from "@mantine/hooks";

type Props = {
  assignAgents: any;
};

const AgentsList = ({ assignAgents }: Props) => {
  const user = useSelector(
    (state: RootState) => state.UserInfo.member_details
  )[0];
  const [searchAssignee, setSearch] = useState("");
  const [usersPanel, setPanel] = useState<boolean | any>(false);
  const markedArray = useSelector(
    (state: RootState) => state.ChatData.markedArray
  );
  const allMembers = useSelector(
    (state: RootState) => state.UserInfo.allMembers
  );
  const filteredUsers = useMemo(() => {
    return allMembers.length >= 1
      ? allMembers
          ?.filter(
            (user: any) =>
              Object.values(user)?.some(
                (value: string | any) =>
                  value
                    ?.toString()
                    ?.toLowerCase()
                    ?.replace(/\s/gim, "")
                    ?.includes(
                      searchAssignee?.toLowerCase()?.replace(/\s/gim, "")
                    )
              ) && user.access?.toLowerCase() !== "client"
          )
          ?.slice(0, 10)
      : [];
  }, [searchAssignee, allMembers]);
  const modalRef = useClickOutside(() => setPanel(false));

  //Component ================
  return (
    <>
      <button
        title="Assign Agent"
        disabled={markedArray?.length <= 0 || user?.access !== "admin"}
        onClick={() => setPanel((prev: boolean) => (prev ? false : true))}
        className="h-full w-12 min-w-[3rem] hover:!text-blue-600 transition-all
       disabled:pointer-events-none disabled:opacity-50 disabled:cursor-not-allowed
       text-xl flex items-center justify-center"
      >
        <RiUserReceivedLine />
      </button>

      {/**Modal =================================== */}
      <div
        ref={modalRef}
        className={`h-44 bg-white dark:bg-black-800 border border-black-300 dark:border-black-700
             shadow-2xl drop-shadow-2xl rounded-xl absolute ${
               usersPanel ? "left-2 right-2" : "-left-[200%] right-[200%]"
             } top-[4rem] z-10 p-5 overflow-hidden flex flex-col gap-4 transition-all ease-in-out`}
      >
        <input
          onChange={(e) => {
            setSearch(e.target.value?.toString());
          }}
          value={searchAssignee}
          type="search"
          name="search_assign_agent_tickets"
          id="search_assign_agent_tickets"
          autoComplete="off"
          placeholder="Search for an agent ..."
          className="w-full h-12 bg-inherit rounded-full border border-black-300 dark:border-black-600
           focus:!border-blue-600 transition-all focus:ring-0 text-xs text-black-600 dark:text-black-200
            placeholder:!text-black-500 px-5"
        />
        <ul
          className="w-full h-[calc(100%-4rem)] flex gap-4 overflow-hidden
         overflow-x-scroll no-scrollbar no-scrollbar::-webkit-scrollbar snap-x"
        >
          {filteredUsers.length >= 1 ? (
            filteredUsers?.map((user: any) => {
              return (
                <li
                  key={user?.name}
                  onClick={() => {
                    assignAgents(user?.name, user?.email, user?.id);
                    setPanel(false);
                  }}
                  className="w-20 min-w-[5rem] h-16 gap-2 group flex flex-col items-center 
                    overflow-hidden justify-center snap-center relative cursor-pointer select-none"
                >
                  <img
                    title={user.name}
                    onError={(e) => {
                      e.currentTarget.src = no_profile;
                    }}
                    src={
                      user.photoUrl !== null && user.photoUrl !== ""
                        ? user.photoUrl
                        : no_profile
                    }
                    alt="profile"
                    className={`object-cover w-9 h-9 min-w-[1.75rem] p-1 object-center rounded-full
                      border border-black-300 dark:border-black-700 bg-black-50 dark:bg-black-800`}
                  />
                  <div className="absolute bottom-5.5 right-5 w-3 h-3 rounded-full border-2 border-white dark:border-black-800 bg-green-600"></div>
                  <span
                    title={user.name}
                    className="w-full text-xs capitalize text-center font-medium text-black-600 dark:text-black-200 
                      whitespace-nowrap truncate group-hover:!text-blue-600 transition-all"
                  >
                    {user.name}
                  </span>
                </li>
              );
            })
          ) : (
            <div
              className="h-16 w-full flex items-center justify-center border border-dashed border-black-200
             dark:border-black-600 rounded-lg bg-black-50 dark:bg-black-900/30"
            >
              <img src={noResults} alt="no_resulte" className="h-10 w-auto" />
            </div>
          )}
        </ul>
      </div>
    </>
  );
};

export default AgentsList;
