import { FC, useEffect } from "react";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../Redux/store";
import {
  addAllMembers,
  setToDo,
  updateCannedRes,
  updatePublicCannedRes,
} from "../Redux/Slices/UserSlice";
import {
  setContacts,
  loadAccounts,
  setCategories,
  setCustomFields,
} from "../Redux/Slices/Tickets_n_Contacts_Slice";
import {
  updatePermissions,
  setCompanyDetails,
  loadGroups,
  loadSystemSettings,
} from "../Redux/Slices/SettingsSlice";
import { setMessages } from "../Redux/Slices/NotificationsSlice";

//Firestore ===================
import {
  collection,
  onSnapshot,
  addDoc,
  doc,
  deleteDoc,
  increment,
  runTransaction,
  updateDoc,
  arrayUnion,
  arrayRemove,
  writeBatch,
  getDoc,
} from "firebase/firestore";
import { checkUrls, decrypt } from "../Reusable Functions/Reusable_Func";
import QueueData from "./QueueData";
import TicketsDataFetcher from "./ticketsdata";
import { auth, db } from "./Firebase";

// //Images  onError Handling
// // get all images on the page
// const images = document.querySelectorAll("img");

// // loop through all images and add an error listener
// images.forEach((img) => {
//   img.addEventListener("error", function () {
//     // replace the failed image with a default image
//     this.src = default_image;
//   });
// });

// Initialize Firebase for auth======================
export const org =localStorage.getItem("organization_name");

// collection refs
export const systemRef: any = org && collection(db, `system_data`);
export const logsRef: any = org && collection(db, `system_data/analytics/logs`);
export const membersRef: any =
  org && collection(db, `companies/${org}/members`);
export const ticketsRef: any =
  org && collection(db, `companies/${org}/tickets`);
export const queueRef: any = org && collection(db, `companies/${org}/queue`);
const queueLogsRef: any = org && collection(db, `companies/${org}/queue_logs`);
const contactsRef: any = org && collection(db, `companies/${org}/contacts`);
//const settingsRef: any = org && collection(db, `companies/${org}/settings`);
const publicCannedResRef: any =
  org && collection(db, `companies/${org}/cannedResponses`);
const emailAccountsRef: any =
  org && collection(db, `companies/${org}/email_accounts`);
// const email_TemplatesRef: any =
//   org &&
//   collection(db, `companies/${org}/settings/all_settings/email_templates`);
const categoriesRef: any =
  org && collection(db, `companies/${org}/settings/all_settings/categories`);
const customFieldsRef: any =
  org && collection(db, `companies/${org}/settings/all_settings/custom_fields`);
const companyDetailsRef: any =
  org &&
  collection(db, `companies/${org}/settings/all_settings/company_details`);
const rolesPermissionsRef: any =
  org &&
  collection(db, `companies/${org}/settings/all_settings/roles_permissions`);
const groupsRef: any =
  org && collection(db, `companies/${org}/settings/all_settings/groups`);

//System ========================================
export const addLog = async (obj: any) => {
  await addDoc(logsRef, obj);
};
//Groups ========================================
export const addGroup = async (obj: any) => {
  await addDoc(groupsRef, obj);
};

export const updateGroup = async (obj: any) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/groups`,
    obj?.id
  );
  await updateDoc(docRef, obj);
};

export const deleteGroup = async (id: string) => {
  const docRef = doc(db, `companies/${org}/settings/all_settings/groups`, id);
  await deleteDoc(docRef);
};

//Permissions Collection =====================
export const addPerm = (obj: any) => {
  addDoc(rolesPermissionsRef, obj);
};

export const updatePerm = (obj: any) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/roles_permissions`,
    obj?.id
  );
  updateDoc(docRef, obj);
};

export const deletePerm = (id: string) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/roles_permissions`,
    id
  );
  deleteDoc(docRef);
};

//Custom categories

//===================================USER===========================================
// Update User Details ================
export const updateUserDetails = (id: string, name: string, dept: string) => {
  const docRef = doc(db, `companies/${org}/members`, id);
  updateDoc(docRef, {
    name: name,
    dept: dept,
  });
};

// Update Online Status ================
export const updateUserStatus = (id: string, status: string) => {
  const docRef = doc(db, `companies/${org}/members`, id);
  updateDoc(docRef, {
    status: status,
  });
};

//User Notifications settings =====================================
export const updateNotificationsPref = async (obj: any) => {
  const docRef = doc(db, `companies/${org}/members`, obj?.id);
  await updateDoc(docRef, { notificationsPref: obj?.notificationsPref });
};

// Update Agent profileUrl on FireBase Doc ================
export const updateProfileUrl = (id: string, photoUrl: any) => {
  const docRef = doc(db, `companies/${org}/members`, id);
  updateDoc(docRef, {
    photoUrl: photoUrl,
  });
};

// Update User Uid ================
export const updateUID = (id: string, uid: any) => {
  const docRef = doc(db, `companies/${org}/members`, id);
  updateDoc(docRef, {
    uid: uid,
  });
};

// change user active status ================
export const activateUser = (id: string, state: boolean) => {
  const docRef = doc(db, `companies/${org}/members`, id);
  updateDoc(docRef, {
    active: state,
  });
};

// Edit user ================
export const editUser = (obj: any) => {
  const docRef = doc(db, `companies/${org}/members`, obj?.id);
  updateDoc(docRef, { ...obj, companies: obj?.companies?.toString() });
};

// Delete User ================
export const deleteUser = (id: string) => {
  const docRef = doc(db, `companies/${org}/members`, id);
  deleteDoc(docRef);
};

// New User =================================
export const createUser = (obj: any) => {
  addDoc(membersRef, {
    ...obj,
    companies: obj?.companies?.toString(),
    email: obj?.email?.replace(/\s/g, "")?.toLowerCase(),
    uid: "",
    createdAt: new Date().getTime(),
  });
};

//===================================NOTIFICATIONS===========================================
// Add Notifications =================================
export const addNotification = (id: any, title: string, message: string) => {
  addDoc(collection(db, `companies/${org}/members/${id}/notifications`), {
    title: title,
    message: message,
    date: Number(new Date().getTime()),
  });
};

//Delete notification ============================
export const deleteNotification = (id: string, user_id: any) => {
  const docRef = doc(
    db,
    `companies/${org}/members/${user_id}/notifications`,
    id
  );
  deleteDoc(docRef);
};

//===================================CONTACTS MANAGEMENT===========================================
// New Contact =================================
export const newContact = (newContactValue: any) => {
  addDoc(contactsRef, newContactValue);
};

//Add Multiple Contacts ==========================
export const newContacts = async (newContactValues: any[]) => {
  const batch = writeBatch(db);

  newContactValues.forEach((newContactValue) => {
    const docRef = doc(collection(db, `companies/${org}/contacts`)); // generates a document reference
    batch.set(docRef, newContactValue); // adds the document to the batch
  });

  await batch.commit(); // writes all the documents in the batch
};

//Edit Contact ========================
export const editContact = (contact: any) => {
  const docRef = doc(
    db,
    `companies/${org}/contacts`,
    contact?.selectedContact_id
  );
  updateDoc(docRef, { ...contact });
};

// Function to edit multiple contacts in a batch
export const editContacts = async (contacts: any[]) => {
  const BATCH_LIMIT = 500;

  // Split the contacts array into chunks of BATCH_LIMIT size
  for (let i = 0; i < contacts.length; i += BATCH_LIMIT) {
    const batch = writeBatch(db);
    const chunk = contacts.slice(i, i + BATCH_LIMIT);

    chunk.forEach((contact) => {
      if (!contact.id) {
        console.error("Contact object missing id:", contact);
        return;
      }

      const docRef = doc(db, `companies/${org}/contacts`, contact.id);
      const { ...contactData } = contact;
      batch.update(docRef, contactData);
    });

    try {
      await batch.commit();
      console.log(
        `Batch update successful for contacts ${i + 1} to ${i + chunk.length}`
      );
    } catch (error) {
      console.error(
        `Batch update failed for contacts ${i + 1} to ${i + chunk.length}:`,
        error
      );
    }
  }
};

// Delete Contact ================
export const deleteContact = (id: string) => {
  const docRef = doc(db, `companies/${org}/contacts`, id);
  deleteDoc(docRef);
};

//===================================EMAIL ACCOUNTS MANAGEMENT===========================================
// Update Email Account =================================
export const updateEmailAccount = async (obj: any) => {
  const docRef = doc(db, `companies/${org}/email_accounts`, obj?.id);
  await updateDoc(docRef, obj);
};

//New Account ==========================
export const newEmailAccount = async (obj: any) => {
  await addDoc(emailAccountsRef, obj);
};

// Delete Contact ================
export const deleteEmailAccount = async (id: string) => {
  const docRef = doc(db, `companies/${org}/email_accounts`, id);
  await deleteDoc(docRef);
};

//=================================== Canned Responses===========================================
//Public Canned Response
export const newPublicCannedRes = async (name: string, message: string) => {
  await addDoc(publicCannedResRef, {
    name: name,
    message: message,
    scope: "public",
  });
};

export const deletePublicCannedRes = (id: string) => {
  const docRef = doc(db, `companies/${org}/cannedResponses`, id);
  deleteDoc(docRef);
};

//Private Canned Response
//New Template ==========================
export const newCannedRes = async (
  id: string,
  name: string,
  message: string
) => {
  await addDoc(
    collection(db, `companies/${org}/members/${id}/canned_responses`),
    {
      name: name,
      message: message,
      scope: "private",
    }
  );
};

// Delete Template ================
export const deleteCannedRes = (id: string, userId: string) => {
  const docRef = doc(
    db,
    `companies/${org}/members/${userId}/canned_responses`,
    id
  );
  deleteDoc(docRef);
};

//=================================== Custom Fields ===========================================
//New Field ==========================
export const newField = (newFieldObject: any) => {
  addDoc(customFieldsRef, newFieldObject);
};

// Edit field ================
export const editField = (id: any, editFieldObject: any) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/custom_fields`,
    id
  );
  updateDoc(docRef, editFieldObject);
};

// Delete Field ================
export const deleteField = (id: string) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/custom_fields`,
    id
  );
  deleteDoc(docRef);
};

//=================================== Categories ===========================================
//New Category ==========================
export const newCategory = async (obj: any) => {
  return await addDoc(categoriesRef, obj);
};

// Edit Category ================
export const editCategory = (obj: any) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/categories`,
    obj?.id
  );
  updateDoc(docRef, obj);
};

// Delete Category ================
export const deleteCategory = (id: string) => {
  const docRef = doc(
    db,
    `companies/${org}/settings/all_settings/categories`,
    id
  );
  deleteDoc(docRef);
};

//===================================TICKETS===========================================
// deleting Ticket From Queue on Assign
export const deleteQueue = (queue: any, user: any, assigneer: any) => {
  const docRef = doc(db, `companies/${org}/queue`, queue?.id);
  addDoc(queueLogsRef, {
    ...queue,
    attanded_At: new Date()?.getTime(),
    time_to_Answer: new Date()?.getTime() - queue?.date,
    assignee: user,
    assigneer: assigneer,
  }).then(() => {
    deleteDoc(docRef);
  });
};

// Update Fields  ================
export const updateTicket = async (obj: any) => {
  const docRef = doc(db, `companies/${org}/tickets`, obj?.id);
  await updateDoc(docRef, obj);
};

// Add Feedback  ================
export const feedBack = async (id: string, feedback: string) => {
  const docRef = doc(db, `companies/${org}/tickets`, id);
  await updateDoc(docRef, {
    feedback: feedback,
  });
};

// Add Notes ================
export const addNote = async (id: string, notes: any) => {
  const docRef = doc(db, `companies/${org}/tickets`, id);
  await updateDoc(docRef, {
    notes: arrayUnion(notes),
  });
};
// Delete Notes ================
export const deleteNote = async (id: string, note_id: any) => {
  const docRef = doc(db, `companies/${org}/tickets`, id);
  try {
    await runTransaction(db, async (transaction) => {
      const ticketDoc = await transaction.get(docRef);
      const newArray = ticketDoc
        .data()
        ?.notes?.filter((data: any) => data?.id !== note_id);
      transaction.update(docRef, { notes: newArray });
    });
    console.log("Transaction successfully committed!");
  } catch (e) {
    console.log("Transaction failed: ", e);
  }
};

// Change Reopen Ticket ================
export const reOpenTicket = (id: string) => {
  const docRef = doc(db, `companies/${org}/tickets`, id);
  updateDoc(docRef, {
    status: "reopened",
    reopened: true,
  });
};

// Change Ticket Status ================
export const changeStatus = (id: string, status: any) => {
  const docRef = doc(db, `companies/${org}/tickets`, id);
  updateDoc(docRef, {
    status: status,
  });
};

// deleting Tickets
export const deleteTicket = async (id: string) => {
  const docRef = doc(db, `companies/${org}/tickets`, id);
  await deleteDoc(docRef);
};

export const deleteThreadMessage = async (
  message: any,
  thread_id: any,
) => {
  const docRef = doc(db, `companies/${org}/tickets`, thread_id);
  await updateDoc(docRef, {
    thread: arrayRemove(message),
    thread_size: increment(-1),
  });
};

// Assign Different Agent ================
export const assignAgent = (
  id: string,
  agent: any,
  email: any,
  assignee_id: any,
  assigner: any,
  assigner_email: any
) => {
  const docRef = doc(db, `companies/${org}/tickets`, id);
  updateDoc(docRef, {
    "assignee name": agent,
    "assignee email": email,
    "assignee id": assignee_id,
    assigner: assigner,
    "assigner email": assigner_email,
  });
};

//Close Or Resolve Ticket
export const resolveTicket = (
  id: string,
  openDate: any,
  solution: string,
  status: string,
  user: any
) => {
  const docRef = doc(db, `companies/${org}/tickets`, id);
  updateDoc(docRef, {
    status: status,
    resolution: solution,
    "close date": Number(new Date().getTime()),
    "resolution time": Number(new Date().getTime()) - Number(openDate),
    resolved_by: user?.name,
    logs: arrayUnion({
      event_name: "Ticket has been resolved | closed",
      log_time: new Date().getTime(),
      by_name: user?.name,
      by_email: user?.email,
    }),
  });
};

//Add Collaborator
export const addCollaborator = (
  id: string,
  collaborator: any,
  user: any,
  ticket_id: any,
  member_id: string
) => {
  const docRef = doc(db, `companies/${org}/tickets`, id);
  updateDoc(docRef, {
    collaborators: arrayUnion(collaborator),
    logs: arrayUnion({
      event_name: "New collaborator added : " + collaborator,
      log_time: new Date().getTime(),
      by_name: user?.name,
      by_email: user?.email,
    }),
  });
  addNotification(
    member_id,
    "You've been added as a collaborator",
    `You've been added as a collaborator on ticket with id: ${ticket_id}`
  );
};

//Add Collaborator
export const deleteCollaborator = (
  id: string,
  collaborator: any,
  user: any,
  ticket_id: any,
  member_id: string
) => {
  const docRef = doc(db, `companies/${org}/tickets`, id);
  updateDoc(docRef, {
    collaborators: arrayRemove(collaborator),
    logs: arrayUnion({
      event_name: "A collaborator has been removed: " + collaborator,
      log_time: new Date().getTime(),
      by_name: user?.name,
      by_email: user?.email,
    }),
  });
  addNotification(
    member_id,
    "You've been removed as a collaborator",
    `You've been removed as a collaborator on ticket with id: ${ticket_id}`
  );
};

//Add Reply or Send Reply ============
export const addReply = async (reply_obj: any, openTicket: any) => {
  const docRef = doc(db, `companies/${org}/tickets`, openTicket?.id);
  return await updateDoc(docRef, {
    thread: arrayUnion(reply_obj),
    thread_size: increment(1),
    "1st time response": reply_obj["1st time response"],
    logs: arrayUnion({
      event_name:
        "Ticket has been updated with a status of : " + reply_obj?.status,
      log_time: reply_obj?.date,
      by_name: reply_obj?.sender_name,
      by_email: reply_obj?.sender_email,
    }),
  })
    .then(() => {
      return "updated";
    })
    .catch(() => {
      return "failed";
    });
};

export const markAsRead = async (
  arrayIDs: any,
  openTicket: any,
  user_email: string
) => {
  const docRef = doc(db, `companies/${org}/tickets`, openTicket?.id);
  return await updateDoc(docRef, {
    thread:
      Array.isArray(openTicket?.thread) &&
      openTicket?.thread?.map((msg: any) => {
        if (arrayIDs?.some((data: any) => data?.id === msg?.id)) {
          return {
            ...msg,
            read_by: [...(msg?.read_by ? msg?.read_by : []), user_email],
          };
        } else {
          return msg;
        }
      }),
  })
    .then(() => {
      return "updated";
    })
    .catch(() => {
      return "failed";
    });
};

// New Tickects ==============================
export const addTicket = async (new_ticket__obj: any) => {
  const docRef = await addDoc(ticketsRef, {
    ...new_ticket__obj,
    thread: [],
    read_by: [],
    logs: [
      {
        event_name: "A new ticket has been logged",
        log_time: new_ticket__obj?.date,
        by_name: new_ticket__obj["requester name"],
        by_email: new_ticket__obj["requester email"],
      },
    ],
  });

  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};
//Component ==================================
const TicketsnUserData: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser: any | null = auth?.currentUser;
  const user = useSelector(
    (state: RootState) => state.UserInfo.member_details
  )[0];
  //Company Details Data Fetching ======================
  useEffect((): any => {
    return (
      org &&
      onSnapshot(
        companyDetailsRef,
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          dispatch(
            setCompanyDetails(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))[0]
            )
          );
        }
      )
    );
  }, [dispatch]);

  useEffect((): any => {
    return (
      //Members Data Fetching
      org &&
        onSnapshot(
          membersRef,
          { includeMetadataChanges: true },
          (snapshot: { docs: any[] }) => {
            dispatch(
              addAllMembers(
                snapshot.docs
                  .map((doc: { data: () => any; id: any }) => ({
                    ...doc.data(),
                    id: doc.id,
                  }))
                  .sort(
                    (
                      a: { name: string | number },
                      b: { name: string | number }
                    ) => (a.name < b.name ? -1 : 1)
                  )
              )
            );
          }
        ),
      //Contacts Data Fetching ======================
      org &&
        onSnapshot(
          contactsRef,
          { includeMetadataChanges: true },
          (snapshot: { docs: any[] }) => {
            dispatch(
              setContacts(
                snapshot.docs
                  .map((doc: { data: () => any; id: any }) => ({
                    ...doc.data(),
                    id: doc.id,
                  }))
                  ?.filter((company: any) =>
                    user?.access === "client"
                      ? user?.companies?.includes(company?.branch_company)
                      : true
                  )
                  ?.filter(Boolean)
              )
            );
          }
        ),
      //Permissions Data Data Fetching ======================
      org &&
        onSnapshot(
          rolesPermissionsRef,
          { includeMetadataChanges: true },
          (snapshot: { docs: any[] }) => {
            dispatch(
              updatePermissions(
                snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
              )
            );
          }
        ),
      //Loadavailable Groups ======================
      org &&
        onSnapshot(
          groupsRef,
          { includeMetadataChanges: true },
          (snapshot: { docs: any[] }) => {
            dispatch(
              loadGroups(
                snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
              )
            );
          }
        ),
      //Categories Data Fetching ======================
      org &&
        onSnapshot(
          categoriesRef,
          { includeMetadataChanges: true },
          (snapshot: { docs: any[] }) => {
            dispatch(
              setCategories(
                snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
              )
            );
          }
        )
    );
  }, [dispatch, user, currentUser?.email]);

  //Fetch System Settings ===========================
  useEffect((): any => {
    return (
      org &&
      onSnapshot(
        systemRef,
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          dispatch(
            loadSystemSettings(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
        }
      )
    );
  }, [dispatch, user]);

  //Add Custom Fields
  useEffect((): any => {
    return (
      user?.access !== "client" &&
      org &&
      onSnapshot(
        customFieldsRef,
        { includeMetadataChanges: true },
        (snapshot: { docs: any[] }) => {
          dispatch(
            setCustomFields(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
        }
      )
    );
  }, [dispatch, user]);

  //Load Email_Accounts ====================
  useEffect(() => {
    return onSnapshot(
      emailAccountsRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        if (user?.access !== "client") {
          dispatch(
            loadAccounts(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
        }
      }
    );
  }, [dispatch, user]);

  //Private Canned Responses ================
  useEffect(() => {
    return onSnapshot(
      collection(db, `companies/${org}/members/${user?.id}/canned_responses`),
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        if (user?.access !== "client") {
          dispatch(
            updateCannedRes(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
        }
      }
    );
  }, [user, dispatch]);

  //Public Canned Responses ==========
  useEffect(() => {
    return onSnapshot(
      publicCannedResRef,
      { includeMetadataChanges: true },
      (snapshot: { docs: any[] }) => {
        if (user?.access !== "client") {
          dispatch(
            updatePublicCannedRes(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
        }
      }
    );
  }, [user, dispatch]);

  //Todo CollectionRef and Notifications ====================
  useEffect((): any => {
    const toDoRef: any | null =
      org &&
      user &&
      collection(db, `companies/${org}/members/${user?.id}/to-do`);
    const notificationsRef: any | null =
      org &&
      user &&
      collection(db, `companies/${org}/members/${user?.id}/notifications`);
    return (
      org &&
        onSnapshot(
          toDoRef,
          { includeMetadataChanges: true },
          (snapshot: { docs: any[] }) => {
            dispatch(
              setToDo(
                snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
              )
            );
          }
        ),
      org &&
        onSnapshot(
          notificationsRef,
          { includeMetadataChanges: true },
          (snapshot: { docs: any[] }) => {
            dispatch(
              setMessages(
                snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
              )
            );
          }
        )
    );
  }, [dispatch, user]);

  //Component ==================
  return (
    <>
      <QueueData />
      <TicketsDataFetcher />
    </>
  );
};

export default TicketsnUserData;
