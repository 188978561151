import UserSlice from "./Slices/UserSlice";
import NotificationsSlice from "./Slices/NotificationsSlice";
import TicketsSlice from "./Slices/Tickets_n_Contacts_Slice";
import SettingsSlice from "./Slices/SettingsSlice";
import DashboardSlice from "./Slices/DashboardSlice";
import QueueSlice from "./Slices/QueueSlice";
import ChatSlice from "./Slices/ChatSlice";

const rootReducer = {
  UserInfo: UserSlice,
  NotificationsData: NotificationsSlice,
  Tickets: TicketsSlice,
  SettingsData: SettingsSlice,
  DashboardData: DashboardSlice,
  QueueData: QueueSlice,
  ChatData: ChatSlice,
};

export default rootReducer;
