import { FC, useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../Redux/store";
import {
  changeLocation,
  setCompany,
  updateUser,
} from "../../Redux/Slices/UserSlice";
//Firestore ===================
import { collection, getDocs, where, query } from "firebase/firestore";
import { notifications } from "@mantine/notifications";
import { useAuth } from "../../Custom-Hooks/useAuth";
import { RiSearch2Line } from "react-icons/ri";
import { auth, db } from "../../APIs & Data/Firebase";
import { usePostHog } from "posthog-js/react";

const WorkspaceOption: FC = () => {
  const posthog = usePostHog();
  const [loading, setLoading] = useState<boolean | string>(false);
  const dispatch: AppDispatch = useDispatch();
  const [searchValue, setSearch] = useState<string>("");
  const { currentUser }: { currentUser: any } = useAuth();
  const [userRoles, setUserRoles] = useState<any[]>([]);
  const navigate = useNavigate();

  //Get User Workspace If Avaialble
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            setUserRoles(idTokenResult.claims.userRoles || []);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setUserRoles([]);
      }
    });
    return unsubscribe;
  }, [auth]);

  // init services for firestore & User Selection Func =========================
  const selectWorkspace = (space: any) => {
    setLoading(space?.workspace_name);
    //Remove All Local Storage Data ===============
    [
      "dashboard_stats_data",
      "dashboard_other_data",
      "loginvalues",
      "chatData",
    ]?.forEach((key: string) => {
      window.localStorage.removeItem(key);
    });

    //Check If Workspace or Company Exists ===============
    const companiesRef: any = query(
      collection(
        db,
        `companies/${space?.workspace_name
          ?.toLowerCase()
          ?.replace(/\s/gim, "")}/members`
      ),
      where(
        "email",
        "==",
        currentUser?.email?.replace(/\s/gim, "")?.toLowerCase()
      ),
      where("access", "in", ["owner", space?.role])
    );

    //Check if the workspace exist or not ============
    getDocs(companiesRef)
      .then((snapshot) => {
        if (snapshot.docs?.length >= 1) {
          //Notify User To Verify Their Account ========
          if (!currentUser.emailVerified) {
            notifications.show({
              title: "Please Verify your account",
              message: "Please Verify your account",
              color: "yellow",
            });
          }

          //Check If user has any data in the collection
          const loggedUser = snapshot.docs
            .map((doc: any) => ({
              ...doc.data(),
              id: doc.id,
            }))
            .find((member: any) => member?.email === currentUser.email);

          //Add User Data =================
          dispatch(updateUser(loggedUser));
          notifications.show({
            title: "Logged In Succesfully",
            message: "Logged In Succesfully",
            color: "green",
          });

          //Login If All Set ==================
          dispatch(setCompany(space?.workspace_name));
          navigate(`/?workspace=${space?.workspace_name}`);
          posthog?.capture("clicked_log_in");
          setLoading(false);
          window.location.reload();
        } else {
          setLoading(false);
          notifications.show({
            title: "Workspace | Account Doesn't Exist",
            message: "Workspace | Account Doesn't Exist 🤥",
            color: "red",
          });
        }
      })
      .catch(() => {
        setLoading(false);
        notifications.show({
          title: "Failed to log in please retry",
          message: "Failed to log in please retry ",
          color: "red",
        });
      });
  };

  //Check If User Is Logged
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (
        !window.localStorage.getItem("organization_name") &&
        !user &&
        !window.localStorage.getItem("bs-sessions-persist")
      ) {
        navigate("/login");
      }
    });
    return unsubscribe;
  }, [auth, navigate]);

  //component ============================================================
  return (
    <div
      className={`min-h-[40rem] h-screen w-screen bg-black-50 transition-all duration-300 
        flex flex-col justify-center items-center gap-6 px-4 py-8 pt-14 overflow-hidden`}
    >
      <h1 className="text-black-800 text-lg sm:text-2xl font-bold uppercase">
        Choose your Workspace
      </h1>
      <div className="w-[90%] md:w-[30rem] h-fit relative shrink-0 flex items-center gap-2 overflow-hidden px-1">
        <label htmlFor="search_workspace" className="flex w-full relative">
          <input
            type="search"
            name="search"
            id="search"
            value={searchValue}
            autoComplete="off"
            placeholder="Find your Workspace ..."
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            className="h-11 w-full p-2 px-4 text-xs text-black-700 font-medium
              rounded bg-white border-2 border-black-300 focus:border-black-900/80
               focus:ring-0 focus:outline-none transition-all placeholder:text-black-500"
          />
          <RiSearch2Line className="absolute right-4 top-3 text-black-500 text-lg" />
        </label>
      </div>
      <ul
        className="h-full max-h-[16rem] w-[90%] md:w-[30rem] p-1 gap-2 flex flex-col overflow-hidden 
         overflow-y-scroll no-scrollbar no-scrollbar::-webkit-scrollbar"
      >
        {userRoles.length >= 1 &&
          userRoles
            ?.filter((data: any) =>
              data?.workspace_name?.includes(
                searchValue?.toLowerCase()?.replace(/\s/gi, "")
              )
            )
            ?.sort((a: any, b: any) =>
              a.workspace_name < b.workspace_name ? -1 : 1
            )
            ?.map((space: any, index: number) => {
              return (
                <li
                  key={space?.workspace_name + index}
                  className="w-full h-14 rounded bg-white p-2 flex items-center space-x-2 box-shadow-half shrink-0"
                >
                  <div className="h-full w-10 rounded bg-black-800 uppercase text-lg text-white font-bold flex items-center justify-center">
                    {space?.workspace_name?.charAt(0)}
                  </div>
                  <div className="h-full w-[calc(100%-2.75rem)] flex items-center justify-between">
                    <div className="flex flex-col justify-center w-4/5 h-full">
                      <span
                        className="w-full overflow-hidden whitespace-nowrap text-ellipsis text-xs sm:text-sm 
                         capitalize font-bold text-black-700"
                      >
                        {space?.account_name}
                      </span>
                      <span
                        className="w-full overflow-hidden whitespace-nowrap text-ellipsis text-[0.65rem] 
                          capitalize font-semibold text-black-500"
                      >
                        {space?.role}
                      </span>
                    </div>
                    <button
                      onClick={() => selectWorkspace(space)}
                      className="w-24 h-7 text-[0.65rem] sm:text-xs text-black-700 font-semibold pt-0.5 sm:pt-0 
                        border border-black-300 flex justify-center items-center  bg-white rounded-full
                         hover:shadow-lg transition-all"
                    >
                      {loading === space?.workspace_name ? (
                        <div
                          className="h-4 w-4 rounded-full border-4 border-black-700 
                      border-l-black-400 animate-spin"
                        ></div>
                      ) : (
                        <span className="flex">Select</span>
                      )}
                    </button>
                  </div>
                </li>
              );
            })}
      </ul>
      <button
        onClick={() => {
          dispatch(changeLocation("Basedesk"));
          signOut(auth).then(() => {
            window.localStorage.clear();
            document.title = "dndHelp-Desk";
            navigate("/logIn");
            window.location.reload();
          });
        }}
        className="h-12 w-[90%] md:w-[30rem] bg-black-900 text-white text-xs uppercase font-semibold
           shrink-0 rounded hover:opacity-70 transition-all"
      >
        sign-out
      </button>
    </div>
  );
};

export default WorkspaceOption;
