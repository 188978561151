import { FC, useState } from "react";
import { TbTrash, TbUserPlus } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import {
  addCollaborator,
  deleteCollaborator,
} from "../../../APIs & Data/TicketsnUserData";
import { updateAlert } from "../../../Redux/Slices/NotificationsSlice";
import default_pro from "../../../Assets/images/default_user.png";

type Props = {
  collabModal: boolean;
  setCollabModal: any;
  openTicket: any;
};

const Collaborators: FC<Props> = ({
  collabModal,
  setCollabModal,
  openTicket,
}) => {
  const dispatch = useDispatch();
  const allMember = useSelector(
    (state: RootState) => state.UserInfo.allMembers
  );
  const alerts = useSelector(
    (state: RootState) => state.NotificationsData.alerts
  );
  const user = useSelector(
    (state: RootState) => state.UserInfo.member_details
  )[0];
  const [search, setSearch] = useState<string>("");

  //component ======
  return (
    <div
      className={`fixed -top-4 bottom-0 z-[9999] bg-black-900/50 ${
        collabModal ? "left-0 right-0" : "left-[200%] -right-[200%]"
      } flex justify-end items-center transition-all`}
    >
      <div className="h-full w-[25rem] bg-white dark:bg-black-700 pt-4 relative">
        <div className=" w-full h-[9rem] overflow-hidden p-4">
          <div
            className="text-sm dark:text-black-200 text-black-800 uppercase font-bold font-sans 
            flex items-center gap-1"
          >
            <span>Collaborators</span>
          </div>
          <p
            className="mt-2 text-[0.65rem] dark:text-black-400 text-black-600 font-medium
           leading-[1rem]"
          >
            Collaborators can help in resolving a customer issue more quickly by
            sharing their knowledge and expertise, adding their own notes and
            comments to the ticket, or even taking over the ticket from the
            primary agent if they are unavailable. They can also provide
            additional context or information to the primary agent that can help
            them in resolving the issue.
          </p>
        </div>

        {/**Collaborators List ========= */}
        <ul className="w-full space-y-2 p-4 px-4">
          {openTicket?.collaborators?.length >= 1 &&
            [
              ...openTicket?.collaborators?.map(
                (data: any) =>
                  allMember?.filter((user: any) => user?.email === data)[0]
              ),
            ]?.map((col: any) => {
              return (
                <div
                  key={col?.id}
                  className="w-full h-14 rounded bg-black-50 dark:bg-black-800 flex items-center 
                  justify-between p-2 text-black-700 dark:text-black-300 border border-black-300 dark:border-black-600"
                >
                  <div className="h-full flex items-center space-x-2">
                    <div
                      className="h-9 w-9 bg-black-200 dark:bg-black-700 rounded-full flex 
                    items-center justify-center overflow-hidden"
                    >
                      <img
                        onError={(e) => {
                          e.currentTarget.src = default_pro;
                        }}
                        src={col?.photoUrl ? col?.photoUrl : default_pro}
                        alt="profile"
                        className="object-fit object-cover h-full w-full"
                      />
                    </div>
                    <div className="h-full flex flex-col justify-center">
                      <span className="text-[0.65rem] font-semibold capitalize">
                        {col?.name}
                      </span>
                      <span
                        className={`text-[0.65rem] 
                      ${
                        col?.status === "busy"
                          ? "text-yellow-500"
                          : col?.status === "available"
                          ? "text-green-600"
                          : "text-red-600"
                      } capitalize
                       flex items-center space-x-1`}
                      >
                        <div
                          className={`h-2 w-2 rounded-sm
                      ${
                        col?.status === "busy"
                          ? "bg-yellow-500"
                          : col?.status === "available"
                          ? "bg-green-600"
                          : "bg-red-600"
                      }`}
                        ></div>{" "}
                        <span>{col?.status}</span>
                      </span>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      deleteCollaborator(
                        openTicket?.id,
                        col?.email,
                        user,
                        openTicket?.ticket_id,
                        col?.id
                      );
                      dispatch(
                        updateAlert([
                          ...alerts,
                          {
                            message: `Action complete`,
                            color: "bg-green-200",
                            id: new Date().getTime(),
                          },
                        ])
                      );
                    }}
                    className="bg-inherit p-1 hover:opacity-80 transition-all"
                  >
                    <TbTrash className="text-red-600 text-xl" />
                  </button>
                </div>
              );
            })}
        </ul>

        {/**New Collab Search======== */}
        <div className="px-4 flex items-center justify-between">
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            value={search}
            placeholder="Search for a new collaborator"
            className="bg-inherit rounded text-xs text-black-700 dark:text-black-300 h-10 w-full transition-all 
            p-2 border-black-300 dark:border-black-600 border-2 focus:ring-0 bg-black-50 dark:bg-black-750"
          />
        </div>

        {/**Search Results List ========= */}
        <ul className="w-full space-y-2 p-4 px-4">
          {allMember.length >= 1 &&
            search.length >= 3 &&
            allMember
              ?.filter(
                (data) =>
                  (data.name
                    ?.toLowerCase()
                    ?.replace(/\s/gi, "")
                    ?.includes(search?.toLowerCase()?.replace(/\s/gi, "")) ||
                    data.email
                      ?.toLowerCase()
                      ?.replace(/\s/gi, "")
                      ?.includes(search?.toLowerCase()?.replace(/\s/gi, ""))) &&
                  !openTicket?.collaborators?.some(
                    (email: any) => email === data?.email
                  ) &&
                  data?.access !== "client"
              )
              ?.map((member: any) => {
                return (
                  <div
                    key={member?.id}
                    className="w-full h-14 rounded bg-black-50 dark:bg-black-800 flex items-center
                     justify-between p-2 text-black-700 dark:text-black-300 border border-black-300 dark:border-black-600"
                  >
                    <div className="h-full flex items-center space-x-2">
                      <div
                        className="h-9 w-9 bg-black-200 dark:bg-black-700 rounded-full flex items-center
                       justify-center overflow-hidden"
                      >
                        <img
                          onError={(e) => {
                            e.currentTarget.src = default_pro;
                          }}
                          src={
                            member?.photoUrl ? member?.photoUrl : default_pro
                          }
                          alt="profile"
                          className="object-fit object-cover h-full w-full"
                        />
                      </div>
                      <div className="h-full flex flex-col justify-center">
                        <span className="text-[0.65rem] font-semibold capitalize">
                          {member?.name}
                        </span>
                        <span
                          className={`text-[0.65rem]
                      ${
                        member?.status === "busy"
                          ? "text-yellow-500"
                          : member?.status === "available"
                          ? "text-green-600"
                          : "text-red-600"
                      } capitalize
                       flex items-center space-x-1`}
                        >
                          <div
                            className={`h-2 w-2 rounded-sm
                      ${
                        member?.status === "busy"
                          ? "bg-yellow-500"
                          : member?.status === "available"
                          ? "bg-green-600"
                          : "bg-red-600"
                      }`}
                          ></div>{" "}
                          <span>{member?.status}</span>
                        </span>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        addCollaborator(
                          openTicket?.id,
                          member?.email,
                          user,
                          openTicket?.ticket_id,
                          member?.id
                        );

                        dispatch(
                          updateAlert([
                            ...alerts,
                            {
                              message: `Action complete`,
                              color: "bg-green-200",
                              id: new Date().getTime(),
                            },
                          ])
                        );
                      }}
                      className="bg-inherit p-1 hover:opacity-80 transition-all"
                    >
                      <TbUserPlus className="text-blue-600 text-xl" />
                    </button>
                  </div>
                );
              })}
        </ul>

        {/**Action */}
        <button
          onClick={() => setCollabModal(false)}
          className="absolute bottom-0 left-0 right-0 h-12 bg-blue-600 text-white text-xs
        uppercase font-semibold hover:opactiy-80 transition-all"
        >
          cancel
        </button>
      </div>
    </div>
  );
};

export default Collaborators;
