import "./Styles/index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { Notifications } from "@mantine/notifications";
import { createRoot } from "react-dom/client";
import { Toaster } from "sonner";
import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
};

const container: any = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PostHogProvider
      apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <Notifications />
      <Toaster richColors />
      <App />
    </PostHogProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
