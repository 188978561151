import React, { useMemo } from "react";
import useFetch from "../../Custom-Hooks/useFetch";
import { Link } from "react-router-dom";

type Props = {};

const Status = (props: Props) => {
  const { data, isPending } = useFetch(
    "https://updown.io/api/checks?api-key=iK5CmQCRagGd9aqW65iM"
  );
  const status = useMemo(() => {
    const statuses = data
      ?.map((serveice: any) => serveice?.last_status)
      ?.reduce((acc: any, curr: any) => acc + curr, 0);
    const percentage = (statuses / ((data?.length || 1) * 200)) * 100;
    return isNaN(percentage) ? 100 : percentage;
  }, [data]);

  //HTML Component
  return (
    <Link
      to="https://basedesk-status.openstatus.dev/"
      //"https://updown.io/p/z8gi8"
      target="_blank"
      className="h-8 w-full px-3 rounded border border-zinc-200 bg-white flex items-center
       overflow-hidden gap-2 hover:shadow-md transition-all"
    >
      <div
        className={`h-3 w-3 rounded-full shrink-0 
        ${status >= 90 ? "bg-green-500" : status < 90 && status >= 70 ? "bg-yellow-500" : "bg-red-500"}`}
      />
      <span className="text-xxs uppercase font-bold text-zinc-500 whitespace-nowrap">
        {status >= 90
          ? "Systems operational"
          : status < 90 && status >= 70
            ? "Minor Degradation"
            : "Critical"}
      </span>
    </Link>
  );
};

export default Status;
