import React from "react";
import { Link } from "react-router-dom";

const TermsPrivacy = () => {
  return (
    <p className="text-xs text-zinc-500 max-w-[23rem] text-center overflow-hidden z-10">
      By continuing you are agreeing to our{" "}
      <Link to="/legal" target="_blank" className="underline text-sky-600/70">
        Terms of Service and
        Privacy Policy
      </Link>
    </p>
  );
};

export default React.memo(TermsPrivacy);
