import { FC } from "react";
import { useDispatch } from "react-redux";
import { updateAlert } from "../../Redux/Slices/NotificationsSlice";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  openPanel: boolean;
  setActionPanel: any;
  deleteSelected: any;
  option: string;
  warningMsg?: string;
  showAlert?: boolean;
};

const ActionPanel: FC<Props> = ({
  openPanel,
  setActionPanel,
  deleteSelected,
  option,
  warningMsg,
  showAlert,
}) => {
  const dispatch = useDispatch();

  //component ======
  return (
    <AnimatePresence>
      {openPanel&&<motion.div
        initial={{opacity:0}}
        animate={{opacity:1}}
        exit={{opacity:0}}
        transition={{duration:0.3}}
        className={`fixed left-0 right-0 top-0 bottom-0 z-[9999] bg-black-900/40 backdrop-blur-md 
        flex justify-center items-center drop-shadow-2xl shadow-2xl`}
      >
        <div
          id="actionPanel"
          className={`drop-shadow-2xl shadow-2xl bg-black-50 dark:bg-black-800 rounded 
        h-[60vh] md:h-[12rem] md:w-[27rem] w-[50vw]  relative overflow-hidden flex flex-col gap-4
         p-4 border border-black-200 dark:border-black-700 transition-all duration-300 z-[999]`}
        >
          <div className="flex w-full justify-between items-center text-black-800 dark:text-black-300 text-sm font-medium">
            <h2>{`Delete this ${option} ?`}</h2>
          </div>

          <p className="text-black-800 dark:text-black-300 text-xs">
            {warningMsg
              ? warningMsg
              : `Are you sure you want to delete this ${option}? This action is
          destructive and can not be undone. All the data related to this ${option}
          will be permanently removed.`}
          </p>
          <div className="absolute bottom-4 left-0 right-4 flex justify-end items-center space-x-4">
            <button
              type="button"
              onClick={() => {
                setActionPanel(false);
              }}
              className="h-8 rounded-sm text-xs font-normal hover:opacity-80 transition-all capitalize 
            duration-200 px-6 bg-blue-700 text-black-50 outline-none focus:outline-none"
            >
              Cancel action
            </button>
            <button
              type="button"
              onClick={() => {
                deleteSelected();
                setActionPanel(false);
                !showAlert &&
                  dispatch(
                    updateAlert({
                      message: "Action Completed successfully",
                      color: "bg-green-200",
                    })
                  );
              }}
              className="h-8 rounded-sm text-xs font-normal hover:opacity-80 transition-all capitalize 
            duration-200 px-6 bg-red-600 text-black-50 outline-none focus:outline-none"
            >
              {`Delete ${option}`}
            </button>
          </div>
        </div>
      </motion.div>}
    </AnimatePresence>
  );
};

export default ActionPanel;
