import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { membersRef } from "./TicketsnUserData";
import { limit, onSnapshot, query, where } from "firebase/firestore";
import { useAuth } from "../Custom-Hooks/useAuth";
import { updateUser } from "../Redux/Slices/UserSlice";

const UserData = () => {
  const { loading, currentUser } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading || !currentUser) {
      return;
    }

    const q = query(
      membersRef,
      where("email", "==", currentUser.email),limit(1)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        dispatch(
          updateUser(
            snapshot.docs.map((doc:any) => ({
              ...doc.data(),
              id: doc.id,
            }))
          )
        );
      },
      (error) => {
        console.error("Error fetching user data:", error.message);
        // Handle the error appropriately
      }
    );

    return unsubscribe; // Return the unsubscribe function to clean up the subscription
  }, [currentUser, dispatch, loading]);

  return null; // If nothing is rendered, return null
};

export default UserData;
