import { getApp, getApps, initializeApp } from "firebase/app";
import { browserLocalPersistence, browserPopupRedirectResolver, getAuth, initializeAuth, updateProfile } from "firebase/auth";
import {
  CACHE_SIZE_UNLIMITED,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";
// import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
// import { updateProfileUrl } from "./TicketsnUserData";

//Config Firebase ==================================
export const firebaseConfig = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  projectId: "dial-n-dine-help-desk",
  storageBucket: "dial-n-dine-help-desk.appspot.com",
  messagingSenderId: import.meta.env.VITE_MS_ID,
  appId: import.meta.env.VITE_APP_ID,
  measurementId: "G-GZCS9SQW3Z",
};

// Detect if it is dev mode
declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string;
  }
}

if (typeof window !== "undefined" && window.location.hostname === "localhost") {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = import.meta.env
    .VITE_APP_CHECK_KEY as string;
}

// initializeApp() is called before getAuth() for sure
export const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
let AppCheckInstance = null;

if (typeof window !== "undefined" && !AppCheckInstance) {
  import("firebase/app-check").then(async (firebaseAppCheck) => {
    const captachp = import.meta.env.VITE_APP_CHECK_KEY as string;
    AppCheckInstance = firebaseAppCheck.initializeAppCheck(app, {
      provider: new firebaseAppCheck.ReCaptchaEnterpriseProvider(captachp),
      isTokenAutoRefreshEnabled: true,
    });
  });
}

// init services for firestore =========================
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache(
    /*settings*/ {
      tabManager: persistentMultipleTabManager(),
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    }
  ),
});

//Get Current Logged User Related Data
export const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
});

// // Storage
// const storage = getStorage();
// export async function upload(file: any, currentUser: any, id?: any) {
//   const fileRef = ref(storage, currentUser.uid + ".png");
//   await uploadBytes(fileRef, file);
//   const photoURL = await getDownloadURL(fileRef);
//   await updateProfile(currentUser, { photoURL });
//   //updateProfileUrl(id, photoURL);
//   return photoURL
// }

//Get Auth Token
export const getToken = async () => {
  const user = auth?.currentUser;
  if (user) {
    const idTokenResult = await user.getIdTokenResult();
    return idTokenResult.token;
  }
  return "";
};
