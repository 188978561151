import { FC, useRef, useState } from "react";
import { TbSelector } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import { objectsMatch } from "../../../../Reusable Functions/Reusable_Func";
import { updateTicket } from "../../../../APIs & Data/TicketsnUserData";
import { updateAlert } from "../../../../Redux/Slices/NotificationsSlice";

type Props = {
  openTicket: any;
};

const UpdatableFields: FC<Props> = ({ openTicket }) => {
  const ticket = openTicket || {};
  const dispatch = useDispatch();
  const [showLoading, setLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const category = useSelector((state: RootState) => state.Tickets.categories);
  const groups = useSelector((state: RootState) => state.SettingsData.groups);
  const priorities = ["low", "medium", "high", "urgent"];
  const [updatableOptions, setOptions] = useState<any>({
    priority: ticket?.priority,
    category: ticket?.category,
    team: ticket?.team,
  });

  //Hande Submit =================
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    if (
      !objectsMatch(ticket, updatableOptions, ["priority", "category", "team"])
    ) {
      updateTicket({ ...updatableOptions, id: ticket?.id })
        .then(() => {
          dispatch(
            updateAlert({
              message: "Fields updated successfully",
              color: "bg-green-200",
            })
          );
          setLoading(false);
          if (formRef && formRef?.current) {
            formRef.current?.reset();
          }
        })
        .catch((err) => {
          dispatch(
            updateAlert({
              message: err.message,
              color: "bg-red-200",
            })
          );
          setLoading(false);
        });
    }
  };

  //Component ============
  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit}
      className="w-full h-fit p-4 rounded drop-shadow border border-black-200 dark:border-black-700
       bg-white dark:bg-black-800 flex flex-col divide-y divide-black-200 dark:divide-black-400/10 select-none"
    >
      {Object?.keys(updatableOptions)?.map((field: string) => {
        return (
          <div
            key={field}
            className="text-xs flex items-center justify-between gap-4 w-full h-10 min-h-[2.5rem]"
          >
            <div
              className={`"hidden sm:flex items-center space-x-2 font-semibold capitalize w-2/5 text-xs
                  overflow-hidden whitespace-nowrap overflow-ellipsis text-black-700 dark:text-black-200`}
            >
              <TbSelector className="text-lg" />
              <span>{field?.replace(/_/gim, " ")}</span>
            </div>
            <div
              className={`"hidden sm:flex w-3/5 h-full text-[0.65rem] overflow-hidden whitespace-nowrap overflow-ellipsis ${
                field?.includes("email")
                  ? "lowercase text-blue-600"
                  : "capitalize"
              } truncate text-black-600 dark:text-black-300 text-xs font-medium relative
                flex items-center justify-between`}
            >
              <select
                onChange={(e) => {
                  setOptions((prev: any) => ({
                    ...prev,
                    [field]: e.target.value,
                  }));
                }}
                className="w-full h-8 border-none focus:ring-0 whitespace-nowrap truncate overflow-hidden 
                 bg-inherit text-[0.65rem] uppercase font-medium"
              >
                <option value={ticket?.[field]}>{ticket?.[field]}</option>
                {[
                  ...(field === "category"
                    ? category?.map((data: any) => data?.name)
                    : field === "priority"
                    ? priorities
                    : field === "team"
                    ? groups?.map((data: any) => data?.name)
                    : []),
                ]?.map((option: any) => {
                  return <option key={option}>{option}</option>;
                })}
              </select>
            </div>
          </div>
        );
      })}
      <div className="w-full flex justify-end pt-4">
        <button
          disabled={objectsMatch(ticket, updatableOptions, [
            "priority",
            "category",
            "team",
          ])}
          type="submit"
          className="h-9 w-full rounded-sm disabled:bg-blue-500 bg-blue-600 text-white
         text-xs font-semibold uppercase disabled:cursor-not-allowed flex justify-center items-center"
        >
          {!showLoading ? (
            <span>update</span>
          ) : (
            <div className="h-4 w-4 border-4 border-l-blue-400 rounded-full animate-spin"></div>
          )}
        </button>
      </div>
    </form>
  );
};

export default UpdatableFields;
