import { useEffect, useState } from "react";
import { auth } from "../APIs & Data/Firebase";

const useAuthToken = () => {
  const [authData, setAuthData] = useState<null | string>(null);

  useEffect(() => {
    let refreshTimer: NodeJS.Timeout | null = null;

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult();
          const token = idTokenResult.token;
          const expirationTime = idTokenResult.expirationTime;

          // Calculate token expiration (in milliseconds)
          const expiresIn = new Date(expirationTime).getTime() - Date.now();

          // Set the refresh timer a bit before expiration (e.g., 5 minutes)
          refreshTimer = setTimeout(async () => {
            try {
              const newIdTokenResult = await user.getIdTokenResult(true); // Force refresh
              setAuthData(newIdTokenResult.token);
            } catch (error: any) {
              setAuthData(null);
            }
          }, expiresIn - 300000); // 5 minutes = 300,000 milliseconds

          setAuthData(token);
        } catch (error: any) {
          setAuthData(null);
        }
      } else {
        setAuthData(null);
        if (refreshTimer) {
          clearTimeout(refreshTimer);
        }
      }
    });

    return () => {
      unsubscribe();
      if (refreshTimer) {
        clearTimeout(refreshTimer);
      }
    };
  }, []);

  return authData;
};

export default useAuthToken;
