import React from "react";
import { TbArrowRight } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../APIs & Data/Firebase";
import Loader from "../../Components/Misc/Loader";

const ForgotPassword = () => {
  const [formdata, setData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();

  //Handle Submit
  const handleSubmit = (e: any) => {
    e.preventDefault();

    const promise = async () => {
      setLoading(true);
      return await sendPasswordResetEmail(auth, formdata?.email)
    };

    toast.promise(promise, {
      loading: "Please wait ...",
      success: () => {
        setData({});
        setLoading(false);
        navigate("/login");
        return "Please check your inbox";
      },
      error: (error: any) => {
        setLoading(false);
        return error.message || "An error occured";
      },
    });
  };

  //HTML Content =============
  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 flex flex-col bg-zinc-100 itmes-center
       overflow-hidden overflow-y-auto p-6"
    >
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-[25rem] h-fit m-auto flex flex-col items-center"
      >
        <img
          src="/logo/maskable_icon.png"
          alt="logo"
          className="h-12 w-12 rounded-lg shadow border-2 border-white"
        />
        <h1 className="mt-6 text-lg md:text-xl font-bold text-center uppercase text-zinc-800">
          Forgot password
        </h1>
        <p className="mt-3 text-xs md:text-sm text-zinc-500 font-medium text-center">
          Please enter your email below to get a password reset link
        </p>

        <label
          htmlFor="email"
          className="mt-4 w-full flex flex-col gap-1 shrink-0 relative"
        >
          <input
            onChange={(e) => {
              setData((prev: any) => ({
                ...prev,
                email: e.target?.value,
              }));
            }}
            title="Match requirements"
            autoComplete="none"
            required
            value={formdata?.email || ""}
            type={"email"}
            name="email"
            id="email"
            placeholder="Your email ..."
            className="w-full h-12 shrink-0 rounded border border-zinc-200 focus:border-sky-300 text-xs
          font-medium text-zinc-700 placeholder:text-zinc-500/80 px-4 bg-white"
          />
        </label>

        <button
          disabled={loading}
          type="submit"
          className="mt-4 rounded w-full h-12 bg-black-800 text-white text-xs uppercase font-semibold 
          flex items-center justify-center gap-2 hover:bg-black-700 transition-all shrink-0 disabled:cursor-not-allowed"
        >
          {loading ? (
            <div className="h-5 w-5 border-2 border-white border-l-black-400 rounded-full animate-spin"></div>
          ) : (
            <span className="font-semibold">get reset link</span>
          )}
        </button>

        {/**Or Sign In ================== */}

        <div className="w-full flex items-center gap-4 overflow-hidden my-4">
          <hr className="border-zinc-300 border-dashed w-1/2" />
          <span className="text-xs font-semibold text-zinc-500 uppercase">
            {" "}
            or
          </span>
          <hr className="border-zinc-300 w-1/2 border-dashed" />
        </div>
        <Link
          to="/login"
          className="rounded w-full h-12 text-black-800 text-xs uppercase font-semibold border-2 border-black-500 
          flex items-center justify-center gap-2 hover:gap-3 transition-all group bg-white shrink-0"
        >
          <span className="font-semibold">sign in</span>
          <TbArrowRight className="text-lg -rotate-45 group-hover:rotate-0 transition-all" />
        </Link>
      </form>

      {/**Loading */}
      {loading && <Loader />}
    </div>
  );
};

export default ForgotPassword;
