import { FC, useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { BiShowAlt } from "react-icons/bi";
import { MdOutlineAlternateEmail, MdLockOutline } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { auth } from "../../APIs & Data/Firebase";
import Loader from "../../Components/Misc/Loader";
import Terms_n_privacy from "../../Components/ui/terms_n_privacy";
import Status from "../../Components/Misc/status";

interface InputInter {
  email: string;
  password: string;
}

const LogIn: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [revealPass, setReveal] = useState<boolean>(false);
  const [inputValues, setValues] = useState<InputInter>({
    email: "",
    password: "",
  });

  //Log in User =====================
  const handleSubmit = (e: React.SyntheticEvent) => {
    setLoading(true);
    e.preventDefault();
    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithEmailAndPassword(auth, inputValues.email, inputValues.password)
        .then(() => {
          setLoading(false);
          navigate("/workspace");
        })
        .catch((error) => {
          setLoading(false);
          notifications.show({
            title: "Error",
            message:
              error.message.includes(":") === true
                ? error.message.split(":")[1].replace(/firebase|Error/gim, "")
                : error.message.replace(/firebase|Error/gim, ""),
            color: "red",
          });
        });
    });
  };

  //Check If User Is Logged
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (
        window.localStorage.getItem("organization_name") &&
        user &&
        window.localStorage.getItem("bs-sessions-persist")
      ) {
        navigate("/");
      }
    });
    return unsubscribe;
  }, [navigate]);

  //React Component =====================================================================================
  return (
    <>
      {!loading ? (
        <div className="bg-black-50 w-screen h-screen min-h-[40rem]">
          <div
            //style={{ background: `url(${bgLight})` }}
            className="bg-gradient-to-br from-black-50 via-blue-200/80 to-white w-full min-h-full h-full relative overflow-hidden
            bg-no-repeat bg-cover bg-center"
          >
            <div
              className="fixed inset-0 bg-gradient-to-b from-black-50/50 
              to-transparent flex justify-center items-center"
            >
              <div
                className="fixed top-2 2xl:top-4 inset-x-0 text-black-800 text-xs font-semibold flex items-center 
                justify-between space-x-4 h-fit px-4"
              >
                <div className="w-44">
                  <Status />
                </div>
                <div className="flex items-center justify-between gap-4 overflow-hidden">
                  <span>Not a member yet ? </span>
                  <Link
                    to="https://basedesk.co.za/pricing"
                    className="h-8 px-6 flex justify-center items-center text-black-50 
                     bg-black-900 hover:bg-black-800 transition-all rounded font-sans font-medium"
                  >
                    Sign Up
                  </Link>
                </div>
              </div>

              <div
                className="w-[90%] sm:w-[26rem] space-y-6 p-8 
            pb-8 pt-10 relative"
              >
                <div>
                  <Link to="https://basedesk.co.za">
                    <img
                      className="mx-auto h-14 w-14 rounded-lg box-shadow-one"
                      src="/logo/maskable_icon.png"
                      alt="Basedesk logo"
                    />
                  </Link>
                  <h1 className="mt-6 text-center text-lg font-bold text-black-800 uppercase">
                    Let&apos;s get you back in
                  </h1>
                </div>
                <form className="mt-8 flex flex-col" onSubmit={handleSubmit}>
                  <label
                    htmlFor="email-address"
                    className="w-full flex relative"
                  >
                    <input
                      type="email"
                      name="email"
                      id="email"
                      required
                      aria-required
                      aria-placeholder="Your email ..."
                      placeholder="Email address"
                      onChange={(e) =>
                        setValues({ ...inputValues, email: e.target.value })
                      }
                      value={inputValues.email}
                      className="signupStyles"
                    />
                    <MdOutlineAlternateEmail className="absolute left-3 top-5 text-black-700 text-lg" />
                  </label>
                  <label
                    htmlFor="password"
                    className="w-full mt-3 flex relative"
                  >
                    <input
                      type={revealPass ? "text" : "password"}
                      name="password"
                      id="password"
                      aria-required
                      required
                      autoComplete="off"
                      aria-placeholder="Your password..."
                      placeholder="Password"
                      onChange={(e) =>
                        setValues({ ...inputValues, password: e.target.value })
                      }
                      value={inputValues.password}
                      className="signupStyles pr-8"
                    />
                    <MdLockOutline className="absolute left-3 top-5 text-black-700 text-lg" />
                    <BiShowAlt
                      onClick={() => {
                        setReveal((prev: boolean) => {
                          if (prev) {
                            return false;
                          } else {
                            return true;
                          }
                        });
                      }}
                      className={`absolute top-5 right-3 text-black-800 cursor-pointer text-lg z-[99] ${
                        inputValues.password?.length >= 2 ? "" : "hidden"
                      }`}
                    />
                  </label>

                  {/** ==================== Rest Options ================= */}
                  <div className="mt-2 w-full flex items-center justify-center overflow-hidden">
                    <button
                      type="button"
                      onClick={() => {
                        navigate("/forgot_password");
                      }}
                      className="text-xs pt-0.5 text-sky-600/70 font-medium whitespace-nowrap truncate
                        transition-all w-fit mr-1 underline underline-offset-2 hover:opacity-80"
                    >
                      Forgot password ?
                    </button>
                  </div>

                  <div>
                    <button
                      disabled={loading}
                      type="submit"
                      className="mt-6 h-14 group relative w-full transition-all flex items-center justify-center space-x-4
                 py-3 px-4 border border-transparent text-sm uppercase font-semibold rounded-lg text-white
                 bg-black-900 hover:bg-black-800 hover:ring-2 ring-offset-2 ring-black-600 
                 focus:outline-none outline-none select-none disabled:cursor-progress"
                    >
                      {!loading ? (
                        <span>Sign In</span>
                      ) : (
                        <div
                          className={`spinner h-4 w-4 rounded-full border-4 border-black-400 border-l-white
                   animate-spin`}
                        ></div>
                      )}
                    </button>
                  </div>
                </form>

                <Terms_n_privacy />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default LogIn;
