import { FC, useState, useEffect, useRef, useMemo } from "react";
import { BiTrash, BiRightArrowAlt } from "react-icons/bi";
import { TbSearch, TbHash } from "react-icons/tb";
import { MdAddCircle, MdShortcut } from "react-icons/md";
import {
  deleteCannedRes,
  deletePublicCannedRes,
  newPublicCannedRes,
} from "../../../APIs & Data/TicketsnUserData";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../Redux/store";
import { updateAlert } from "../../../Redux/Slices/NotificationsSlice";
import { openNewCannedResponse } from "../../../Redux/Slices/ChatSlice";
import { useClickOutside } from "@mantine/hooks";

type Props = {
  setReply: any;
  tooltipPosition: any;
  position: any;
  editorClassName: string;
  openCannedRes: boolean;
  setRes: any;
  onChange: any;
  editorTextBoxRef: any;
};

const CannedResponses: FC<Props> = ({
  setReply,
  tooltipPosition,
  editorClassName,
  openCannedRes,
  setRes,
  onChange,
  editorTextBoxRef,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const modalRef = useClickOutside(()=>setRes(false))
  const user = useSelector((state: RootState) => state.UserInfo.member_details);
  const alerts = useSelector(
    (state: RootState) => state.NotificationsData.alerts
  );
  const privateCannedRes = useSelector(
    (state: RootState) => state.UserInfo.cannedResponses
  );
  const publicCannedRes = useSelector(
    (state: RootState) => state.UserInfo.publicCannedResponses
  );
  const [focusIndex, setFocusIndex] = useState<number>(-1);
  const [allCannedRes, setAllCannedRes] = useState<any[]>([]);
  const [cannedSearch, setCannedSearch] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const hasFocused = useMemo(() => {
    if (openCannedRes && cannedSearch?.length <= 0) {
      return true;
    } else {
      return false;
    }
  }, [openCannedRes, cannedSearch?.length]);

  //Focus On Element
  useEffect(() => {
    hasFocused && inputRef.current && inputRef.current.focus();
  });

  //Combined All Canned Responses
  useEffect(() => {
    setAllCannedRes([
      ...privateCannedRes,
      ...publicCannedRes.filter(
        (data) =>
          Object.values(data)?.some(
            (can: any) =>
              can
                ?.toString()
                ?.toLowerCase()
                ?.replace(/\s/gim, "")
                ?.includes(
                  cannedSearch?.toString()?.toLowerCase()?.replace(/\s/gim, "")
                )
          )
      ),
    ]);
  }, [privateCannedRes, publicCannedRes, cannedSearch]);

  //Select Canned Response Function
  const selectCanned = (template: any) => {
    const editor = editorTextBoxRef?.current;
    if (editor) {
      if (editor?.textContent && editor?.textContent?.length <= 1) {
        editor.innerHTML = template.message?.trim();
      } else {
        editor.innerHTML += template.message?.trim();
      }
      onChange(editor.innerHTML);
      if (editorClassName === "newTicketEditor") {
        setReply((prev: any) => ({
          ...prev,
          description: editor.innerHTML,
        }));
      } else {
        setReply((prev: any) => ({
          ...prev,
          message: editor.innerHTML,
        }));
      }
    }
    setCannedSearch("");
    setRes(false);
  };

  //Navigate Through A List
  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (openCannedRes) {
        if (event.key === "ArrowUp") {
          setFocusIndex(Math.max(focusIndex - 1, 0));
        } else if (event.key === "ArrowDown") {
          setFocusIndex(Math.min(focusIndex + 1, allCannedRes.length - 1));
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [focusIndex, openCannedRes, allCannedRes]);

  //component ==========
  return (
    <div
    ref={modalRef}
      className={`absolute z-[999] ${tooltipPosition} w-[23rem] h-[23rem] ${
        openCannedRes === true ? "flex" : "hidden"
      }`}
    >
      <div
        className={`rounded-xl bg-black-500/50 dark:bg-black-400/60 z-[9999] w-full h-full shadow-xl relative ${
          openCannedRes ? "flex" : "hidden"
        } flex-col gap-4 justify-between backdrop-blur-md overflow-hidden p-2 px-4`}
      >
        {/**Search Bar ==================== */}
        <div
          className="w-full h-12 overflow-hidden relative bg-transparent border-b
           border-black-300/50 "
        >
          <input
            ref={inputRef}
            type="search"
            onChange={(e) => {
              setCannedSearch(e.target.value);
            }}
            autoFocus={true}
            value={cannedSearch}
            placeholder="Quick Search ..."
            className={`outline-none focus:outline-none focus:border-0 focus:ring-0 h-full 
              w-full bg-inherit border-0 text-xs pl-7 placeholder:text-black-50 text-white`}
          />
          <TbSearch className="text-white text-lg absolute left-0.5 top-4" />
        </div>
        {/**Search Bar ==================== */}

        <div className="w-full h-[calc(100%-10rem)] overflow-hidden z-[9] bg-transparen">
          <ul
            className="h-full w-full gap-1 overflow-hidden overflow-y-scroll text-white
             text-xs font-semibold no-scrollbar no-scrollbar::-webkit-scrollbar"
          >
            {allCannedRes.length >= 1 &&
              allCannedRes.map(
                (
                  template: {
                    id: string;
                    message: string;
                    scope: string;
                    name: string;
                  },
                  index
                ) => {
                  return (
                    <li
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "enter") {
                          selectCanned(template);
                        }
                      }}
                      className={`capitalize hover:!text-blue-500 p-1 pr-4 overflow-hidden overflow-ellipsis 
                      whitespace-nowrap h-8 cursor-pointer relative w-full flex items-center text-xs font-sans 
                      font-medium gap-2 transition-all cannedResponseList ${
                        template?.name
                          ?.toLowerCase()
                          .replace(/\s/g, "")
                          .includes(
                            cannedSearch?.toLowerCase().replace(/\s/g, "")
                          )
                          ? ""
                          : "hidden"
                      }  ${focusIndex === index ? "text-blue-500" : ""}`}
                      key={index}
                    >
                      <TbHash className="text-lg text-black-200" />
                      <span
                        onClick={() => selectCanned(template)}
                        title=""
                        className="w-[calc(100%-3rem)] whitespace-nowrap truncate"
                      >
                        {template.name}
                      </span>
                      <div className="absolute right-0 flex items-center space-x-2">
                        <button
                          title="Move to public"
                          type="button"
                          onClick={() => {
                            newPublicCannedRes(
                              template?.name,
                              template?.message
                            );
                            deleteCannedRes(template.id, user[0]?.id);
                            dispatch(
                              updateAlert([
                                ...alerts,
                                {
                                  message: "Moved to public",
                                  color: "bg-green-200",
                                  id: new Date().getTime(),
                                },
                              ])
                            );
                          }}
                          className={`hidden outline-none fucus:outline-none text-blue-500 border border-blue-500
                               h-8 w-8 rounded bg-white/40 justify-center items-center shadow-lg ${
                                 template?.scope === "public" ? "hidden" : ""
                               }`}
                        >
                          <BiRightArrowAlt className="text-lg" />
                        </button>
                        <button
                          type="button"
                          disabled={
                            template?.scope === "public" &&
                            user[0]?.access !== "admin"
                              ? true
                              : false
                          }
                          onClick={() => {
                            if (template?.scope === "public") {
                              deletePublicCannedRes(template.id);
                            } else {
                              deleteCannedRes(template.id, user[0]?.id);
                            }
                            dispatch(
                              updateAlert([
                                ...alerts,
                                {
                                  message: "Macro Deleted succesfully",
                                  color: "bg-green-200",
                                  id: new Date().getTime(),
                                },
                              ])
                            );
                          }}
                          className="hidden outline-none fucus:outline-none text-red-600 border border-red-600 
                          h-8 w-8 rounded bg-white/40 justify-center items-center shadow-lg
                           disabled:cursor-not-allowed disabled:opacity-80"
                        >
                          <BiTrash />
                        </button>
                      </div>
                    </li>
                  );
                }
              )}
            {allCannedRes.length <= 0 && (
              <>
                <h2 className="mt-8 text-center text-lg gap-2">
                  You have no canned messages
                </h2>
                <p className="text-center font-medium px-4 mt-2 text-black-100">
                  Create canned responses to speed up and standardize your
                  responses.
                </p>
              </>
            )}
          </ul>
        </div>

        {/**Bottom Name*/}
        <div className="w-full h-20 border-t border-black-300/50 py-2 overflow-hidden">
          <button
            onClick={() => {
              dispatch(openNewCannedResponse(true));
              setRes(false)
              document.body.style.overflow = "hidden";
            }}
            type="button"
            className="w-full h-8 flex items-center gap-2 text-white hover:text-blue-500 transition-all"
          >
            <MdAddCircle className="text-xl whitespace-nowrap truncate" />{" "}
            <span className="text-xs">New canned response</span>
          </button>
          <div className="w-full h-8 flex items-center justify-between gap-2 text-white hover:opacity-70 transition-all">
            <div className="h-full w-fit flex items-center gap-2">
              <MdShortcut className="text-xl" />{" "}
              <span className="text-xs whitespace-nowrap truncate capitalize">
                open shortcut
              </span>
            </div>
            <div className="h-full w-fit flex items-center gap-1">
              <kbd
                className="text-[0.65rem] h-6 px-2 flex items-center justify-center rounded bg-black-700/50
              text-white font-bold whitespace-nowrap truncate"
              >
                forward slash
              </kbd>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CannedResponses;
