import { FC } from "react";
import { MdOutlineClose } from "react-icons/md";
import TopDetails from "./TopDetails";
import EditableFields from "./EditableFields";
import AdditionalDetails from "./AdditionalDetails";
import { useClickOutside } from "@mantine/hooks";

type Props = {
  setDetails: any;
  showDetails: any;
  openTicket: any;
};

const Details: FC<Props> = ({ showDetails, setDetails, openTicket }) => {
  const overlayfRef = useClickOutside(()=>{
    setDetails(false)
  })

  //Component ====================
  return (
    <div
      ref={overlayfRef}
      className={`fixed top-0 bottom-0 z-[999999] flex flex-col w-full md:w-[27rem] h-full dark:bg-black-700
       bg-black-100 backdrop-blur-sm shadow-2xl p-4 ${
         showDetails === true
           ? "right-0 left-0 md:left-[calc(100%-27rem)]"
           : "left-[200%] -right-[200%]"
       } transition-all overflow-hidden gap-4 md:border-l-2 border-black-300 dark:border-black-600`}
    >
      <div className="w-full flex justify-between items-center">
        <span className="text-black-700 dark:text-black-200 dark:text-black-black-200 text-sm font-bold uppercase">
          Ticket Details
        </span>
        <button
          onClick={() => setDetails(false)}
          className="h-7 w-7 bg-black-50 dark:bg-black-800 border border-black-300 dark:border-black-600 rounded
         flex justify-center items-center text-black-600 dark:text-black-200"
        >
          <MdOutlineClose />
        </button>
      </div>

      <div
        className="w-full h-[calc(100%-3rem)] flex flex-col gap-4 overflow-hidden overflow-y-scroll no-scrollbar
          no-scrollbar::-webkit-scrollbar"
      >
        {/**Top Details ========================== */}
        <TopDetails openTicket={openTicket} />
        {/**Top Details ========================== */}

        {/**Updatable Fields ========================== */}
        <span className="text-black-600 dark:text-black-300 dark:text-black-black-200 
        text-xs font-semibold uppercase">
          Editable details
        </span>
        <EditableFields openTicket={openTicket} />
        {/**Updatable Fields ========================== */}

        {/**Additional Fields ========================== */}
        <span className="text-black-600 dark:text-black-300 dark:text-black-black-200 text-xs
         font-semibold uppercase">
          Additional Info
        </span>
        <AdditionalDetails openTicket={openTicket} />
        {/**Additional Fields ========================== */}
      </div>
    </div>
  );
};

export default Details;
