import { notifications } from "@mantine/notifications";
import {
  addReply,
  changeStatus,
  reOpenTicket,
  resolveTicket,
} from "../../../APIs & Data/TicketsnUserData";
import { toUpper } from "../../../Reusable Functions/Reusable_Func";
import { default_reply_template } from "./Reply_Templates";
import { auth, getToken } from "../../../APIs & Data/Firebase";

//Send Reply Function ========================
export const sendReplyFunc = async (
  e: React.SyntheticEvent,
  setSending: any,
  openTicket: any,
  email_accounts: any,
  user: any,
  company_details: any,
  replyObj: any,
  clearDataOnSend: any,
  uploadingStatus: boolean,
  token: string
) => {
  e.preventDefault();
  setSending(true);
  if (new Blob([JSON.stringify(openTicket)])?.size <= 1000000) {
    //Sending Account ========================
    const sendingAccount = email_accounts?.find(
      (account: any) =>
        account.name.toLowerCase() === openTicket?.team.toLowerCase()
    );

    const new_token = await getToken();

    //Send Email Func =================================
    const sendEmail = async (replyObj: any) => {
      //Send Email If Sender Is not Client
      if (user?.access !== "client") {
        await fetch(`https://mail.basedesk.co.za/send`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            from: `${sendingAccount?.email}`,
            company: `${sendingAccount.name}`,
            password: sendingAccount.password,
            host: sendingAccount.host,
            port: sendingAccount.port,
            cc: openTicket["carbon copy"] ?? "",
            to: openTicket["recipient email"]?.replace(/\\/gim, ","),
            subject: `${toUpper(openTicket?.category)} || Ticket-ID: ${
              openTicket?.ticket_id
            }`,
            ticket_id: openTicket?.ticket_id,
            email_body: default_reply_template(
              company_details.name,
              openTicket?.ticket_id,
              replyObj,
              openTicket?.company,
              `${sendingAccount.name}`
            ),
            token: new_token || token,
            uid: auth?.currentUser?.uid,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            const resData = data;
            if (resData.status === "success") {
              notifications.show({
                title: "Email sent Successfully",
                message: "Email sent Successfully",
                color: "green",
              });
            } else if (resData.status === "fail") {
              notifications.show({
                title: "Email Failed To Send",
                message: "Email Failed To Send",
                color: "red",
              });
            }
          })
          .catch(() => {
            notifications.show({
              title: "Failed to send email, check your network",
              message: "Failed to send email, check your network",
              color: "red",
            });
          });
      }
    };

    //Check There are Files Being Uploaded
    if (uploadingStatus) {
      notifications.show({
        title: "Still Uploading Files Please wait",
        message: "Still Uploading Files Please wait",
        color: "yellow",
      });
    } else if (!uploadingStatus) {
      //Update Func
      const openRegularFunc = () => {
        addReply(
          {
            ...replyObj,
            thread_size: Number(openTicket.thread_size) + 1,
            "1st time response":
              Number(openTicket.thread_size) >= 1
                ? new Date().getTime() - Number(openTicket.date||0)
                : openTicket["1st time response"] ?? "",
          },
          openTicket
        )
          .then(() => {
            changeStatus(openTicket?.id, replyObj?.status);
            sendEmail(replyObj);
            clearDataOnSend();
            setSending(false);
          })
          ?.catch((err: any) => {
            setSending(false);
            notifications.show({
              title: "We ran into an error",
              message: err?.message,
              color: "red",
            });
          });
      };

      try {
        switch (replyObj?.status) {
          case "open":
            openRegularFunc();
            break;
          case "pending":
            openRegularFunc();
            break;
          case "reopened":
            openRegularFunc();
            reOpenTicket(openTicket?.id);
            break;
          case "closed":
            resolveTicket(
              openTicket?.id,
              openTicket?.date,
              replyObj?.message,
              replyObj?.status,
              user
            );
            sendEmail(replyObj);
            clearDataOnSend();
            setSending(false);
            break;
          case "solved":
            resolveTicket(
              openTicket?.id,
              openTicket?.date,
              replyObj?.message,
              replyObj?.status,
              user
            );
            sendEmail(replyObj);
            clearDataOnSend();
            setSending(false);
            break;
          default:
            openRegularFunc();
        }
      } catch {
        setSending(false);
        notifications.show({
          title: "We ran into an error",
          message: "We had an error updating your ticket, check your network",
          color: "red",
        });
      }
    }
  } else {
    setSending(false);
    notifications.show({
      title: "We ran into an error",
      message:
        "Max number of interactions reached, Consider opening a new ticket",
      color: "red",
    });
  }
};
