import { FC } from "react";
import { BiCategoryAlt, BiHash, BiPulse, BiTagAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { updateChatDataFilters } from "../../../Redux/Slices/Tickets_n_Contacts_Slice";
import { RootState } from "../../../Redux/store";
import AgentsFilter from "./AgentgsFilter";
import CompanyFilter from "./CompanyFilter";

interface Props {
  filtersSelectRef: any;
  filtersSelect2Ref: any;
  filtersSelect3Ref: any;
}

const Filters: FC<Props> = ({
  filtersSelectRef,
  filtersSelect2Ref,
  filtersSelect3Ref,
}) => {
  const dispatch = useDispatch();
  const categories = useSelector(
    (state: RootState) => state.Tickets.categories
  );
  const chatDataFilters = useSelector(
    (state: RootState) => state.Tickets.chatDataFilters
  );

  //Component ==============================
  return (
    <>
      <div className="col-span-1 h-10 dark:bg-slate-900 bg-slate-100 w-full min-w-[15rem] lg:min-w-0 
      flex items-center rounded-sm relative">
        <BiCategoryAlt className="text-slate-700 dark:text-slate-400 text-lg absolute h-10 left-3" />
        <select
          ref={filtersSelect3Ref}
          onChange={(e) => {
            dispatch(
              updateChatDataFilters({
                ...chatDataFilters,
                category: e.target.value,
              })
            );
          }}
          defaultValue={chatDataFilters?.category}
          className="selectFilters"
        >
          <option value="">Category</option>
          {categories.length >= 1 &&
            categories?.map((category, index) => (
              <option key={index} className="capitalize" value={category?.name}>
                {category?.name}
              </option>
            ))}
        </select>
      </div>

      <div className="col-span-1 h-10 dark:bg-slate-900 bg-slate-100 w-full min-w-[15rem] lg:min-w-0
       flex items-center rounded-sm relative">
        <BiPulse className="text-slate-700 dark:text-slate-400 text-lg absolute h-10 left-3" />
        <select
          ref={filtersSelect2Ref}
          onChange={(e) => {
            dispatch(
              updateChatDataFilters({
                ...chatDataFilters,
                status: e.target.value,
              })
            );
          }}
          defaultValue={chatDataFilters?.status}
          className="selectFilters"
        >
          <option value="">Status</option>
          {[
            "open",
            "solved",
            "closed",
            "pending",
            "reopened",
            "first contact resolution",
          ].map((opt) => {
            return (
              <option key={opt} value={opt}>
                {opt}
              </option>
            );
          })}
        </select>
      </div>
      <div className="col-span-1 h-10 dark:bg-slate-900 bg-slate-100 w-full min-w-[15rem] lg:min-w-0 
      flex items-center rounded-sm relative">
        <BiTagAlt className="text-slate-700 dark:text-slate-400 text-lg absolute h-10 left-3" />
        <select
          ref={filtersSelectRef}
          onChange={(e) => {
            dispatch(
              updateChatDataFilters({
                ...chatDataFilters,
                priority: e.target.value,
              })
            );
          }}
          defaultValue={chatDataFilters?.priority}
          className="selectFilters"
        >
          <option value="">Priority</option>
          <option value="">priority</option>
          {["low", "medium", "high", "urgent"].map((opt) => {
            return (
              <option key={opt} value={opt}>
                {opt}
              </option>
            );
          })}
        </select>
      </div>
      <AgentsFilter />
      <CompanyFilter />
      <div className="col-span-1 h-10 dark:bg-slate-900 bg-slate-100 w-full min-w-[15rem] lg:min-w-0 
      flex items-center rounded-sm relative">
        <BiHash className="text-slate-700 dark:text-slate-400 text-lg absolute h-10 left-3" />
        <input
          type="search"
          autoComplete="off"
          name="ticket_id"
          id="ticket_id"
          onChange={(e) => {
            dispatch(
              updateChatDataFilters({
                ...chatDataFilters,
                ticket_id: e.target.value,
              })
            );
          }}
          value={chatDataFilters?.ticket_id}
          className="selectFilters"
          placeholder="Ticket-ID ..."
        />
      </div>
    </>
  );
};

export default Filters;
