import { FC, useEffect, useState } from "react";
import { TbListSearch, TbChevronDown, TbListDetails } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useClickOutside } from "@mantine/hooks";
import { RootState } from "../../Redux/store";
import { updateAlert } from "../../Redux/Slices/NotificationsSlice";

type Props = {
  element: any;
  inputStyles: any;
  passedList: any[];
  hasDefault: boolean;
  setValues: any;
  inputValue: any;
  clear: any;
  setClear: any;
};

//Multiple  Select
const MultiSelect: FC<Props> = ({
  element,
  inputStyles,
  passedList,
  setValues,
  inputValue,
  clear,
  setClear,
}) => {
  const dispatch = useDispatch();
  const alerts = useSelector(
    (state: RootState) => state.NotificationsData.alerts
  );
  const allMembers = useSelector(
    (state: RootState) => state.UserInfo.allMembers
  );
  const [showDropDown, setShow] = useState<boolean>(false);
  const multiListModalRef = useClickOutside(() => setShow(false));
  const [selected, setSelected] = useState<any>(
    inputValue[element.name]?.length >= 1 ? inputValue[element.name] : []
  );
  const [filteredList, setList] = useState<any[]>([]);
  const [typedValue, setTypedValue] = useState<string | any>("");

  //Update Initial Value On Clear Or Submit
  useEffect(() => {
    if (clear) {
      setTypedValue("");
      setSelected(
        inputValue[element.name]?.length >= 1 ? inputValue[element.name] : []
      );
      setClear(false);
    }
  }, [clear, element.name, inputValue, setClear]);

  //Update List
  useEffect(() => {
    setList(
      passedList
        ? passedList?.filter(
            (data: any) => data?.hidden_property?.access !== "client"
          )
        : []
    );
  }, [passedList]);

  //Update Values On Change
  useEffect(() => {
    if (inputValue[element.name] !== selected) {
      setValues((prev: any) => ({
        ...prev,
        [element.name]: selected,
      }));
    }
  }, [element.name, selected, inputValue, setValues]);

  //Component ======
  return (
    <div
      onMouseLeave={() => {
        if (
          [...passedList]?.filter(
            (data) =>
              data?.name
                ?.toLowerCase()
                ?.replace(/\s/gi, "")
                ?.includes(typedValue?.toLowerCase()?.replace(/\s/gi, "")) ||
              data?.value
                ?.toLowerCase()
                ?.replace(/\s/gi, "")
                ?.includes(typedValue?.toLowerCase()?.replace(/\s/gi, ""))
          ).length <= 0
        ) {
          setTypedValue("");
        } else if (selected.length >= 0) {
          setTypedValue("");
        } else {
          setTypedValue("");
        }
      }}
      ref={multiListModalRef}
      className="relative"
    >
      <div
        onClick={() => {
          setShow(true);
        }}
        className="w-full h-full flex flex-nowrap justify-between items-center whitespace-nowrap relative cursor-text overflow-hidden"
      >
        <div className="w-full h-full flex overflow-hidden overflow-x-scroll no-scrollbar::-webkit-scrollbar no-scrollbar">
          {/**Chips Field ======================== */}
          {selected?.length >= 1 &&
            selected?.map((item: string) => {
              return (
                <div
                  key={item}
                  className={`ml-2 mt-0.5 w-fit h-fit p-0.5 rounded border border-black-300 dark:border-black-600 flex justify-between items-center text-[.7rem] font-medium text-black-700 dark:text-black-300 bg-black-50 dark:bg-black-700 cursor-pointer ${
                    element.name === "collaborators"
                      ? "lowercase"
                      : "capitalize"
                  }`}
                >
                  <span className="h-full px-2 border-r border-black-300 dark:border-black-600">
                    {item}
                  </span>
                  {/**Clear field ======= */}
                  <button
                    onClick={() => {
                      setSelected(
                        (prev: any) =>
                          prev?.filter((data: string) => data !== item)
                      );
                    }}
                    type="button"
                    className="focus:outline-none outline-none h-full px-1 text-xs"
                  >
                    <abbr title="clear">&times;</abbr>
                  </button>
                </div>
              );
            })}

          {/**Input Field ======================== */}
          <input
            onFocus={() => {
              setShow(true);
            }}
            onKeyDown={(e) => {
              setShow(true);
              if (["Enter", "Tab", ",", "/", "Space"].includes(e.key)) {
                e.preventDefault();
                if (
                  selected?.filter(
                    (elem: string) =>
                      elem?.replace(/\s/gi, "")?.toLowerCase() ===
                      typedValue?.replace(/\s/gi, "")?.toLowerCase()
                  ).length >= 1
                ) {
                  dispatch(
                    updateAlert([
                      ...alerts,
                      {
                        message: "Duplicates are not allowed",
                        id: new Date().getTime(),
                        color: "bg-yellow-200",
                      },
                    ])
                  );
                } else {
                  selected?.length <= 0
                    ? setSelected([typedValue])
                    : setSelected((prev: any) => [...prev, typedValue]);
                  setTypedValue("");
                }
              }
            }}
            type="text"
            autoComplete="off"
            required={element?.required}
            name={element?.name}
            id={element?.name}
            placeholder={element?.placeholder}
            className={`${selected.length >= 1 && "!pl-1"} ${inputStyles}`}
            value={typedValue}
            onChange={(e) => {
              setTypedValue(e.target.value);
              setList(
                [...passedList]
                  ?.filter(
                    (data) =>
                      data?.name
                        ?.toLowerCase()
                        ?.replace(/\s/gi, "")
                        ?.includes(
                          e.target.value?.toLowerCase()?.replace(/\s/gi, "")
                        ) ||
                      data?.value
                        ?.toLowerCase()
                        ?.replace(/\s/gi, "")
                        ?.includes(
                          e.target.value?.toLowerCase()?.replace(/\s/gi, "")
                        )
                  )
                  ?.filter(
                    (data: any) => data?.hidden_property?.access !== "client"
                  )
              );
            }}
          />
        </div>
        <div className="absolute right-0 -top-0.5 bottom-0  h-full w-7 bg-inherit flex items-center justify-center">
          <TbChevronDown className="text-base text-black-400 dark:text-black-500" />
        </div>
      </div>

      {/**drop down */}
      <div
        className={`absolute top-[100%] w-full bg-transparent z-[999] pt-1.5 ${
          showDropDown ? "" : "!hidden"
        }`}
      >
        <div
          className={`min-h-[2.5rem] w-full dark:bg-black-800 bg-white rounded-md p-2 px-3 space-y-1 border 
          dark:border-black-700 border-black-400 shadow-2xl drop-shadow-2xl transition-all`}
        >
          {filteredList?.length <= 0 && (
            <div className="font-medium text-xs text-black-600 dark:text-black-500 h-full pt-0.5 flex items-center space-x-2 px-2">
              <TbListSearch className="text-lg" /> <span>No Results Found</span>
            </div>
          )}
          {filteredList?.length >= 1 && (
            <ul
              className="w-full h-full max-h-[16rem] p-1 px-2 overflow-hidden overflow-y-scroll space-y-1
                no-scrollbar no-scrollbar::-webkit-scrollbar"
            >
              {filteredList?.length >= 1 &&
                filteredList?.slice(0, 30)?.map((item, index) => {
                  return (
                    <li
                      key={item?.value + index}
                      onClick={() => {
                        setShow(false);
                        if (
                          selected?.filter(
                            (elem: string) =>
                              elem?.replace(/\s/gi, "")?.toLowerCase() ===
                              item.name?.replace(/\s/gi, "")?.toLowerCase()
                          ).length >= 1
                        ) {
                          dispatch(
                            updateAlert([
                              ...alerts,
                              {
                                message: "Duplicates are not allowed",
                                id: new Date().getTime(),
                                color: "bg-yellow-200",
                              },
                            ])
                          );
                        } else {
                          setSelected((prev: any) => [
                            ...prev,
                            element?.name === "collaborators"
                              ? allMembers?.filter(
                                  (data: any) => data?.name === item.name
                                )[0]?.email
                              : item.name,
                          ]);
                        }
                      }}
                      className={`h-9 w-full font-medium capitalize text-xs text-black-600 dark:text-black-300 flex items-center space-x-2 px-2 hover:bg-black-200 dark:hover:bg-black-700 rounded cursor-pointer ${
                        selected?.some((data: any) => data === item?.value) ||
                        selected?.some((data: any) => data === item?.name)
                          ? "bg-black-200 dark:bg-black-700"
                          : ""
                      }`}
                    >
                      {element.name === "collaborators" ? (
                        <div className="h-fit w-fit relative border-2 border-black-500 rounded-full">
                          <div
                            className={`w-2 h-2 rounded-full border border-white dark:border-black-700 absolute -right-0.5 -top-0.5 ${
                              item?.hidden_property?.status === "available"
                                ? "bg-green-600"
                                : item?.hidden_property?.status ===
                                  "unavailable"
                                ? "bg-red-600"
                                : "bg-yellow-500"
                            }`}
                          ></div>
                          <img
                            onError={(e) => {
                              e.currentTarget.src = "/logo/basedesk.png";
                            }}
                            src={
                              item?.hidden_property?.photoUrl ??
                              "/logo/basedesk.png"
                            }
                            alt="profile"
                            className="h-6 w-6 rounded-full object-covero object-center"
                          />
                        </div>
                      ) : (
                        <TbListDetails className="text-base" />
                      )}
                      <span className="whitespace-nowrap overflow-ellipsis overflow-hidden">
                        {item?.name}
                      </span>
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiSelect;
