import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialStateType {
  date: any;
  dashboard_stats_data: any;
  other_data: any;
}

export const localDashboardDates = (() => {
  const data = localStorage.getItem("dashboard_dates");
  return data
    ? JSON.parse(data)
    : {
        start: new Date().getTime() - 172800000,
        end: new Date().getTime(),
      };
})();

const initialState: InitialStateType = {
  date: localDashboardDates,
  dashboard_stats_data: (() => {
    const data = localStorage.getItem("dashboard_stats_data");
    return data
      ? JSON.parse(data)
      : [
          { index: 1, name: "open tickets", value: 0, compare_value: 0 },
          { index: 2, name: "pending tickets", value: 0, compare_value: 0 },
          { index: 3, name: "closed tickets", value: 0, compare_value: 0 },
          { index: 4, name: "solved tickets", value: 0, compare_value: 0 },
          { index: 5, name: "total tickets", value: 0, compare_value: 0 },
          { index: 6, name: "resolution time", value: 0, compare_value: 0 },
        ];
  })(),
  other_data: (() => {
    const data = localStorage.getItem("dashboard_other_data");
    return data
      ? JSON.parse(data)
      : [
          { categories: [] },
          { "assignee email": [] },
          { origin: [] },
          { hourly: [] },
          { monthly: [] },
          { weekly: [] },
        ];
  })(),
};

export const DashboardSlice = createSlice({
  name: "DashboardData",
  initialState,
  reducers: {
    changeDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
      window.localStorage.setItem(
        "dashboard_dates",
        JSON.stringify(action.payload)
      );
    },
    updateDashboardStatsData: (
      state,
      action: PayloadAction<{
        index: number;
        name: string;
        value: number;
        compare_value: number;
      }>
    ) => {
      state.dashboard_stats_data = [
        ...[...state.dashboard_stats_data]?.filter(
          (data: any) => data?.name !== action.payload?.name
        ),
        action.payload,
      ]?.sort((a: any, b: any) => a.index - b.index);
      window.localStorage.setItem(
        "dashboard_stats_data",
        JSON.stringify(
          [
            ...[...state.dashboard_stats_data]?.filter(
              (data: any) => data?.name !== action.payload?.name
            ),
            action.payload,
          ]?.sort((a: any, b: any) => a.index - b.index)
        )
      );
    },
    updateOtherStatsData: (state, action: PayloadAction<any>) => {
      state.other_data = { ...state.other_data, ...action.payload };
      window.localStorage.setItem(
        "dashboard_other_data",
        JSON.stringify({ ...state.other_data, ...action.payload })
      );
    },
  },
});

export const { changeDate, updateDashboardStatsData, updateOtherStatsData } =
  DashboardSlice.actions;

export default DashboardSlice.reducer;
