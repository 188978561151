import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//import { queueData } from "../../Pages/Dashboard/Queue n Calls/Queue/DemoData";

interface InitialInterface {
  queue: any[];
  filteredQueue: any[];
}

const initialState: InitialInterface = {
  queue: [],
  filteredQueue: [],
};

const QueueSlice = createSlice({
  name: "QueueData",
  initialState,
  reducers: {
    updateQueue: (state, action: PayloadAction<any[]>) => {
      state.queue = action.payload;
    },
    filterQueue: (state, action: PayloadAction<any[]>) => {
      state.filteredQueue = action.payload;
    },
  },
});
export const { updateQueue, filterQueue } = QueueSlice.actions;

export default QueueSlice.reducer;
