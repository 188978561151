const Loader = () => {
  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0 z-[99999999] bg-black-100 flex
        flex-col items-center justify-center overflow-hidden"
    >
      <img
        className="mx-auto h-16 w-16 rounded-full shadow-xl shadow-blue-500/40"
        src="/logo/animated.gif"
        alt="Basedesk logo"
      />

      <span className="text-xs font-bold text-black-800 mt-8 uppercase">
        loading please wait ...
      </span>
    </div>
  );
};

export default Loader;
