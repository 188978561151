import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { updateAlert } from "../../Redux/Slices/NotificationsSlice";

type Props = {
  element: any;
  inputStyles: any;
  setValues: any;
  inputValue: any;
  clear: any;
  setClear: any;
};

//Multiple  Select
const TextMultiple: FC<Props> = ({
  element,
  inputStyles,
  setValues,
  inputValue,
  clear,
  setClear,
}) => {
  const dispatch = useDispatch();
  const alerts = useSelector(
    (state: RootState) => state.NotificationsData.alerts
  );
  const [typedValue, setTypedValue] = useState<string>("");
  const [array, setArray] = useState<any[]>(
    inputValue[element.name]?.length >= 1 ? inputValue[element.name] : []
  );

  //Update Initial Value On Clear Or Submit
  useEffect(() => {
    if (clear) {
      setTypedValue("");
      setArray(
        inputValue[element.name]?.length >= 1 ? inputValue[element.name] : []
      );
      setClear(false);
    }
  }, [clear, element.name, inputValue, setClear]);

  //Update Values On Change
  useEffect(() => {
    if (inputValue[element.name] !== array) {
      setValues((prev: any) => ({
        ...prev,
        [element.name]: array,
      }));
    }
  }, [element.name, array, inputValue, setValues]);

  //Component ======
  return (
    <div
      onMouseLeave={() => {
        if (
          array?.filter(
            (value: string) =>
              value?.replace(/\s/gi, "")?.toLowerCase() ===
              typedValue?.replace(/\s/gi, "")?.toLowerCase()
          ).length >= 1
        ) {
          dispatch(
            updateAlert([
              ...alerts,
              {
                message: "Duplicates are not allowed",
                id: new Date().getTime(),
                color: "bg-yellow-200",
              },
            ])
          );
        } else if (typedValue.length >= 4) {
          setArray((prev: any) => [...prev, typedValue]);
          setTypedValue("");
        } else {
          setTypedValue("");
        }
      }}
      className="w-full h-full flex overflow-hidden overflow-x-scroll no-scrollbar::-webkit-scrollbar no-scrollbar"
    >
      {array?.map((value: string) => {
        return (
          <div
            key={value}
            className="ml-2 mt-0.5 w-fit h-fit p-0.5 rounded border border-black-300 dark:border-black-600 flex justify-between items-center text-[.7rem] font-medium text-black-700 dark:text-black-300 bg-black-50 dark:bg-black-700 cursor-pointer"
          >
            <span className="h-full px-2 border-r border-black-300 dark:border-black-600 lowercase">
              {value}
            </span>
            <button
              onClick={() => {
                setArray((prev: any) =>
                  prev?.filter((data: string) => data !== value)
                );
              }}
              className="focus:outline-none outline-none h-full px-1 text-xs"
            >
              <abbr title="clear">&times;</abbr>
            </button>
          </div>
        );
      })}
      <input
        onKeyDown={(e) => {
          if (["Enter", "Tab", ",", "/", "Space"].includes(e.key)) {
            e.preventDefault();
            if (
              array?.filter(
                (value: string) =>
                  value?.replace(/\s/gi, "")?.toLowerCase() ===
                  typedValue?.replace(/\s/gi, "")?.toLowerCase()
              ).length >= 1
            ) {
              dispatch(
                updateAlert([
                  ...alerts,
                  {
                    message: "Duplicates are not allowed",
                    id: new Date().getTime(),
                    color: "bg-yellow-200",
                  },
                ])
              );
            } else {
              setArray((prev: any) => [...prev, typedValue]);
              setTypedValue("");
            }
          }
        }}
        onChange={(e) => {
          setTypedValue(e.target.value);
        }}
        autoComplete="off"
        value={typedValue}
        type="text"
        name={element.name}
        id={element.name}
        required={element?.required}
        placeholder={element.placeholder}
        className={`${inputStyles} ${array?.length === 4 ? "hidden" : ""}`}
      />
    </div>
  );
};

export default TextMultiple;
