import { FC} from "react";
import ExpandedChat from "./Thread/ExpandedChat";
import NewTicket from "./New Ticket/NewTicket";
import NewCanned from "./Macros/NewCanned";
import ListContainer from "./Ticket List/ListContainer";

const TicketsComponent: FC = () => {

  //Component ======================
  return (
    <div className="bg-transparent w-full h-full flex overflow-hidden">
      {/**New Ticket Form ====================================== */}
      <NewTicket />

      {/**New Canned Response Modal */}
      <NewCanned
      />
      {/**New Canned Response Modal */}

      <ListContainer />
      {/**=========================== Expandend Chat ===================================== */}
      <ExpandedChat  />
    </div>
  );
};

export default TicketsComponent;
