import { useState } from "react";
import { RiLightbulbFlashLine } from "react-icons/ri";

const Placeholder = () => {
  const [viewingIndex, setIndex] = useState<number>(0);
  const tipsArray = [
    `You can save message templates to use later through
      a feature called micros ?, just press control (ctrl) + letter (M) to get started`,
    `You can quickly open a a new ticket from anywhere but just pressing control
       (ctrl) + letter (N) to open or close the modal`,
    `If you want to perform bulk action(s) on your ticket like deleting, assigning or changing
     status you can click the first circle on the right of each ticket which will mark/select the ticket`,
  ];
  //Component
  return (
    <div className="w-full h-full flex flex-col items-center justify-center p-4">
      <div
        className="h-12 w-12 flex items-center justify-center rounded-full bg-black-100
         dark:bg-black-700 border border-zinc-200 dark:border-zinc-600
          shadow-lg shadow-blue-600/30"
      >
        <RiLightbulbFlashLine className="text-2xl text-black-600 dark:text-black-200" />
      </div>

      {/** =========== Didi you knwo slide =================== */}
      <span className="mt-4 text-lg font-bold text-black-700 dark:text-black-100 text-center">
        Did you know ?
      </span>
      <div className="mt-3 flex items-center gap-1 w-[20rem] overflow-hidden">
        {tipsArray.map((tip: string, index: number) => {
          return (
            <p
              key={index}
              className={`w-full text-center text-xs text-black-600 dark:text-zinc-400 ${
                viewingIndex === index ? "flex" : "hidden"
              }`}
            >
              {tip}
            </p>
          );
        })}
      </div>

      {/** ====================Control ====================*/}
      <div className="mt-6 flex items-center justify-center gap-2 max-w-[20rem]">
        {Array.from({ length: tipsArray?.length }).map(
          (item: any, index: number) => {
            return (
              <button
                type="button"
                onClick={() => {
                  setIndex(index);
                }}
                key={index}
                className={`h-3 w-3 rounded-full border border-black-300 dark:border-black-600 ${
                  index === viewingIndex
                    ? "bg-black-300 dark:bg-black-500"
                    : "bg-black-50 dark:bg-black-700"
                }`}
              ></button>
            );
          }
        )}
      </div>

      <button
        type="button"
        className="mt-6 h-9 w-32 rounded-full bg-black-800 dark:bg-blue-600 text-white
         text-xss font-medium uppercase shrink-0 hover:bg-black-500 transition-all"
      >
        view docs
      </button>
    </div>
  );
};

export default Placeholder;
