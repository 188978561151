export const NewTicketFeilds = [
  {
    type: "dropdown",
    placeholder: "Select Asignee",
    required: true,
    visibility: "show",
    name: "assignee name",
    visible_name: "Assignee",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "assignee email",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "assignee id",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "assigner email",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "requester email",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "requester name",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "dropdown",
    placeholder: "Find contact",
    required: true,
    visibility: "show",
    name: "recipient name",
    visible_name: "To",
    hasDefault: false,
  },
  {
    type: "text",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "recipient email",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "recipient twitter_id",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "recipient whatsapp_number",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "recipient slack_id",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "recipient messanger_id",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "recipient insta_id",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "tel",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "recipient phone",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "company",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "dropdown",
    placeholder: "Category",
    required: true,
    visibility: "show",
    name: "category",
    visible_name: "Category",
    hasDefault: false,
  },
  {
    type: "text_multiple",
    placeholder: "Enter emails",
    required: false,
    visibility: "show",
    name: "carbon copy",
    visible_name: "cc",
    hasDefault: true,
  },
  {
    type: "dropdown",
    placeholder: "Add collaborators",
    required: false,
    visibility: "show",
    name: "collaborators",
    visible_name: "collaborators",
    hasDefault: false,
    multiple: true,
  },
  {
    type: "number",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "thread_size",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "number",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "date",
    visible_name: "date",
    hasDefault: true,
  },
  {
    type: "number",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "close date",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "number",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "due_date",
    visible_name: "due_date",
    hasDefault: true,
  },
  {
    type: "number",
    placeholder: "none",
    required: true,
    visibility: "none",
    name: "1st time response",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "dropdown",
    placeholder: "Status",
    required: true,
    visibility: "show",
    name: "status",
    visible_name: "Status",
    hasDefault: true,
  },
  {
    type: "dropdown",
    placeholder: "Priority",
    required: true,
    visibility: "show",
    name: "priority",
    visible_name: "Priority",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "Description",
    required: true,
    visibility: "show",
    name: "description",
    visible_name: "description",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "",
    required: true,
    visibility: "none",
    name: "resolution",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "",
    required: true,
    visibility: "none",
    name: "resolved_by",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "boolean",
    placeholder: "",
    required: true,
    visibility: "none",
    name: "fcr",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "boolean",
    placeholder: "",
    required: true,
    visibility: "none",
    name: "ropened",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "",
    required: true,
    visibility: "none",
    name: "ticket_id",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "dropdown",
    placeholder: "Team",
    required: true,
    visibility: "show",
    name: "team",
    visible_name: "Team",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "",
    required: true,
    visibility: "none",
    name: "origin",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "array",
    placeholder: "",
    required: true,
    visibility: "none",
    name: "read_by",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "",
    required: true,
    visibility: "none",
    name: "satisfaction_rate",
    visible_name: "",
    hasDefault: true,
  },
  {
    type: "text",
    placeholder: "Your feedback",
    required: true,
    visibility: "none",
    name: "feedback",
    visible_name: "",
    hasDefault: false,
  },
  {
    type: "number",
    placeholder: "resolution time",
    required: true,
    visibility: "none",
    name: "resolution time",
    visible_name: "",
    hasDefault: false,
  },
];
