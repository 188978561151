import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialStateType {
  settings: string[] | any[];
  company_details: string[] | any;
  roles_permissons: any;
  groups: any[];
  system_data: any[];
}

const initialState: InitialStateType = {
  company_details: [],
  settings: [],
  roles_permissons: {},
  groups: [],
  system_data: [],
};

export const SettingsSlice = createSlice({
  name: "SettingsData",
  initialState,
  reducers: {
    loadSettings: (state, action: PayloadAction<any[]>) => {
      state.settings = action.payload;
    },
    setCompanyDetails: (state, action: PayloadAction<any[]>) => {
      state.company_details = action.payload;
    },
    updatePermissions: (state, action: PayloadAction<any>) => {
      state.roles_permissons = action.payload;
    },
    loadGroups: (state, action: PayloadAction<any>) => {
      state.groups = action.payload;
    },
    loadSystemSettings: (state, action: PayloadAction<any>) => {
      state.system_data = action.payload;
    },
  },
});

export const {
  loadSettings,
  setCompanyDetails,
  updatePermissions,
  loadGroups,
  loadSystemSettings,
} = SettingsSlice.actions;

export default SettingsSlice.reducer;
