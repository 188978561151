export interface FMTypes {
    "External Reference": string,
    "Region": string,
    "CallcenterName": string,
    "Restaurant": string,
    "Franchise Manager": string,
    "FM Email": string,
    "Operations Manager": string,
    "OM Email": string

}


export const fm_list = []

export const passwordRequirements = [
    "Minimum of 8 characters",
    "At least one special character (e.g. @, #, $, %, .)",
    "At least one uppercase/capital character",
    "At least one number",
  ];