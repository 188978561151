import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Redux/store";
import { org, queueRef } from "./TicketsnUserData";
import { onSnapshot} from "firebase/firestore";
import { updateQueue } from "../Redux/Slices/QueueSlice";


const QueueData = () => {
  const user = useSelector(
    (state: RootState) => state.UserInfo.member_details
  )[0];
  const dispatch = useDispatch();

  //Update Queue ==============================================
  useEffect((): any => {
    return (
      queueRef&&org &&
      user?.access !== "client" &&
      onSnapshot(
        queueRef,
        (snapshot: { docs: any[] }) => {
          dispatch(
            updateQueue(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
        },
        (error) => {
          console.log(error?.message)
        }
      )
    );
  }, [dispatch, user]);

  return <></>;
};

export default QueueData;
