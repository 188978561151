const License = () => {
  return (
    <div className="fixed inset-0 overflow-hidden overflow-y-scroll p-4">
      <embed
        src="https://firebasestorage.googleapis.com/v0/b/dial-n-dine-help-desk.appspot.com/o/basedesk%2FBASEDESK%20SOFTWARE%20LICENSING%20AGREEMENT.pdf?alt=media&token=259907e8-5da6-4eb7-8aa4-36738347d1b7"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default License;
