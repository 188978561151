import { FC, useState } from "react";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { BiTrash } from "react-icons/bi";
import { TbFilter, TbCircleMinus } from "react-icons/tb";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteTicket,
  assignAgent,
  addNotification,
} from "../../../APIs & Data/TicketsnUserData";
import { updateAlert } from "../../../Redux/Slices/NotificationsSlice";
import OffFilters from "../Filters/OffFilters";
import { AppDispatch, RootState } from "../../../Redux/store";
import ActionPanel from "../../../Components/Misc/ActionPanel";
import { checkUrls } from "../../../Reusable Functions/Reusable_Func";
import {
  getLocal,
  markTicket,
  selectTicket,
  updateNewTicket,
} from "../../../Redux/Slices/ChatSlice";
import { RiDraftLine } from "react-icons/ri";
import AgentsList from "./AgentsList";
import { useOutletContext } from "react-router-dom";

const Navbar: FC = () => {
  const markedArray = useSelector(
    (state: RootState) => state.ChatData.markedArray
  );
  const [filtersModal, setfiltersModal] = useState<boolean | any>(false);
  const [openPanel, setActionPanel] = useState<boolean>(false);
  const user = useSelector(
    (state: RootState) => state.UserInfo.member_details
  )[0];
  const ticketFields = useSelector(
    (state: RootState) => state.Tickets.ticketFields
  );
  const customFields = useSelector(
    (state: RootState) => state.Tickets.custom_fields
  );
  const filteredChatData = useSelector(
    (state: RootState) => state.ChatData.filteredChatData
  );
  const dispatch: AppDispatch = useDispatch();
  const [newTicketModal, setModal]: any = useOutletContext();

  //Delete Selected Ticlets/Ticket ==========
  const deleteSelected = () => {
    // Create a reference to the file to delete
    console.log(newTicketModal);
    //const storage = getStorage();
    for (let i = 0; i < markedArray.length; i++) {
      const msg = filteredChatData.find(
        (data: any) => data?.id === markedArray[i]
      );
      const messagesArray = [
        ...(msg?.thread?.length >= 1
          ? msg?.thread?.map((data: any) => data?.message)
          : []),
        ...(msg?.notes?.length >= 1
          ? msg?.notes?.map((data: any) => data?.note)
          : []),
        msg?.resolution,
        msg?.description,
      ];

      let urlsArray: string[] = [];
      messagesArray.forEach((value: string) => {
        const urls = checkUrls(value);
        if (urls) {
          urlsArray = [...urlsArray, ...urls];
        }
      });
      deleteTicket(markedArray[i])
        .then(() => {
          // Initialize Firebase Storage
          const storage = getStorage();
          urlsArray?.forEach((fileUrl: any) => {
            // Get a reference to the file
            const fileRef = ref(storage, fileUrl);
            // Delete the file
            deleteObject(fileRef)
              .then(() => {
                // File deleted successfully
                console.log("File deleted successfully!");
              })
              .catch((error) => {
                // Uh-oh, an error occurred!
                console.error("Error deleting file:", error);
              });
          });
          dispatch(
            updateAlert({
              message: "Ticket Deleted",
              color: "bg-green-200",
            })
          );
        })
        .catch(() => {
          dispatch(
            updateAlert({
              message: "Failed to delete please retry",
              color: "bg-red-200",
            })
          );
        });
    }
    dispatch(markTicket([]));
    dispatch(selectTicket(null));
    window.localStorage.setItem("threadId", "");
  };

  //Assign Tickect To an Agent =================
  const assignAgents = (name: any, email: any, member_id: any) => {
    for (let i = 0; i < markedArray.length; i++) {
      assignAgent(
        markedArray[i],
        name,
        email,
        member_id,
        user?.name,
        user?.email
      );
      addNotification(
        member_id,
        "You've been assigned a Ticket",
        `Assigned a ticket with id: ${
          filteredChatData?.filter(
            (data: any) => data?.id === markedArray[i]
          )[0]?.ticket_id ?? ""
        }
         Assigned by : ${user?.name}`
      );
    }
    dispatch(markTicket([]));
    dispatch(
      updateAlert({
        message: `Ticket(s) Assigned to ${name} Successfully`,
        color: "bg-green-200",
      })
    );
  };

  //Component ======================
  return (
    <nav
      className="flex min-h-[3.5rem] h-14  justify-between items-center w-full relative bg-black-50 
      dark:bg-black-800 border-b border-black-200 dark:border-black-700 px-4 pl-1"
    >
      <div className="w-fit h-full flex items-center gap-2">
        <div className="h-full w-fit flex items-center overflow-hidden text-black-600 dark:text-black-300">
          {/**Mark All ================================ */}
          {user?.access !== "client" && (
            <button
              onClick={() => {
                if (markedArray?.length !== filteredChatData?.length) {
                  dispatch(
                    markTicket(filteredChatData?.map((data: any) => data?.id))
                  );
                } else {
                  dispatch(markTicket([]));
                }
              }}
              title="Mark All"
              className="h-full w-12 min-w-[3rem] hover:!text-blue-600 transition-all
            disabled:pointer-events-none disabled:opacity-80 disabled:cursor-not-allowed
             text-xl flex items-center justify-center"
            >
              {markedArray?.length !== filteredChatData?.length ? (
                <IoMdCheckmarkCircleOutline />
              ) : (
                <TbCircleMinus />
              )}
            </button>
          )}

          <button
            title="All filters"
            onClick={() => setfiltersModal(true)}
            className="h-full w-12 min-w-[3rem] hover:!text-blue-600 transition-all
              disabled:pointer-events-none disabled:opacity-80 disabled:cursor-not-allowed
               text-xl flex items-center justify-center"
          >
            <TbFilter />
          </button>

          {/**ssigning Agents */}
          {<AgentsList assignAgents={assignAgents} />}

          {/**Delete Ticket ================================= */}
          {user?.access !== "client" && (
            <button
              title="Delete Ticket"
              onClick={() => {
                setActionPanel(true);
              }}
              disabled={markedArray?.length <= 0}
              className="h-full w-12 min-w-[3rem] hover:!text-red-600 transition-all
              disabled:pointer-events-none disabled:opacity-50 disabled:cursor-not-allowed
               text-xl flex items-center justify-center"
            >
              <BiTrash />
            </button>
          )}
        </div>
      </div>

      {/**Open New Tickets */}
      {user?.access !== "client" && (
        <div
          className="h-full w-fit min-w-fit px-2 pl-[1.35rem] flex items-center justify-end
       border-l border-black-200 dark:border-black-700"
        >
          <button
            title="Open New Ticket"
            onClick={() => {
              //Get Draft Message From the Local Storage ==============
              const initialDraft = () => {
                const draft = localStorage.getItem("newTicketDraftValues");
                return draft
                  ? JSON.parse(draft)
                  : [...ticketFields, ...customFields]?.reduce(
                      (obj, val) => ({ ...obj, [val.name]: "" }),
                      {}
                    );
              };
              setModal(user?.name !== "User Loader" ? true : false);
              // setValues(initialDraft());
              document.body.style.overflow = "hidden";
              const editor =
                document.getElementsByClassName("newTicketEditor")[0];
              if (editor) {
                editor.innerHTML = initialDraft()?.description;
              }
              dispatch(
                updateNewTicket(
                  getLocal("newTicket") ||
                    [...ticketFields, ...customFields]?.reduce(
                      (obj, val) => ({ ...obj, [val.name]: "" }),
                      {}
                    )
                )
              );
            }}
            className="h-9 w-10 min-w-[2.5rem] rounded-sm bg-black-800 dark:bg-blue-600
         text-white text-lg font-semibold uppercase pt-0.5 border border-black-700
         hover:opacity-80 transition-all flex items-center justify-center"
          >
            <RiDraftLine />
          </button>
        </div>
      )}

      {/**Delele Ticket Action Panel ====== */}
      <ActionPanel
        openPanel={openPanel}
        setActionPanel={setActionPanel}
        deleteSelected={deleteSelected}
        option="ticket"
        showAlert={true}
      />
      {/**Delele Ticket Action Panel ====== */}

      {/**Filter Btn ============================== */}
      <OffFilters
        filtersModal={filtersModal}
        setfiltersModal={setfiltersModal}
      />
    </nav>
  );
};

export default Navbar;
