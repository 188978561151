import React, { useEffect } from "react";
import { TbArrowRight, TbEye, TbEyeOff } from "react-icons/tb";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { getPasswordStrength } from "../../Utils/reusable_functions";
import Loader from "../../Components/Misc/Loader";
import { passwordRequirements } from "../../Utils/reusable_object";
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";


const ResetPassword = () => {
  const [formdata, setData] = React.useState<any>(null);
  const [revealPassword, setReveal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode") as string;
  const navigate = useNavigate();

  useEffect(() => {
    if (!oobCode) {
      // Handle the case where the code is missing (e.g., redirect)
      toast.error("Invalid or missing reset code.");
      navigate("/login"); 
    }
  }, [oobCode, navigate]);

  //Handle Submit
  const handleSubmit = async (e:React.SyntheticEvent) => {
    e.preventDefault();

    try {
      setLoading(true);

      // Verify the reset code
      const auth = getAuth(); 
      await verifyPasswordResetCode(auth, oobCode); 

      // Confirm password reset
      await confirmPasswordReset(auth, oobCode, formdata.new_password);

      toast.success("Password reset successfully!");
      setData({});
      navigate("/login");
    } catch (error:any) {
      toast.error("Failed to reset password: " + error.message.replace(/firebase/gi, ''));
    } finally {
      setLoading(false);
    }
  };

  //HTML Content =============
  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 flex flex-col bg-zinc-100 itmes-center
       overflow-hidden overflow-y-auto p-6"
    >
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-[25rem] h-fit m-auto flex flex-col items-center"
      >
        <img
          src="/logo/maskable_icon.png"
          alt="logo"
          className="h-12 w-12 rounded-lg shadow border-2 border-white"
        />
        <h1 className="mt-6 text-lg md:text-xl font-bold text-center uppercase text-zinc-800">
          Reset password
        </h1>
        <p className="mt-3 text-xs md:text-sm text-zinc-500 font-medium text-center">
          Create a secure password to protect your account
        </p>

        <label
          htmlFor="new_password"
          className="mt-4 w-full flex flex-col gap-1 shrink-0 relative"
        >
          <input
            onChange={(e) => {
              setData((prev: any) => ({
                ...prev,
                new_password: e.target?.value,
              }));
            }}
            title="Match requirements"
            pattern="^(?=.*[A-Z])(?=.*[!@#$&.*])(?=.*[0-9]).{8,}$"
            autoComplete="none"
            required
            value={formdata?.new_password || ""}
            type={revealPassword ? "text" : "password"}
            name="new_password"
            id="new_password"
            placeholder="New password ..."
            className="w-full h-12 shrink-0 rounded border border-zinc-200 focus:border-black-300 text-xs
          font-medium text-zinc-700 placeholder:text-zinc-500/80 px-4 bg-white"
          />
          <button
            onClick={() => setReveal((prev: boolean) => !prev)}
            type="button"
            className="shirnk-0 text-xl text-zinc-400 absolute top-3.5 right-3 z-[9999]"
          >
            {!revealPassword ? <TbEye /> : <TbEyeOff />}
          </button>
        </label>

        {/**Password Requirements */}
        <ul className="mt-4 w-full h-fit shrink-0 ml-1 flex flex-col gap-0.5 text-xs text-zinc-600 list-disc list-inside">
          {passwordRequirements?.map((rule: string, index: number) => (
            <li
              key={index + "nested"}
              className={`w-full whitespace-nowrap truncate transition-all duration-300 ${
                getPasswordStrength(formdata?.new_password || "", rule)
                  ? "text-green-600 line-through"
                  : ""
              }`}
            >
              {rule}
            </li>
          ))}
        </ul>

        <label
          htmlFor="confirm_password"
          className="mt-4 w-full flex flex-col gap-1 shrink-0 relative"
        >
          <input
            onChange={(e) => {
              setData((prev: any) => ({
                ...prev,
                confirm_password: e.target?.value,
              }));
            }}
            title="Password Doesn't match"
            pattern={formdata?.new_password}
            autoComplete="off"
            required
            value={formdata?.confirm_password || ""}
            type={revealPassword ? "text" : "password"}
            name="confirm_password"
            id="confirm_password"
            placeholder="Confirm password ..."
            className="w-full h-12 shrink-0 rounded border border-zinc-200 focus:border-black-300 text-xs
            font-medium text-zinc-700 placeholder:text-zinc-500/80 px-4 bg-white"
          />
          <button
            onClick={() => setReveal((prev: boolean) => !prev)}
            type="button"
            className="shirnk-0 text-xl text-zinc-400 absolute top-3.5 right-3 z-[9999]"
          >
            {!revealPassword ? <TbEye /> : <TbEyeOff />}
          </button>
        </label>

        <button
          disabled={loading}
          type="submit"
          className="mt-4 rounded w-full h-12 bg-black-800 text-white text-xs uppercase font-semibold 
          flex items-center justify-center gap-2 hover:bg-black-700 transition-all shrink-0 disabled:cursor-not-allowed"
        >
          {loading ? (
            <div className="h-5 w-5 border-2 border-white border-l-black-400 rounded-full animate-spin"></div>
          ) : (
            <span>reset password</span>
          )}
        </button>

        {/**Or Sign Up ================== */}

        <div className="w-full flex items-center gap-4 overflow-hidden my-4">
          <hr className="border-zinc-300 border-dashed w-1/2" />
          <span className="text-xs font-semibold text-zinc-500 uppercase">
            {" "}
            or
          </span>
          <hr className="border-zinc-300 w-1/2 border-dashed" />
        </div>
        <Link
          to="/login"
          className="rounded w-full h-12 text-black-800 text-xs uppercase font-semibold border-2 border-black-500 
          flex items-center justify-center gap-2 hover:gap-3 transition-all group bg-white shrink-0"
        >
          <span>sign in</span>
          <TbArrowRight className="text-lg -rotate-45 group-hover:rotate-0 transition-all" />
        </Link>
      </form>



      {/**Loading */}
      {loading && <Loader />}
    </div>
  );
};

export default ResetPassword;
