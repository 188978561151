import { FC, useState, useEffect } from "react";
import ToolBar from "./ToolBar";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { insertAtCursor, convertBytes } from "./Functions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import { updateAlert } from "../../Redux/Slices/NotificationsSlice";
import { slashCommands } from "./Functions";

type Props = {
  otherStyles: string;
  toolBarStyles: string;
  setUploadStatus: any;
  uploadingStatus?: boolean;
  value: any;
  onChange: any;
  showChannel: boolean;
  editorClassName: string;
  slashComm?: any;
  editorTextBoxRef: any;
};

const Editor: FC<Props> = ({
  otherStyles,
  toolBarStyles,
  showChannel,
  setUploadStatus,
  uploadingStatus,
  onChange,
  editorClassName,
  slashComm,
  editorTextBoxRef,
}) => {
  const [isBackspace, setIsBackspace] = useState(false);
  const company_details: any = useSelector(
    (state: RootState) => state.SettingsData.company_details
  );
  const dispatch: AppDispatch = useDispatch();
  const alerts = useSelector(
    (state: RootState) => state.NotificationsData.alerts
  );

  //Handle pasting from the clipboard and dislay them
  const handlePaste = async (e: any) => {
    //handling  Images and displaying them ===================================
    Array.prototype.forEach.call(e?.clipboardData?.items, async (elem) => {
      e.preventDefault();
      if (elem?.size >= 20971520) {
        dispatch(
          updateAlert([
            ...alerts,
            {
              message: "File is too large please upload 20MB <",
              color: "bg-red-200",
              id: new Date().getTime(),
            },
          ])
        );
      } else {
        if (elem?.type?.replace(/\s/gi, "")?.includes("image")) {
          e?.preventDefault();
          setUploadStatus(true);
          const file = elem?.getAsFile();

          //Create a temporary Place Holder
          const tempID = `image-${new Date().getTime()}`;
          const tempImage = document.createElement("img");
          tempImage.id = tempID;
          //convert Temp Image to base64
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            typeof reader.result === "string"
              ? (tempImage.src = reader.result)
              : console.log("Failed");
            tempImage.style.filter = "blur(1px)";
            tempImage.style.maxWidth = "10rem";
            tempImage.style.overflow = "hidden";
          };
          insertAtCursor(tempImage);

          //Upload File if there is one
          const storage = getStorage();
          const storageRef = ref(
            storage,
            `/${company_details.name}/${new Date().getTime()}`
          );
          const uploadTask = uploadBytesResumable(storageRef, file);
          await uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              if (progress === 100) {
                dispatch(
                  updateAlert([
                    ...alerts,
                    {
                      message: "File Uploaded Successfully",
                      color: "bg-green-200",
                      id: new Date().getTime(),
                    },
                  ])
                );
              }
            },
            (error) => {
              //Remove Temp Image
              document.getElementById(tempID)?.remove();
              setUploadStatus(false);
              dispatch(
                updateAlert([
                  ...alerts,
                  {
                    message: error.message,
                    color: "bg-red-200",
                    id: new Date().getTime(),
                  },
                ])
              );
            },
            async () => {
              // Upload completed successfully, now we can get the download URL
              await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                //Convert Temp Image By Replace Image Url
                const img = document.getElementById(tempID) as HTMLImageElement;
                if (img) {
                  img.src = downloadURL;
                  img.style.filter = "blur(0px)";
                }
                onChange(editorTextBoxRef?.current?.innerHTML);
                setUploadStatus(false);
              });
            }
          );
        } else if (
          elem?.type?.replace(/\s/gi, "")?.includes("pdf") ||
          elem?.type
            ?.replace(/\s/gi, "")
            ?.includes(
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ) ||
          elem?.type
            ?.replace(/\s/gi, "")
            ?.includes(
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) ||
          elem?.type?.replace(/\s/gi, "")?.includes("audio")
        ) {
          //Upload A Pdf or Docs =========================================
          e.preventDefault();
          setUploadStatus(true);
          const file = elem?.getAsFile();

          //Create a temporary Place Holder
          const tempID = `pdf-${new Date().getTime()}`;
          let tempFile = "";
          //convert Temp Image to base64
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            if (typeof reader.result === "string") {
              tempFile += ` &nbsp;<a 
            contentEditable="${false}"
            href="${reader.result}"
            id="${tempID}"
            rel="noreferrer"
            target="_blank"
            download="${
              file.name
            }" class="dndhelp_file h-14 w-48 inline-flex items-center space-x-2 p-2 rounded-xl border !border-black-300 dark:!border-black-500 overflow-hidden select-none"
          >
            <div class="dndhelp_file_tag rounded-lg h-full w-10 ${
              file.type.includes("pdf")
                ? "!bg-red-600 text-xs red_tag"
                : file.type.includes(
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  )
                ? "!bg-blue-600 text-base blue_tag"
                : file.type.includes(
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  )
                ? "!bg-green-600 text-lg green_tag"
                : file.type.includes("audio")
                ? " !bg-sky-600 text-lg audio_tag"
                : ""
            } !text-white not-italic !font-sans tracking-wider font-semibold flex justify-center items-center">
              <span>${
                file.type.includes("pdf")
                  ? "PDF"
                  : file.type.includes(
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    )
                  ? "W"
                  : file.type.includes(
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    )
                  ? "x"
                  : file.type.includes("audio")
                  ? `<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><desc></desc><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M7 4v16l13 -8z"></path></svg>`
                  : ""
              }</span>
            </div>
            <span class="dndhelp_file_details h-full w-[calc(100%-2.5rem)] not-italic font-sans whitespace-nowrap overflow-ellipsis overflow-hidden grid py-1 spce-y-0">
              <span class="!text-black-700 dark:!text-black-300 !text-xs font-semibold w-full whitespace-nowrap overflow-ellipsis overflow-hidden">
                ${file.name}
              </span>
              <span class="!text-black-700 dark:!text-black-400 !text-xs">${convertBytes(
                file.size
              )}</span>
            </span>
          </a> &nbsp;`;
            }
            if (editorTextBoxRef.current)
              editorTextBoxRef.current.insertAdjacentHTML(
                "beforeend",
                tempFile
              );
            const link = document.getElementById(tempID) as HTMLLinkElement;
            link.style.backgroundColor = "rgb(15 23 42 / 0.3) !important";
          };

          //Upload File if there is one
          const storage = getStorage();
          const storageRef = ref(
            storage,
            `/${company_details.name}/${new Date().getTime()}`
          );
          const uploadTask = uploadBytesResumable(storageRef, file);
         await  uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              if (progress === 100) {
                dispatch(
                  updateAlert([
                    ...alerts,
                    {
                      message: "File Uploaded Successfully",
                      color: "bg-green-200",
                      id: new Date().getTime(),
                    },
                  ])
                );
              }
            },
            (error) => {
              //Remove Temp Image
              document.getElementById(tempID)?.remove();
              setUploadStatus(false);
              dispatch(
                updateAlert([
                  ...alerts,
                  {
                    message: error.message,
                    color: "bg-red-200",
                    id: new Date().getTime(),
                  },
                ])
              );
            },
            async() => {
              // Upload completed successfully, now we can get the download URL
             await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                //Convert Temp Image By Replace Image Url
                const link = document.getElementById(tempID) as HTMLLinkElement;
                if (link) {
                  link.href = downloadURL;
                  link.style.backgroundColor = "";
                }
                onChange(editorTextBoxRef?.current?.innerHTML);
                setUploadStatus(false);
              });
            }
          );
        } else if (elem?.type?.replace(/\s/gi, "")?.includes("video")) {
          e?.preventDefault();
          dispatch(
            updateAlert([
              ...alerts,
              {
                message: "Vidoes are not yet supported",
                color: "bg-red-200",
                id: new Date().getTime(),
              },
            ])
          );
        }
      }
    });

    //Handling Pasting Of Text
    if (
      e.clipboardData?.types?.every(
        (data: any) =>
          data?.toLowerCase()?.replace(/\s/gi, "")?.includes("text")
      )
    ) {
      e.preventDefault();
      const para = document.createElement("p");
      para.innerText = e.clipboardData.getData("text/plain");
      insertAtCursor(para);
      onChange(editorTextBoxRef?.current?.innerHTML);
    }
  };

  //Upload Image ===========================================
  const uploadFile: React.ChangeEventHandler<HTMLInputElement> | any = async (
    elem: any
  ) => {
    if (elem?.size >= 20971520) {
      dispatch(
        updateAlert([
          ...alerts,
          {
            message: "File is too large please upload 20MB <",
            color: "bg-red-200",
            id: new Date().getTime(),
          },
        ])
      );
    } else {
      //handling  Images and displaying them ===================================
      if (elem?.type?.replace(/\s/gi, "")?.includes("image")) {
        //Create a temporary Place Holder
        const tempID = `image-${new Date().getTime()}`;
        const tempImage = document.createElement("img");
        tempImage.id = tempID;
        //convert Temp Image to base64
        const reader = new FileReader();
        reader.readAsDataURL(elem);
        reader.onloadend = () => {
          typeof reader.result === "string"
            ? (tempImage.src = reader.result)
            : console.log("Failed");
          tempImage.style.filter = "blur(1px)";
          tempImage.style.maxWidth = "10rem";
        };
        editorTextBoxRef.current?.append(tempImage);

        //Upload File if there is one
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `/${company_details.name}/${new Date().getTime()}`
        );
        const uploadTask = uploadBytesResumable(storageRef, elem);
        await uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (progress === 100) {
              dispatch(
                updateAlert([
                  ...alerts,
                  {
                    message: "File Uploaded Successfully",
                    color: "bg-green-200",
                    id: new Date().getTime(),
                  },
                ])
              );
            }
          },
          (error) => {
            //Remove Temp Image
            document.getElementById(tempID)?.remove();
            setUploadStatus(false);
            dispatch(
              updateAlert([
                ...alerts,
                {
                  message: error.message,
                  color: "bg-red-200",
                  id: new Date().getTime(),
                },
              ])
            );
          },
          async () => {
            // Upload completed successfully, now we can get the download URL
            await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              //Convert Temp Image By Replace Image Url
              const img = document.getElementById(tempID) as HTMLImageElement;
              if (img) {
                img.src = downloadURL;
                img.style.filter = "blur(0px)";
              }
              onChange(editorTextBoxRef?.current?.innerHTML);
              setUploadStatus(false);
            });
          }
        );
      } else if (
        elem?.type?.replace(/\s/gi, "")?.includes("pdf") ||
        elem?.type
          ?.replace(/\s/gi, "")
          ?.includes(
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) ||
        elem?.type
          ?.replace(/\s/gi, "")
          ?.includes(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) ||
        elem?.type?.replace(/\s/gi, "")?.includes("audio")
      ) {
        //Upload A Pdf or Docs =========================================
        setUploadStatus(true);
        const file = elem;

        //Create a temporary Place Holder
        const tempID = `pdf-${new Date().getTime()}`;
        let tempFile = "";
        //convert Temp Image to base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            tempFile += ` &nbsp;<a 
            contentEditable="${false}"
            href="${reader.result}"
            id="${tempID}"
            rel="noreferrer"
            target="_blank"
            download="${
              file.name
            }" class="dndhelp_file h-14 w-48 inline-flex items-center space-x-2 p-2 rounded-xl border
             !border-black-300 dark:!border-black-500 overflow-hidden select-none"
          >
            <div class="dndhelp_file_tag rounded-lg h-full w-10 ${
              file.type.includes("pdf")
                ? "!bg-red-600 text-xs red_tag"
                : file.type.includes(
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  )
                ? "!bg-blue-600 text-base blue_tag"
                : file.type.includes(
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  )
                ? "!bg-green-600 text-lg green_tag"
                : file.type.includes("audio")
                ? " !bg-sky-600 text-lg audio_tag"
                : ""
            } !text-white not-italic !font-sans tracking-wider font-semibold flex justify-center items-center">
              ${
                file.type.includes("pdf")
                  ? "PDF"
                  : file.type.includes(
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    )
                  ? "W"
                  : file.type.includes(
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    )
                  ? "x"
                  : file.type.includes("audio")
                  ? `<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" 
                  stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <desc></desc><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M7 4v16l13 -8z"></path></svg>`
                  : ""
              }
            </div>
            <span class="dndhelp_file_details h-full w-[calc(100%-2.5rem)] not-italic font-sans 
            whitespace-nowrap overflow-ellipsis overflow-hidden grid py-1 spce-y-0">
              <span class="!text-black-700 dark:!text-black-300 !text-xs font-semibold w-full 
              whitespace-nowrap overflow-ellipsis overflow-hidden">
                ${file.name}
              </span>
              <span class="!text-black-700 dark:!text-black-400 !text-xs">${convertBytes(
                file.size
              )}</span>
            </span>
          </a> &nbsp;`;
          }
          if (editorTextBoxRef.current)
            editorTextBoxRef.current.insertAdjacentHTML("beforeend", tempFile);
          const link = document.getElementById(tempID) as HTMLLinkElement;
          link.style.backgroundColor = "rgb(15 23 42 / 0.3) !important";
        };

        //Upload File if there is one
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `/${company_details.name}/${new Date().getTime()}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);
        await uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (progress === 100) {
              dispatch(
                updateAlert([
                  ...alerts,
                  {
                    message: "File Uploaded Successfully",
                    color: "bg-green-200",
                    id: new Date().getTime(),
                  },
                ])
              );
            }
          },
          (error) => {
            //Remove Temp Image
            document.getElementById(tempID)?.remove();
            setUploadStatus(false);
            dispatch(
              updateAlert([
                ...alerts,
                {
                  message: error.message,
                  color: "bg-red-200",
                  id: new Date().getTime(),
                },
              ])
            );
          },
          async () => {
            // Upload completed successfully, now we can get the download URL
            await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              //Convert Temp Image By Replace Image Url
              const link = document.getElementById(tempID) as HTMLLinkElement;
              if (link) {
                link.href = downloadURL;
                link.style.backgroundColor = "";
              }
              onChange(editorTextBoxRef?.current?.innerHTML);
              setUploadStatus(false);
            });
          }
        );
      } else {
        alert("File Type Is Not Supported");
      }
    }
  };

  //Listen For Backspace And UPdate State Accordingly
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Backspace") {
        setIsBackspace(true);
      } else {
        setIsBackspace(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //Component ===============
  return (
    <div
      className={`rteContainer overflow-hidden relative flex flex-col ${otherStyles}`}
    >
      <ToolBar
        uploadingStatus={uploadingStatus}
        toolBarStyles={toolBarStyles}
        uploadFile={uploadFile}
        showChannel={showChannel}
        editorClassName={editorClassName}
      />
      <div
        className={`w-full h-[calc(100%-2.25rem)] overflow-hidden overflow-y-scroll 
        no-scrollbar no-scrollbar::-webkit-scrollbar p-2`}
      >
        <div
          ref={editorTextBoxRef}
          role="textbox"
          onPaste={(e) => {
            handlePaste(e)
            onChange(editorTextBoxRef?.current?.innerHTML);
          }}
          contentEditable={true}
          onInput={(e) => {
            if (editorTextBoxRef?.current?.innerHTML.trim() === "<br/>"||editorTextBoxRef?.current?.innerHTML.trim() === "<br>") {
              editorTextBoxRef.current.innerHTML = "";
            }
            !isBackspace && slashComm && slashCommands(e, slashComm);
            onChange(editorTextBoxRef?.current?.innerHTML);
          }}
          onKeyDown={(e)=>{
            if(e.key === "Enter"){
              e.preventDefault()
              if(e.currentTarget){
               const newLine =  document.createElement("br")
                insertAtCursor(newLine)
              }
            }
          }}
          spellCheck={true}
          suppressContentEditableWarning={true}
          // dangerouslySetInnerHTML={{ __html: value }}
          placeholder={
            editorClassName === "newTicketEditor"
              ? "Description here ..."
              : "Type here ..."
          }
          className={`dndRTE ${editorClassName} outline-none focus:outline-none overflow-hidden 
            overflow-y-scroll no-scrollbar no-scrollbar::-webkit-scrollbar w-full h-full relative  p-1`}
        ></div>
      </div>
    </div>
  );
};

export default Editor;
