import { useState, useEffect } from "react";

const useFetch = (url: string) => {
  const [data, setData] = useState<any[] | null>(null);
  const [isPending, setIsPending] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const cachedData = localStorage.getItem(url);
    const cacheTimestamp = localStorage.getItem(`${url}_timestamp`); // Store timestamp with data

    const isCacheValid =
      cachedData &&
      cacheTimestamp &&
      Date.now() - +cacheTimestamp < 30 * 60 * 1000; // 30 mins in ms

    if (isCacheValid) {
      setData(JSON.parse(cachedData));
      setIsPending(false);
    } else {
      // Fetch if cache is invalid or doesn't exist
      const abortController = new AbortController();
      fetch(url, { signal: abortController.signal })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch data from the resource.");
          }
          return res.json();
        })
        .then((responseData) => {
          // Handle different API response structures:
          if (Array.isArray(responseData)) {
            setData(responseData);
          } else if (
            responseData &&
            responseData.jsonArray &&
            Array.isArray(responseData.jsonArray)
          ) {
            setData(responseData.jsonArray); // Original format
          } else {
            console.warn("Unexpected API response format:", responseData);
            setError("Invalid data format from the API.");
          }
          setIsPending(false);
          localStorage.setItem(url, JSON.stringify(responseData));
          localStorage.setItem(`${url}_timestamp`, Date.now().toString()); // Update timestamp
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            console.log("Fetch aborted");
          } else {
            setIsPending(false);
            setError(err.message);
          }
        });

      return () => abortController.abort();
    }
  }, [url]);

  return { data, isPending, error };
};

export default useFetch;
