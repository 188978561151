import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Redux/store";
import { onSnapshot, query, where,orderBy } from "firebase/firestore";
import { ticketsRef } from "./TicketsnUserData";
import {
  changeLoadingStatus,
  updateChatData,
  updateFilteredTickets,
} from "../Redux/Slices/Tickets_n_Contacts_Slice";

const TicketsDataFetcher = () => {
  const dispatch = useDispatch();
  const user = useSelector(
    (state: RootState) => state.UserInfo.member_details
  )[0];
  const ticketsComponentDates = useSelector(
    (state: RootState) => state.Tickets.ticketsComponentDates
  );
  const chatDates = useSelector((state: RootState) => state.Tickets.chatDates);

  //Load Tickets Based On Acess Level =========
  useEffect((): any => {
    //Tickects Data Fetching ======================
    if (user?.access === "admin") {
      return onSnapshot(
        query(
          ticketsRef,
          where("date", ">=", new Date(ticketsComponentDates.start).getTime()),
          where(
            "date",
            "<=",
            new Date(Number(ticketsComponentDates.end) + 86400000).getTime()
          )
        ),
        (snapshot: { docs: any[] }) => {
          dispatch(changeLoadingStatus(true));
          dispatch(
            updateFilteredTickets(
              snapshot.docs.map((doc: { data: () => any; id: any }) => ({
                ...doc.data(),
                id: doc.id,
              }))
            )
          );
          dispatch(changeLoadingStatus(false));
        },
        (error) => {
          dispatch(changeLoadingStatus(false));
          console.log(error);
        }
      );
    } else if (user?.access === "client") {
      return onSnapshot(
        query(
          ticketsRef,
          where("date", ">=", new Date(ticketsComponentDates.start).getTime()),
          where(
            "date",
            "<=",
            new Date(Number(ticketsComponentDates.end) + 86400000).getTime()
          )
        ),
        (snapshot: { docs: any[] }) => {
          dispatch(changeLoadingStatus(true));
          dispatch(
            updateFilteredTickets(
              snapshot.docs
                .map((doc: { data: () => any; id: any }) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
                .filter(
                  (data: any) =>
                    user?.companies
                      ?.toLowerCase()
                      ?.replace(/\s/gi, "")
                      ?.includes(
                        data?.company?.toLowerCase()?.replace(/\s/gi, "")
                      ) ||
                    data["recipient email"]
                      ?.toLowerCase()
                      ?.replace(/\s/gi, "")
                      ?.includes(
                        user?.email?.toLowerCase()?.replace(/\s/gi, "")
                      )
                )
            )
          );
          dispatch(changeLoadingStatus(false));
        },
        (error) => {
          dispatch(changeLoadingStatus(false));
          console.log(error);
        }
      );
    } else if (user?.access === "agent") {
      return onSnapshot(
        query(
          ticketsRef,
          where("date", ">=", new Date(ticketsComponentDates.start).getTime()),
          where(
            "date",
            "<=",
            new Date(Number(ticketsComponentDates.end) + 86400000).getTime()
          )
        ),
        (snapshot: { docs: any[] }) => {
          dispatch(changeLoadingStatus(true));
          dispatch(
            updateFilteredTickets(
              snapshot.docs
                .map((doc: { data: () => any; id: any }) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
                ?.filter(
                  (data) =>
                    data["assignee email"]
                      ?.replace(/\s/g, "")
                      ?.toLowerCase() ===
                      user?.email?.replace(/\s/g, "")?.toLowerCase() ||
                    data?.collaborators?.some(
                      (email: any) =>
                        email
                          ?.replace(/\s/g, "")
                          ?.toLowerCase()
                          ?.includes(
                            user?.email?.replace(/\s/g, "")?.toLowerCase()
                          )
                    )
                )
            )
          );
          dispatch(changeLoadingStatus(false));
        },
        (error) => {
          dispatch(changeLoadingStatus(false));
          console.log(error);
        }
      );
    }
  }, [
    dispatch,
    ticketsComponentDates.end,
    ticketsComponentDates.start,
    user?.access,
    user?.companies,
    user?.email,
  ]);



  //Load Queue Report Based On Acess Level =========
  //Load chatData Based On Acess Level  and Filters =========
  useEffect((): any => {
    //Tickects Data Fetching ======================
    if (user?.access === "admin") {
      return onSnapshot(
        query(
          ticketsRef,
          where("date", ">=", new Date(chatDates.start).getTime()),
          where(
            "date",
            "<=",
            new Date(Number(chatDates.end) + 86400000).getTime()
          ),
          orderBy("date", "desc")
        ),
        (snapshot: { docs: any[] }) => {
          dispatch(changeLoadingStatus(true));
          dispatch(
            updateChatData(
              snapshot.docs
                .map((doc: { data: () => any; id: any }) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
                ?.slice(0, 10000)
            )
          );
          dispatch(changeLoadingStatus(false));
        },
        (error) => {
          dispatch(changeLoadingStatus(false));
          console.log("failed to fetch" + error?.message);
        }
      );
    } else if (user?.access === "client") {
      return onSnapshot(
        query(
          ticketsRef,
          where("date", ">=", new Date(chatDates?.start).getTime()),
          where(
            "date",
            "<=",
            new Date(Number(chatDates?.end) + 86400000).getTime()
          ),
          orderBy("date", "desc")
        ),
        (snapshot: { docs: any[] }) => {
          dispatch(changeLoadingStatus(true));
          dispatch(
            updateChatData(
              snapshot.docs
                .map((doc: { data: () => any; id: any }) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
                .filter(
                  (data: any) =>
                    user?.companies
                      ?.toLowerCase()
                      ?.replace(/\s/gi, "")
                      ?.includes(
                        data?.company?.toLowerCase()?.replace(/\s/gi, "")
                      ) ||
                    data["recipient email"]
                      ?.toLowerCase()
                      ?.replace(/\s/gi, "")
                      ?.includes(
                        user?.email?.toLowerCase()?.replace(/\s/gi, "")
                      )
                )
                ?.slice(0, 10000)
            )
          );
          dispatch(changeLoadingStatus(false));
        },
        (error) => {
          dispatch(changeLoadingStatus(false));
          console.log("failed to fetch" + error?.message);
        }
      );
    } else if (user?.access === "agent") {
      return onSnapshot(
        query(
          ticketsRef,
          where("date", ">=", new Date(chatDates?.start).getTime()),
          where(
            "date",
            "<=",
            new Date(Number(chatDates?.end) + 86400000).getTime()
          ),
          orderBy("date", "desc")
        ),
        (snapshot: { docs: any[] }) => {
          dispatch(changeLoadingStatus(true));
          dispatch(
            updateChatData(
              snapshot.docs
                .map((doc: { data: () => any; id: any }) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
                ?.filter(
                  (data) =>
                    data["assignee email"]
                      ?.replace(/\s/g, "")
                      ?.toLowerCase() ===
                      user?.email?.replace(/\s/g, "")?.toLowerCase() ||
                    data?.collaborators?.some((email: any) =>
                      email
                        ?.replace(/\s/g, "")
                        ?.toLowerCase()
                        ?.includes(
                          user?.email?.replace(/\s/g, "")?.toLowerCase()
                        )
                    )
                )
                ?.slice(0, 10000)
            )
          );
          dispatch(changeLoadingStatus(false));
        },
        (error) => {
          dispatch(changeLoadingStatus(false));
          console.log("failed to fetch" + error?.message);
        }
      );
    }
  }, [chatDates.end, chatDates.start, dispatch, user?.access, user?.companies, user?.email]);

  return (
    <>
    </>
  );
};

export default TicketsDataFetcher;
