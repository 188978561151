import { FC, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../Redux/store";
import noTickets from "../images/all_caught.png";
import {
  filterTickets,
  markTicket,
  selectTicket,
} from "../../../Redux/Slices/ChatSlice";
import { formatDate } from "../../../Reusable Functions/Reusable_Func";

const List: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const chatData = useSelector((state: RootState) => state.Tickets.chatData);
  const filteredChatData = useSelector(
    (state: RootState) => state.ChatData.filteredChatData
  );
  const bookMarked = useSelector(
    (state: RootState) => state.ChatData.bookMarked
  );
  const markedArray = useSelector(
    (state: RootState) => state.ChatData.markedArray
  );
  const activeTicket = useSelector(
    (state: RootState) => state.ChatData.activeTicket
  );
  const chatDataFilters = useSelector(
    (state: RootState) => state.Tickets.chatDataFilters
  );
  const pagination = useSelector(
    (state: RootState) => state.ChatData.pagination
  );
  const user = useSelector(
    (state: RootState) => state.UserInfo?.member_details
  )[0];

  //Send Data to A service worker for filtering ====
  const worker = useMemo(
    () =>
      new Worker(
        new URL("../../../Workers/Tickets_List_Worker.js", import.meta.url)
      ),
    []
  );
  //Send Data to worker
  useEffect(() => {
    worker.postMessage({
      fetchedTickets:chatData ||bookMarked || [],
      filters: chatDataFilters,
      pagination: pagination,
    });
  }, [bookMarked, chatData, chatDataFilters, pagination, worker]);

  //Receive data from Worker
  useEffect((): any => {
    worker.onmessage = (message: any) => {
      dispatch(filterTickets(message?.data?.filteredTickets));
    };
    worker.onerror = function (e: any) {
      console.log(e);
    };
    return () => worker.terminate();
  }, [worker, dispatch]);

  //Loop Through Each Tickects =================
  const tickets =
    filteredChatData?.length >= 1 ? (
      filteredChatData?.map((ticket: any) => {
        return (
          <div
            role="row"
            key={ticket?.id}
            className={`w-full h-12 shrink-0 bg-inherit overflow-hidden border-b border-black-100 
            dark:border-black-700/60 ${
              ticket?.ticket_id === activeTicket?.ticket_id
                ? `!bg-black-100 dark:!bg-black-700/70 group/active
                 border-y border-black-200 dark:!border-black-600`
                : ""
            }  flex items-center transition-all hover:opacity-80 px-5 gap-2`}
          >
            <div className="h-full w-fit shrink-0 flex items-center gap-2">
              {/** =============Mark Ticket ================ */}
              <input
                className={`!appearance-none h-3.5 w-3.5 rounded-full border-2 ${
                  ticket?.status === "open"||ticket?.status === "reopened"
                    ? "border-blue-600 checked:!bg-blue-600 focus:checked:!bg-blue-600 bg-blue-50 hover:checked:!bg-blue-600 dark:bg-black-700/70"
                    : ticket?.status === "pending"
                    ? "border-amber-600 checked:!bg-amber-600 focus:checked:!bg-amber-600 bg-amber-50 hover:checked:!bg-amber-600 dark:bg-black-700/70"
                    : ticket?.status === "closed"
                    ? "border-purple-600 checked:!bg-purple-600 focus:checked:!bg-purple-600 bg-purple-50 hover:checked:!bg-purple-600 dark:bg-black-700/70"
                    : "border-emerald-600 checked:!bg-emerald-600 focus:checked:!bg-emerald-600 bg-emerald-50 hover:checked:!bg-emerald-600 dark:bg-black-700/70"
                } cursor-pointer checked:!ring-0 focus:ring-0`}
                type="checkbox"
                name={ticket?.id+"list"}
                id={ticket?.id+"list"}
                checked={
                  markedArray.includes(ticket?.id) === true ? true : false
                }
                disabled={
                  ticket?.origin?.toLowerCase()?.replace(/\s/gi, "") === "yumbi"
                    ? false
                    : !(
                        ticket["requester email"]
                          ?.toLowerCase()
                          ?.replace(/\s/gi, "") ===
                          user?.email?.toLowerCase()?.replace(/\s/gi, "") ||
                        user?.access === "admin"
                      )
                }
                onChange={(e) => {
                  e.target.checked === true
                    ? dispatch(markTicket([...markedArray, ticket?.id]))
                    : dispatch(
                        markTicket(
                          markedArray.filter((data: any) => data !== ticket?.id)
                        )
                      );
                }}
              />
            </div>
            <div className="w-[calc(100%-1.5rem)] h-full flex items-center overflow-hidden">
              <div
                onClick={() => {
                  if (ticket?.ticket_id !== activeTicket?.ticket_id) {
                    dispatch(selectTicket(ticket));
                  }
                }}
                className={`w-full min-w-[80%] h-full flex items-center justify-between gap-6 
                overflow-hidden cursor-pointer text-black-100 dark:text-black-800`}
              >
                <span
                  className="w-fit overflow-hidden whitespace-nowrap overflow-ellipsis text-xs
                     capitalize font-semibold tracking-normal text-black-700 dark:text-black-300"
                >
                  {ticket?.category} {"-"}{" "}
                  <span className="font-medium text-zinc-500 dark:text-black-400">
                    {" "}
                    {(ticket?.["recipient name"]
                      ? ticket?.["recipient name"]
                      : ticket?.company?.split(/,|\//)[0])?.toLowerCase()}
                  </span>
                </span>

                <span
                  className="w-fit shrink-0 overflow-hidden whitespace-nowrap overflow-ellipsis text-right text-xss
                     capitalize font-medium tracking-normal text-zinc-600 dark:text-black-400"
                >
                  {formatDate(new Date(ticket?.date || ""))}
                </span>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div
        className="h-full w-full flex flex-col items-center justify-center gap-0 select-none rounded-sm
       bg-white dark:bg-black-900 borde border-black-300 dark:border-black-750"
      >
        <div className="flex">
          <img
            src={noTickets}
            alt="No Ticket"
            className="h-[5rem] w-[7rem] object-contain object-center"
          />
        </div>
        <div className="flex">
          <h2 className="dark:text-black-300 text-black-700 text-center capitalize text-sm font-bold">
            all caught up!
          </h2>
        </div>
        <div className="flex pt-0.5">
          <p className="dark:text-black-400 text-black-600 text-center text-xs font-medium">
            Take a break and do what you do best.
          </p>
        </div>
      </div>
    );

  //Component
  return <>{tickets}</>;
};

export default List;
