import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NewTicketFeilds } from "../../Pages/Tickets/New Ticket/TicketFields";

const initialId: string | any = window.localStorage.getItem("threadId");
const initialTickestDate = window.localStorage.getItem(
  "overview_reports_dates"
);
interface InitialStateState {
  loadingStatus: boolean;
  allTickets: any[];
  threadId: string | null;
  contacts: string[] | any[];
  email_accounts: any[];
  email_templates: any[];
  categories: string[] | any[];
  custom_fields: string[] | any[];
  ticketFields: any[];
  unread: string[] | any[];
  imageAttachments: string[] | any[];
  ticketsComponentDates: Date | any;
  filteredTickets: string[] | any[];
  frequentlyAsked: string[] | any[];
  chatDates: Date | any;
  chatData: any[];
  chatDataFilters: any;
}

const initialState: InitialStateState = {
  loadingStatus: false,
  allTickets: [],
  threadId: initialId ? JSON.parse(initialId) : "",
  contacts: [],
  email_accounts: [],
  email_templates: [],
  categories: [],
  custom_fields: [],
  ticketFields: [...NewTicketFeilds],
  unread: [],
  imageAttachments: [],
  ticketsComponentDates: initialTickestDate
    ? JSON.parse(initialTickestDate)
    : {
        start: new Date().getTime() - 172800000,
        end: new Date().getTime(),
      },
  chatDates: (() => {
    const data = window.localStorage.getItem("chatDates");
    return data
      ? JSON.parse(data)
      : {
          start: new Date().getTime() - 172800000,
          end: new Date().getTime(),
        };
  })(),
  chatData: [],
  chatDataFilters: (() => {
    const draft = localStorage.getItem("tickets_filters");
    return draft
      ? JSON.parse(draft)
      : {
          ticket_id: "",
          assignee: [],
          stores: [],
          category: "",
          status: "",
          priority: "",
        };
  })(),
  filteredTickets: [],
  frequentlyAsked: [],
};

export const TicketsSlice = createSlice({
  name: "Tickets",
  initialState,
  reducers: {
    changeLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.loadingStatus = action.payload;
    },
    updateTicketFields: (state, action: PayloadAction<any[]>) => {
      state.ticketFields = action.payload;
    },
    addAllTickets: (state, action: PayloadAction<any[]>) => {
      state.allTickets = action.payload?.sort((a, b) => b?.date - a?.date);
    },
    setContacts: (state, action: PayloadAction<any[]>) => {
      state.contacts = action.payload.sort((a, b) =>
        a.name < b.name ? -1 : 1
      );
    },
    setThreadId: (state, action: PayloadAction<string | null>) => {
      state.threadId = action.payload;
    },
    loadFrequentlyAsked: (state, action: PayloadAction<any[]>) => {
      state.frequentlyAsked = action.payload;
    },
    loadAccounts: (state, action: PayloadAction<string[] | number[]>) => {
      state.email_accounts = action.payload;
    },
    setImageArray: (state, action: PayloadAction<any[]>) => {
      state.imageAttachments = action.payload;
    },
    loadTemplates: (state, action: PayloadAction<string[] | number[]>) => {
      state.email_templates = action.payload;
    },
    setUnread: (state, action: PayloadAction<any[]>) => {
      state.unread = action.payload;
    },
    setCategories: (state, action: PayloadAction<any[]>) => {
      state.categories = action.payload;
    },
    setCustomFields: (state, action: PayloadAction<any[]>) => {
      state.custom_fields = action.payload;
    },
    updateTicketsComponentDates: (state, action: PayloadAction<any>) => {
      state.ticketsComponentDates = action.payload;
      window.localStorage.setItem(
        "overview_reports_dates",
        JSON.stringify(action.payload)
      );
    },
    updateFilteredTickets: (state, action: PayloadAction<any[]>) => {
      state.filteredTickets = action.payload.sort(
        (a: any, b: any) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
      );
    },
    updateChatData: (state, action: PayloadAction<any[]>) => {
      state.chatData = action.payload.sort(
        (a: any, b: any) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
      );
    },
    updateChatDataFilters: (state, action: PayloadAction<any>) => {
      state.chatDataFilters = action.payload;
      window.localStorage.setItem(
        "tickets_filters",
        JSON.stringify(action.payload)
      );
    },
    changeChatDate: (state, action: PayloadAction<any>) => {
      state.chatDates = action.payload;
      window.localStorage.setItem("chatDates", JSON.stringify(action.payload));
    },
  },
});

export const {
  changeLoadingStatus,
  addAllTickets,
  updateTicketFields,
  setContacts,
  setThreadId,
  loadFrequentlyAsked,
  loadTemplates,
  updateFilteredTickets,
  setUnread,
  setImageArray,
  loadAccounts,
  setCategories,
  updateTicketsComponentDates,
  setCustomFields,
  updateChatData,
  changeChatDate,
  updateChatDataFilters,
} = TicketsSlice.actions;

export default TicketsSlice.reducer;
